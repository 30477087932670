import api from '../../../../../common/api.js'
import {
  isNonZeroPositiveNumeric,
  isEmptyValue,
} from '../../../../../common/utils.js'
import {setColumnsToDataMap} from '../../dataImport/index.js'

import baseStrategy from './baseStrategy.js'

export default class productCreateStrategy extends baseStrategy {
  static title = 'Create Products'
  static requiredColumns = ['sku', 'name']

  static getExampleRows() {
    return () => {
      return api.get('/product/', {limit: 5}).then(({json: data}) => {
        const rows = [
          [
            'SKU *Req',
            'Description *Req',
            'Price',
            'Weighted Average Unit Cost',
            'Weight (lbs)',
            'Category',
            'Length (in)',
            'Width (in)',
            'Height (in)',
            'UPC',
            'ASIN',
            'Harmonized Code',
            'Country of Origin',
            'Declared Value',
            'Customs Description',
            'Internal Notes',
            'Has Serial Numbers',
          ],
        ]

        data.product.forEach((product) => {
          rows.push([
            product.sku,
            product.name,
            product.price,
            product.cost,
            product.weight,
            product.category,
            product.length,
            product.width,
            product.height,
            product.upc,
            product.asin,
            product.hs_code,
            product.country_of_origin,
            product.declared_value,
            product.customs_description,
            product.internal_notes,
            product.has_serial_numbers,
          ])
        })

        return rows
      })
    }
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/sku/i),
      name: find(/^description|name$/i),
      price: find(/price/i),
      cost: find(/^(weighted.)?(average.)?(unit.)?cost$/i),
      weight: find(/^weight( \(lbs\))?$/i),
      category: find(/category/i),
      length: find(/^length( \(in\))?$/i),
      width: find(/^width( \(in\))?$/i),
      height: find(/^height( \(in\))?$/i),
      upc: find(/upc/i),
      asin: find(/asin/i),
      hs_code: find(/hs_code|harmonized.code/i),
      country_of_origin: find(/^country(.of.origin)?$/i),
      declared_value: find(/^declared.value?$/i),
      customs_description: find(/^customs.description$/i),
      internal_notes: find(/internal.note(s)?/i),
      has_serial_numbers: find(/serial.number(s)?/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()

    data.payload = {
      sku: row[columnsByName.sku],
      name: row[columnsByName.name],
      price: row[columnsByName.price],
      cost: row[columnsByName.cost],
      weight: row[columnsByName.weight],
      category: row[columnsByName.category],
      length: row[columnsByName.length],
      width: row[columnsByName.width],
      height: row[columnsByName.height],
      upc: row[columnsByName.upc],
      asin: row[columnsByName.asin],
      hs_code: row[columnsByName.hs_code],
      country_of_origin: row[columnsByName.country_of_origin],
      declared_value: row[columnsByName.declared_value],
      customs_description: row[columnsByName.customs_description],
      internal_notes: row[columnsByName.internal_notes],
      has_serial_numbers: this.toBoolean(row[columnsByName.has_serial_numbers]),
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')
    this.required(payload, errors, 'name', 'Name is required')
    this.requiredNumber(payload, errors, 'price', 'Price must be a number')
    this.requiredNumber(payload, errors, 'cost', 'Cost must be a number')
    this.requiredNumber(payload, errors, 'weight', 'Weight must be a number')

    if (
      !isNonZeroPositiveNumeric(payload.length) &&
      !isEmptyValue(payload.length)
    ) {
      errors.length = 'Length must be a positive number or empty'
    } else {
      delete errors.length
    }

    if (
      !isNonZeroPositiveNumeric(payload.width) &&
      !isEmptyValue(payload.width)
    ) {
      errors.width = 'Width must be a positive number or empty'
    } else {
      delete errors.width
    }

    if (
      !isNonZeroPositiveNumeric(payload.height) &&
      !isEmptyValue(payload.height)
    ) {
      errors.height = 'Height must be a positive number or empty'
    } else {
      delete errors.height
    }

    return errors
  }

  static save(data) {
    return () => {
      const params = {
        sku: data.payload.sku,
        name: data.payload.name,
        price: data.payload.price,
        cost: data.payload.cost,
        weight: data.payload.weight,
        category: data.payload.category,
        length: Number(data.payload.length) || null,
        width: Number(data.payload.width) || null,
        height: Number(data.payload.height) || null,
        upc: data.payload.upc,
        asin: data.payload.asin,
        hs_code: data.payload.hs_code,
        country_of_origin: data.payload.country_of_origin,
        declared_value: data.payload.declared_value,
        customs_description: data.payload.customs_description,
        internal_notes: data.payload.internal_notes,
        has_serial_numbers: data.payload.has_serial_numbers,
      }

      return api.post('/product', params)
    }
  }
}
