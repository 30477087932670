import {formSelector} from '../../../store.js'

export function setupExportBOMStructureForm() {
  return {}
}

export function exportBOMStructureErrorsSelector() {
  return {}
}

export function exportBOMStructurePayloadSelector(state, {formName}) {
  const {reportType} = formSelector(state, {formName})

  const payload = {
    type: reportType,
    params: {},
  }

  return payload
}

export default function ExportBOMStructureForm() {
  return null
}
