import api from '../../../../../common/api.js'
import {
  isNonZeroPositiveNumeric,
  isEmptyValue,
} from '../../../../../common/utils.js'
import productCreateStrategy from './productCreateStrategy.js'
import {dataImportSelector} from '../../../../selectors/ui/dataImport/index.js'

export default class productUpdateStrategy extends productCreateStrategy {
  static title = 'Update Product'
  static requiredColumns = ['sku']

  static getExampleRows() {
    return () => {
      return api.get('/product/', {limit: 5}).then(({json: data}) => {
        const rows = [
          [
            'SKU *Req',
            'Description',
            'Price',
            'Weighted Average Unit Cost',
            'Weight (lbs)',
            'Category',
            'Length (in)',
            'Width (in)',
            'Height (in)',
            'UPC',
            'ASIN',
            'Harmonized Code',
            'Country of Origin',
            'Declared Value',
            'Customs Description',
            'Internal Notes',
            'Has Serial Numbers',
          ],
        ]

        data.product.forEach((product) => {
          rows.push([
            product.sku,
            product.name,
            product.price,
            product.cost,
            product.weight,
            product.category,
            product.length,
            product.width,
            product.height,
            product.upc,
            product.asin,
            product.hs_code,
            product.country_of_origin,
            product.declared_value,
            product.customs_description,
            product.internal_notes,
            product.has_serial_numbers,
          ])
        })

        return rows
      })
    }
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')
    this.requiredNumber(payload, errors, 'price', 'Price must be a number')
    this.requiredNumber(payload, errors, 'cost', 'Cost must be a number')
    this.requiredNumber(payload, errors, 'weight', 'Weight must be a number')

    if (
      !isNonZeroPositiveNumeric(payload.length) &&
      !isEmptyValue(payload.length)
    ) {
      errors.length = 'Length must be a positive number or empty'
    } else {
      delete errors.length
    }

    if (
      !isNonZeroPositiveNumeric(payload.width) &&
      !isEmptyValue(payload.width)
    ) {
      errors.width = 'Width must be a positive number or empty'
    } else {
      delete errors.width
    }

    if (
      !isNonZeroPositiveNumeric(payload.height) &&
      !isEmptyValue(payload.height)
    ) {
      errors.height = 'Height must be a positive number or empty'
    } else {
      delete errors.height
    }

    return errors
  }

  static save(data) {
    return (dispatch, getState) => {
      const {columnsToDataMap} = dataImportSelector(getState())
      const url = `/product/${encodeURIComponent(data.payload.sku)}/`
      const params = {
        sku: data.payload.sku,
      }

      if (columnsToDataMap.name !== -1) {
        params.name = data.payload.name
      }
      if (columnsToDataMap.price !== -1) {
        params.price = data.payload.price
      }
      if (columnsToDataMap.cost !== -1) {
        params.cost = data.payload.cost
      }
      if (columnsToDataMap.weight !== -1) {
        params.weight = data.payload.weight
      }
      if (columnsToDataMap.category !== -1) {
        params.category = data.payload.category
      }
      if (columnsToDataMap.length !== -1) {
        params.length = Number(data.payload.length) || null
      }
      if (columnsToDataMap.width !== -1) {
        params.width = Number(data.payload.width) || null
      }
      if (columnsToDataMap.height !== -1) {
        params.height = Number(data.payload.height) || null
      }
      if (columnsToDataMap.upc !== -1) {
        params.upc = data.payload.upc
      }
      if (columnsToDataMap.asin !== -1) {
        params.asin = data.payload.asin
      }
      if (columnsToDataMap.hs_code !== -1) {
        params.hs_code = data.payload.hs_code
      }
      if (columnsToDataMap.country_of_origin !== -1) {
        params.country_of_origin = data.payload.country_of_origin
      }
      if (columnsToDataMap.declared_value !== -1) {
        params.declared_value = data.payload.declared_value
      }
      if (columnsToDataMap.customs_description !== -1) {
        params.customs_description = data.payload.customs_description
      }
      if (columnsToDataMap.internal_notes !== -1) {
        params.internal_notes = data.payload.internal_notes
      }
      if (columnsToDataMap.has_serial_numbers !== -1) {
        params.has_serial_numbers = data.payload.has_serial_numbers
      }

      return api.put(url, params)
    }
  }
}
