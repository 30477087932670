import {createSelector} from 'reselect'
import find from 'lodash/find.js'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'
import intersection from 'lodash/intersection.js'
import {useEffect} from 'react'
import uniq from 'lodash/uniq.js'
import partition from 'lodash/partition.js'
import keyBy from 'lodash/keyBy.js'

import {isPositiveNumeric} from '../common/utils.js'
import {
  DROPSHIPPABLE_FULFILLMENT,
  AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
  PRODUCT_FULFILLMENT_CHANNEL_OPTIONS,
  BOM_LINK_TYPE,
  PRODUCT_BOOLEAN_FILTER_OPTIONS,
  IS_KIT_FILTER,
  IS_KIT_COMPONENT_FILTER,
  IS_BOM_FILTER,
  IS_BOM_COMPONENT_FILTER,
  IS_DROPSHIP_FILTER,
  NEEDS_SYNC_FILTER,
  IS_OVERSOLD_FILTER,
  IS_REORDERED_FILTER,
  IS_LOW_INVENTORY_AOH_FILTER,
  IS_LOW_INVENTORY_POH_FILTER,
} from '../common/constants/Products.js'
import {
  AMAZON,
  AMAZON_CA,
  AMAZON_UK,
  FULFILLMENT_CHANNEL_FBM,
  FULFILLMENT_CHANNEL_FBA,
} from '../common/constants/CartVendorOptions.js'
import {
  hasProductPermissionSelector,
  hasProductUpdateInventoryPermissionSelector,
} from './me.js'
import {
  companyFeaturesSelector,
  usesInventorySelector,
  hasUseMOsFeatureSelector,
  useKittingSelector,
  useDropshippingSelector,
} from './company.js'
import {amazonCartsSelector, cartVendorsSelector} from './carts.js'
import {warehouseSelector, getIsFBAWarehouse} from './warehouses.js'
import {suppliersSelector, supplierOptionsSelector} from './suppliers.js'
import {productTagsSelector} from './productTags.js'

import {
  getState,
  dispatch,
  useSelector,
  setForm,
  updateForm,
  formsSelector,
  updateFormObject,
} from '../store.js'
import api from '../common/api.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {showCreateProductModal} from '../ordoro/ProductListPage/Modals/CreateProductModal.js'
import {checkRunningTasks} from '../redux/actions/data/isRunningTasks.js'
import {showMessageToast} from '../ordoro/Header/Toast/index.js'
import {productsHaveLoadedSelector} from '../redux/selectors/data/hasLoaded.js'
import {updateHasLoaded} from '../redux/actions/data/hasLoaded.js'
import {tokenizeOptions} from '../common/tokenizeOptions.js'

export const PRODUCTS = 'PRODUCTS'

export function setProducts(products) {
  setForm(PRODUCTS, keyBy(products, 'sku'))
}

export function setProduct(product) {
  if (formsSelector(getState())[PRODUCTS]) {
    updateForm(PRODUCTS, {[product.sku]: product})
  } else {
    setProducts([product])
  }
}

export function addProducts(products) {
  if (formsSelector(getState())[PRODUCTS]) {
    updateForm(PRODUCTS, keyBy(products, 'sku'))
  } else {
    setProducts(products)
  }
}

export function updateProduct(sku, updates) {
  updateFormObject(PRODUCTS, [sku], updates)
}

export const getProductLinkFromSku = (sku) =>
  `/product/${encodeURIComponent(sku)}/`

export function getWarehouseLocation(product, {id: warehouseID}) {
  const productWarehouse = product.warehouses.find(
    (warehouse) => warehouse.id === warehouseID,
  )
  return get(productWarehouse, 'location_in_warehouse') || null
}

export const productsSelector = createSelector(
  formsSelector,
  (forms) => forms[PRODUCTS] || productsSelector.default,
)
productsSelector.default = {}

export function productSelector(state, {sku}) {
  return productsSelector(state)[sku]
}

export function getProductSuppliers(product) {
  return get(product, 'suppliers', [])
}

export function productSuppliersSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getProductSuppliers(product)
}

export const canUseProductsSelector = createSelector(
  hasProductPermissionSelector,
  (hasPermission) => hasPermission,
)

export function getProductWarehouses(product) {
  return get(product, 'warehouses', [])
}

export function productWarehousesSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getProductWarehouses(product)
}

export function productWarehouseSelector(state, {sku, warehouseID}) {
  const productWarehouses = productWarehousesSelector(state, {sku})
  return find(productWarehouses, ({id}) => id === warehouseID)
}

export function createDefaultSupplierWarehousesSelector(
  productSelector,
  defaultSupplierSelector,
) {
  return createSelector(
    productSelector,
    defaultSupplierSelector,
    (product, defaultSupplier) => {
      const warehouses = getProductWarehouses(product)

      if (
        defaultSupplier &&
        defaultSupplier.vendor_config &&
        defaultSupplier.vendor_config.id
      ) {
        return warehouses.filter(
          (warehouse) =>
            warehouse.cart &&
            warehouse.cart.id === defaultSupplier.vendor_config.id,
        )
      }

      return sortBy(warehouses, (warehouse) => warehouse.address.name)
    },
  )
}

export function getProductDefaultSupplier(suppliers) {
  return (suppliers || []).find((supplier) => supplier.is_default)
}

export function createDefaultSupplierSelector(productSelector) {
  return createSelector(
    productSelector,
    suppliersSelector,
    (product, suppliers) => {
      const productSuppliers = getProductSuppliers(product)
      const defaultProductSupplier = getProductDefaultSupplier(productSuppliers)
      const defaultSupplier = get(suppliers, get(defaultProductSupplier, 'id'))

      return defaultProductSupplier
        ? {
            ...defaultProductSupplier,
            ...(defaultSupplier ? {address: defaultSupplier.address} : {}),
          }
        : null
    },
  )
}

export function productDefaultSupplierSelector(state, {sku}) {
  const suppliers = productSuppliersSelector(state, {sku})

  return getProductDefaultSupplier(suppliers)
}

export function getProductDefaultWarehouse(warehouses) {
  return (warehouses || []).find((warehouse) => warehouse.is_default_location)
}

export function getIsDropshippedProduct(fulfillmentType) {
  return [
    DROPSHIPPABLE_FULFILLMENT,
    AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
  ].includes(fulfillmentType)
}

export const productIsDropshippedSelector = createSelector(
  fulfillmentTypeSelector,
  (fulfillmentType) => getIsDropshippedProduct(fulfillmentType),
)

export function getProductName(product) {
  return get(product, 'name', '')
}

export function nameSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getProductName(product)
}

export function getProductUPC(product) {
  return get(product, 'upc') || ''
}

export function createTagsSelector(sku) {
  return createSelector(
    (state) => {
      const product = productSelector(state, {sku})

      return get(product, 'tags', [])
    },
    productTagsSelector,
    (ownTags, tags) => {
      return ownTags.reduce((prev, {id}) => {
        const tag = tags[id]

        if (tag) {
          prev.push(tag)
        }

        return prev
      }, [])
    },
  )
}

export function tagsSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  const ownTags = get(product, 'tags', [])
  const tags = productTagsSelector(state)

  return ownTags.reduce((prev, {id}) => {
    const tag = tags[id]

    if (tag) {
      prev.push(tag)
    }

    return prev
  }, [])
}

export function productUPCSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getProductUPC(product)
}

export function weightSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'weight', 0)
}

export function lengthSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'length', 0)
}

export function heightSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'height', 0)
}

export function widthSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'width', 0)
}

export function getProductCarts(product) {
  return get(product, 'carts', [])
}

export function productCartsSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getProductCarts(product)
}

export function getProductKitComponents(product) {
  return get(product, 'kit_components', getProductKitComponents.default)
}
getProductKitComponents.default = []

export function productKitComponentsSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getProductKitComponents(product)
}

export function getIsKit(product) {
  return getProductKitComponents(product).length > 0
}

export function isKitSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getIsKit(product)
}

export function getLinkedSKUs(product) {
  return get(product, 'linked_skus', getLinkedSKUs.default)
}
getLinkedSKUs.default = []

export function linkedSKUsSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getLinkedSKUs(product)
}

export function linkedBOMSKUsSelector(state, {sku}) {
  return linkedSKUsSelector(state, {sku}).filter(
    ({link_type}) => link_type === BOM_LINK_TYPE,
  )
}

export function productBOMComponentsSelector(state, {sku}) {
  const linkedSKU = linkedBOMSKUsSelector(state, {sku})[0]

  if (!linkedSKU) {
    return productBOMComponentsSelector.default
  }

  const linkedProduct = productSelector(state, {sku: linkedSKU.sku})

  return getProductKitComponents(linkedProduct)
}
productBOMComponentsSelector.default = []

export function getIsBOM(product) {
  return (
    getLinkedSKUs(product).filter(({link_type}) => link_type === BOM_LINK_TYPE)
      .length > 0
  )
}

export function isBOMSelector(state, {sku}) {
  return getIsBOM(productSelector(state, {sku}))
}

export function isAllBOMsSelector(state, {skus}) {
  return skus.every((sku) => isBOMSelector(state, {sku}))
}

export function productComponentsSelector(state, {sku}) {
  const kitComponents = productKitComponentsSelector(state, {sku})

  if (kitComponents.length) {
    return kitComponents
  }

  return productBOMComponentsSelector(state, {sku})
}

export function getTotalOnHand(product) {
  return get(product, 'total_on_hand', 0)
}

export function totalOnHandSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getTotalOnHand(product)
}

export function getTotalAvailable(product) {
  return get(product, 'total_available', 0)
}

export function totalAvailableSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getTotalAvailable(product)
}

export function getTotalCommitted(product) {
  return get(product, 'total_committed', 0)
}

export function totalCommittedSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getTotalCommitted(product)
}

export function getTotalMOOrdered(product) {
  return get(product, 'total_mfg_ordered', 0)
}

export function getTotalPOCommitted(product) {
  return get(product, 'po_total_committed', 0)
}

export function totalPOCommittedSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getTotalPOCommitted(product)
}

export function isArchivedSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return !!get(product, 'archive_date')
}

export function costSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'cost', 0)
}

export function priceSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'price', 0)
}

export function asinSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'asin') || ''
}

export function categorySelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'category') || ''
}

export function hsCodeSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'hs_code') || ''
}

export function customsDescriptionSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'customs_description') || ''
}

export function countryOfOriginSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'country_of_origin') || ''
}

export function productDeclaredValueSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'declared_value') || ''
}

export function hasSerialNumbersSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return !!get(product, 'has_serial_numbers')
}

export function internalNotesSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'internal_notes') || ''
}

export function imageURLSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return get(product, 'image_url')
}

export function getFulfillmentType(product) {
  return get(product, 'fulfillment_type')
}

export function fulfillmentTypeSelector(state, {sku}) {
  const product = productSelector(state, {sku})

  return getFulfillmentType(product)
}

export const canListOnAmazonSelector = createSelector(
  amazonCartsSelector,
  companyFeaturesSelector,
  (amazonCarts, features) =>
    amazonCarts.length > 0 && !!features.temp_amazon_listing_creator,
)

export function getCanEditTotalOnHand(product, warehouse) {
  const hasKitComponents = getProductKitComponents(product).length > 0
  const isFBAWarehouse = getIsFBAWarehouse(warehouse)

  return !hasKitComponents && !isFBAWarehouse
}

export function canEditTotalOnHandSelector(state, {sku, warehouseID}) {
  const product = productSelector(state, {sku})
  const warehouse = warehouseSelector(state, {warehouseID})
  const hasProductUpdateInventoryPermission =
    hasProductUpdateInventoryPermissionSelector(state)

  return (
    hasProductUpdateInventoryPermission &&
    getCanEditTotalOnHand(product, warehouse)
  )
}

export function createCanEditTotalOnHandSelector(
  productSelector,
  warehouseSelector,
) {
  return createSelector(
    productSelector,
    warehouseSelector,
    usesInventorySelector,
    hasProductUpdateInventoryPermissionSelector,
    (product, warehouse, usesInventory, hasProductUpdateInventoryPermission) =>
      usesInventory &&
      hasProductUpdateInventoryPermission &&
      getCanEditTotalOnHand(product, warehouse),
  )
}

export function createProductSelector(sku) {
  return createSelector(productsSelector, (products) => products[sku])
}

export function amazonFulfillmentChannelSelector(state, {sku}) {
  const carts = productCartsSelector(state, {sku})

  return carts.reduce((prev, cart) => {
    if (prev === FULFILLMENT_CHANNEL_FBA) {
      return prev
    }

    return getProductCartFulfillmentChannel(cart) || prev
  }, null)
}

export function getProductCartFulfillmentChannel(productCart) {
  if ([AMAZON, AMAZON_UK, AMAZON_CA].includes(productCart.vendor) === false) {
    return null
  }

  const extraInfo = get(productCart, ['extra_info', 0])

  if (!extraInfo) {
    return null
  }

  return extraInfo.fulfillment_channel !== 'DEFAULT'
    ? FULFILLMENT_CHANNEL_FBA
    : FULFILLMENT_CHANNEL_FBM
}

export function fulfillmentChannelCountsSelector(state, {sku}) {
  const carts = productCartsSelector(state, {sku})

  return carts.reduce(
    (prev, cart) => {
      const fulfillmentChannel = getProductCartFulfillmentChannel(cart)

      prev[fulfillmentChannel] += 1

      return prev
    },
    {FULFILLMENT_CHANNEL_FBA: 0, FULFILLMENT_CHANNEL_FBM: 0},
  )
}

export function getProductCartFulfillmentLatency(productCart) {
  const fulfillment_latency = get(productCart, [
    'extra_info',
    0,
    'fulfillment_latency',
  ])

  return isPositiveNumeric(fulfillment_latency) ? fulfillment_latency : null
}

export function productUnassignedSupplierOptionsSelector(state, {sku}) {
  const productSuppliers = productSuppliersSelector(state, {sku})
  const suppliersOptions = supplierOptionsSelector(state)

  return suppliersOptions.filter(
    (option) => !productSuppliers.find(({id}) => option.value === id),
  )
}

export function productCartSelector(state, {sku, cartID}) {
  const carts = productCartsSelector(state, {sku})

  const productCart = carts.find(({id}) => id === cartID)

  return productCart
}

export function productCartExtraInfoLinkSelector(state, {sku, cartID}) {
  const productCart = productCartSelector(state, {sku, cartID})

  return get(productCart, ['extra_info', 0, '_link']) || null
}

export function productCartFulfillmentLatencySelector(state, {sku, cartID}) {
  const productCart = productCartSelector(state, {sku, cartID})

  return getProductCartFulfillmentLatency(productCart)
}

export const fulfillmentChannelsSelector = createSelector(
  cartVendorsSelector,
  (cartVendors) =>
    PRODUCT_FULFILLMENT_CHANNEL_OPTIONS.filter(
      ({requiresCartVendor}) =>
        intersection(requiresCartVendor || [], cartVendors).length > 0,
    ),
)

export const productFilterOptionsSelector = createSelector(
  usesInventorySelector,
  useDropshippingSelector,
  useKittingSelector,
  hasUseMOsFeatureSelector,
  (usesInventory, useDropshipping, useKitting, hasUseMOsFeature) => {
    let options = PRODUCT_BOOLEAN_FILTER_OPTIONS

    if (!usesInventory) {
      options = options.filter(
        ({value}) =>
          ![
            IS_LOW_INVENTORY_AOH_FILTER,
            IS_LOW_INVENTORY_POH_FILTER,
            IS_BOM_FILTER,
            IS_BOM_COMPONENT_FILTER,
            NEEDS_SYNC_FILTER,
            IS_OVERSOLD_FILTER,
          ].includes(value),
      )
    }

    if (!useDropshipping) {
      options = options.filter(
        ({value}) => ![IS_DROPSHIP_FILTER, IS_REORDERED_FILTER].includes(value),
      )
    }

    if (!useKitting) {
      options = options.filter(
        ({value}) => ![IS_KIT_FILTER, IS_KIT_COMPONENT_FILTER].includes(value),
      )
    }

    if (!hasUseMOsFeature) {
      options = options.filter(({value}) => ![IS_BOM_FILTER].includes(value))
    }

    return options
  },
)

export const productFilterOptionTokensSelector = createSelector(
  productFilterOptionsSelector,
  (productFilterOptions) => tokenizeOptions(productFilterOptions),
)

export function supplierLeadTimeSelector(state, {sku, supplierID}) {
  const productSuppliers = productSuppliersSelector(state, {sku})

  const productSupplier = productSuppliers.find(({id}) => id === supplierID)

  return get(productSupplier, 'supplier_lead_time') || 0
}

export function hasNoProductsSelector(state) {
  const hasLoaded = productsHaveLoadedSelector(state)

  if (!hasLoaded) {
    return false
  }

  const products = productsSelector(state)

  return Object.keys(products).length === 0
}

export function setProductOnPOS({sku, onPOs}) {
  updateProduct(sku, {on_pos: onPOs})
}

export function patchProductTag(sku, productTag, needsAdding = true) {
  const product = productSelector(getState(), {sku})

  if (!product || !productTag) {
    return
  }

  const tags = product.tags.filter(({id}) => id !== productTag.id)

  if (needsAdding) {
    tags.push(productTag)
  }

  updateProduct(sku, {tags})
}

const getProductRequestTokens = {}
export async function getProduct(sku) {
  const requestToken = (getProductRequestTokens[sku] = {})
  try {
    const {json} = await api.get(`/product/${encodeURIComponent(sku)}/`)

    if (requestToken !== getProductRequestTokens[sku]) {
      return
    }

    setProduct(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while getting product.',
        details: err.message,
      },
      err,
    )
  }
}

export async function loadBatch(batch, type) {
  if (batch.length === 0) {
    return
  } else if (batch.length === 1) {
    const {json} = await api.get(`/product/${encodeURIComponent(batch[0])}/`)

    setProduct(json)
  } else {
    const {json} = await api.get('/product', {
      product_type: type,
      sku: batch,
      limit: 100,
    })

    addProducts(json.product)
  }
}

export async function ensureProductsLoaded(productSKUs, {refresh, type} = {}) {
  try {
    const products = productsSelector(getState())
    const stillNeeded = uniq(
      refresh
        ? productSKUs
        : productSKUs.filter((productSKU) => !products[productSKU]),
    )

    if (stillNeeded.length === 0) {
      return
    }
    const batches = skuBatches(stillNeeded)
    await Promise.all(batches.map((batch) => loadBatch(batch, type)))
  } catch (err) {
    showGlobalError(
      {
        summary: `Something went wrong while getting product.`,
        details: err.message,
      },
      err,
    )
  }
}

export function skuBatches(skus, {characterLimit, countLimit} = {}) {
  characterLimit = characterLimit || 1900
  countLimit = countLimit || 100
  let totalLength = 0
  let count = 0

  const [withinLimit, overLimit] = partition(skus, (sku) => {
    // add the sku length plus the 5 extra characters in '&sku='
    totalLength += encodeURIComponent(sku).length + 5
    count += 1
    return totalLength <= characterLimit && count <= countLimit
  })

  if (overLimit.length === 0) {
    return [withinLimit]
  }

  return [withinLimit].concat(
    skuBatches(overLimit, {characterLimit, countLimit}),
  )
}

export async function ensureLinkedSKUsLoaded(productSKUs) {
  const products = productsSelector(getState())

  const skusByType = productSKUs.reduce((prev, sku) => {
    const product = products[sku]

    if (!product || !product.linked_skus) {
      return prev
    }

    product.linked_skus.forEach(({link_type, sku}) => {
      prev[link_type] = prev[link_type] || {}

      prev[link_type][sku] = true
    })

    return prev
  }, {})

  const bomSKUs = Object.keys(skusByType[BOM_LINK_TYPE] || {})

  if (bomSKUs.length) {
    await ensureProductsLoaded(bomSKUs, {type: BOM_LINK_TYPE})
  }
}

// Get some products to see if we have any.
// We don't need to get all of them, just more than zero
export async function getSomeProducts() {
  try {
    const {json} = await api.get('/product/')

    addProducts(json.product)
  } finally {
    dispatch(updateHasLoaded({products: true}))
  }
}

export function useSetupIfNeedsProduct({addToCreateOrder} = {}) {
  const hasNoProducts = useSelector(hasNoProductsSelector)

  useEffect(() => {
    if (!hasNoProducts) {
      return
    }

    showCreateProductModal({addToCreateOrder})
  }, [hasNoProducts])
}

export async function saveProduct(sku, params) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.put(productLink, params)

  return json
}

export async function archive(sku) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.post(`${productLink}archive/`)

  return json
}

export async function unarchive(sku) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.post(`${productLink}unarchive/`)

  return json
}

export async function saveWarehouse(warehouseID, sku, params) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.put(`${productLink}warehouse/${warehouseID}`, params)

  return json
}

export async function saveProductCartExtraInfo(sku, cartID, params) {
  const productCartExtraInfoLink = productCartExtraInfoLinkSelector(
    getState(),
    {sku, cartID},
  )

  const {json: extraInfo} = await api.put(productCartExtraInfoLink, params)

  const product = productSelector(getState(), {sku})

  const carts = product.carts.map((cart) => {
    if (cart.id === cartID) {
      cart.extra_info[0] = extraInfo
    }

    return cart
  })

  updateProduct(sku, {carts})
}

export async function saveProductFulfillment(sku, params) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.put(`${productLink}fulfillment/`, params)

  return json
}

export async function listOnAmazon(skus, cartID) {
  const task = {
    type: 'create_products_in_cart',
    cart: cartID,
    params: {skus},
  }

  try {
    await api.post('/task', task)

    dispatch(checkRunningTasks())

    showMessageToast(
      `Attempting to list ${skus.length} product${
        skus.length > 1 ? 's' : ''
      } on Amazon...`,
    )
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while creating amazon listing.',
        details: `${err.error_message || err.message}`,
      },
      err,
    )
  }
}

export async function exportInventoryLog(sku) {
  const task = {
    type: 'export_inventory_log',
    params: {sku},
  }

  try {
    await api.post('/new_task', task)

    dispatch(checkRunningTasks())

    showMessageToast(`Started exporting inventory log for ${sku}...`)
  } catch (err) {
    showGlobalError(
      {
        summary:
          'Something went wrong while creating export inventory log task.',
        details: `${err.error_message || err.message}`,
      },
      err,
    )
  }
}

export async function saveProductSupplier(supplierID, sku, params) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.put(`${productLink}supplier/${supplierID}/`, params)

  return json
}

export async function removeSupplier(supplierID, sku) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.delete(`${productLink}supplier/${supplierID}/`)

  return json
}

export async function setDefaultDropshipper(supplierID, sku) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.post(
    `${productLink}default_dropshipper/${supplierID}/`,
  )

  return json
}

export async function removeDefaultDropshipper(sku) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.delete(`${productLink}default_dropshipper/`)

  return json
}

export async function setDefaultSupplier(supplierID, sku) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.post(`${productLink}default_supplier/${supplierID}/`)

  return json
}

export async function removeDefaultSupplier(sku) {
  const productLink = getProductLinkFromSku(sku)

  const {json} = await api.delete(`${productLink}default_supplier/`)

  return json
}
