import {getState, updateForm, setForm, formsSelector} from '../store.js'
import apiverson from '../common/apiverson.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {AWAITING_FULFILLMENT} from '../common/constants/Orders.js'
import throttle from 'lodash/throttle.js'
import {getRealDate} from '../common/date.js'
import {currentPageSelector} from '../redux/selectors/ui/index.js'
import {ORDER_LIST_PAGE} from '../common/constants/Pages.js'

export const ORDER_COUNTS = 'ORDER_COUNTS'

export function setOrderCounts(counts) {
  if (formsSelector(getState())[ORDER_COUNTS]) {
    updateForm(ORDER_COUNTS, counts)
  } else {
    setForm(ORDER_COUNTS, counts)
  }
}

export function orderCountsSelector(state) {
  return formsSelector(state)[ORDER_COUNTS] || orderCountsSelector.default
}
orderCountsSelector.default = {}

export function allCountSelector(state) {
  return orderCountsSelector(state).all || 0
}

export function awaitingFulfillmentCountSelector(state) {
  return orderCountsSelector(state).awaiting_fulfillment || 0
}

export function needPrintingCountSelector(state) {
  return orderCountsSelector(state).unprinted_shipped || 0
}

export function orderCountsHaveLoadedSelector(state) {
  return !!formsSelector(state)[ORDER_COUNTS]
}

export function hasOrdersCreatedSelector(state) {
  return allCountSelector(state) > 0
}

export function newOrderCountSelector(state) {
  return orderCountsSelector(state).new_awaiting_fulfillment || 0
}

export async function updateOrderCounts() {
  try {
    const {json} = await apiverson.get('/order/counts')

    setOrderCounts(json)
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error getting order counts.',
        details: error.message,
      },
      error,
    )
  }
}

export async function updateOrderRevisionCount() {
  const {json} = await apiverson.get('/order', {
    status: AWAITING_FULFILLMENT,
    has_revision: true,
    limit: 0,
  })

  setOrderCounts({has_revision: json.count})
}

export function acknowledgeNewOrders() {
  setOrderCounts({
    new_time_of_last_acknowledgement: getRealDate().toISOString(),
    new_awaiting_fulfillment: 0,
  })
}

export async function checkForNewOrders() {
  const {new_awaiting_fulfillment, new_time_of_last_acknowledgement} =
    orderCountsSelector(getState())

  if (!new_time_of_last_acknowledgement) {
    acknowledgeNewOrders()

    return
  }

  if (currentPageSelector(getState()) !== ORDER_LIST_PAGE) {
    return
  }

  const now = getRealDate().toISOString()

  const params = {
    created_after: new_time_of_last_acknowledgement,
  }

  const {json} = await apiverson.get('/order/counts', params)

  const updates = {}

  if (json.awaiting_fulfillment !== new_awaiting_fulfillment) {
    updates.new_awaiting_fulfillment = json.awaiting_fulfillment
  }

  if (json.awaiting_fulfillment === 0) {
    updates.new_time_of_last_acknowledgement = now
  }

  if (Object.keys(updates).length) {
    setOrderCounts(updates)
  }
}

export const checkForNewOrdersThrottled = throttle(checkForNewOrders, 30 * 1000)
