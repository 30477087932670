import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import debounce from 'lodash/debounce.js'

import {setForm, updateForm, formsSelector, getState} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'

export const PRINT_CONFIGS = 'PRINT_CONFIGS'

export function printConfigsSelector(state) {
  return formsSelector(state)[PRINT_CONFIGS] || printConfigsSelector.default
}
printConfigsSelector.default = {}

export function printConfigsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PRINT_CONFIGS]
}

export function printConfigSelector(state, {printConfigID}) {
  return printConfigsSelector(state)[printConfigID]
}

export const printConfigsByNameSelector = createSelector(
  printConfigsSelector,
  (printConfigs) => keyBy(printConfigs, 'name'),
)

export const printConfigsSortedByNameSelector = createSelector(
  printConfigsByNameSelector,
  (printConfigsByName) =>
    Object.keys(printConfigsByName)
      .sort()
      .map((name) => printConfigsByName[name])
      .filter((printConfig) => !printConfig.archive_date),
)

export function setPrintConfigs(printConfigs) {
  setForm(PRINT_CONFIGS, keyBy(printConfigs, 'id'))
}

export function setPrintConfig(printConfig) {
  updateForm(PRINT_CONFIGS, {[printConfig.id]: printConfig})
}

export async function getPrintConfigs() {
  try {
    const printConfigs = await fetchAllAPI('/print_config/', 'print_config')

    setPrintConfigs(printConfigs)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting print configs.',
        details: err.message || err.error_message,
      },
      `Error getting print configs. ${err.error_message || err.message}`,
      err,
    )

    setPrintConfigs([])
  }

  return printConfigsSelector(getState())
}

export const getPrintConfigsDebounced = debounce(getPrintConfigs, 500)
