import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {
  orderCountsHaveLoadedSelector,
  awaitingFulfillmentCountSelector,
  needPrintingCountSelector,
} from '../../data/orderCounts.js'
import {canUseOrdersSelector} from '../../data/orders.js'
import {buildPath} from '../../common/querystring.js'
import {PRODUCT_PLURAL_URI_COMPONENT} from '../../common/constants/Products.js'
import {formSelector, onlyIfAutoForm, updateForm} from '../../store.js'
import {
  hasTooManyProductsToHandleSelector,
  usesInventorySelector,
} from '../../data/company.js'
import {PluralBlock, Count, Plural} from '../../common/components/Plural.js'
import {hasProductPermissionSelector} from '../../data/me.js'
import api from '../../common/api.js'
import {setShowUnprintedOnly} from '../OrderListPage/orderListActions.js'
import {AWAITING_FULFILLMENT, SHIPPED} from '../../common/constants/Orders.js'
import {orderListHashBuilder} from '../OrderListPage/orderListSelectors.js'

const ACTION_ITEMS = 'ACTION_ITEMS'

function setActionItemsForm() {
  return {
    formName: ACTION_ITEMS,
    initialForm: {
      isLoading: false,
      lowInventoryCount: 0,
    },
  }
}

function actionItemsFormSelector(state) {
  return formSelector(state, {formName: ACTION_ITEMS})
}

async function refreshActionItems(cancel) {
  const reqToken = {}

  if (cancel.isCancelled) {
    return
  }

  refreshActionItems.reqToken = reqToken
  updateForm(ACTION_ITEMS, {isLoading: true})

  try {
    const {json} = await api.get('/product', {
      active: true,
      low_inventory_poh: true,
      limit: 0,
    })

    if (reqToken !== refreshActionItems.reqToken) {
      return
    }

    updateForm(ACTION_ITEMS, {lowInventoryCount: json.count})

    setTimeout(() => refreshActionItems(cancel), 120 * 1000)
  } catch (err) {
    updateForm(ACTION_ITEMS, {lowInventoryCount: 0})
  } finally {
    updateForm(ACTION_ITEMS, {isLoading: false})
  }
}

export default onlyIfAutoForm(function ActionItems() {
  const orderCountsHaveLoaded = useSelector(orderCountsHaveLoadedSelector)
  const awaitingFulfillmentCount = useSelector(awaitingFulfillmentCountSelector)
  const needPrintingCount = useSelector(needPrintingCountSelector)
  const canUseOrders = useSelector(canUseOrdersSelector)
  const usesInventory = useSelector(usesInventorySelector)
  const hasProductPermission = useSelector(hasProductPermissionSelector)
  const hasTooManyProductsToHandle = useSelector(
    hasTooManyProductsToHandleSelector,
  )
  const canUseProducts = usesInventory && hasProductPermission

  const {lowInventoryCount, isLoading: lowInventoryCountIsLoading} =
    useSelector(actionItemsFormSelector)

  useEffect(() => {
    if (hasTooManyProductsToHandle) {
      return
    }

    const cancel = {isCancelled: false}
    refreshActionItems(cancel)

    return () => {
      cancel.isCancelled = true
    }
  }, [hasTooManyProductsToHandle])

  const isLoading = !(orderCountsHaveLoaded && !lowInventoryCountIsLoading)
  const showOrderActionItems =
    orderCountsHaveLoaded &&
    (!!awaitingFulfillmentCount || !!needPrintingCount) &&
    canUseOrders
  const showProductActionItems =
    !!lowInventoryCount && canUseProducts && !hasTooManyProductsToHandle
  const showActionItems = showOrderActionItems || showProductActionItems

  return (
    <div className="panel panel--dashboard panel--dashboard-action-items flex-item">
      <div className="panel__header panel__header--dashboard">
        <h3 className="subheader subheader--dashboard fs-00">Action Items</h3>
      </div>
      <div className="panel__body">
        {isLoading ? (
          <div className="loading">
            <p className="graph-loading" />
          </div>
        ) : !showActionItems ? (
          <p className="fs-02 lh-md align-center make-medium-grey margin-top-25 margin-bottom-25">
            Nothing right now!
          </p>
        ) : (
          <ul className="list square">
            {showOrderActionItems && awaitingFulfillmentCount > 0 && (
              <PluralBlock count={awaitingFulfillmentCount}>
                <li className="list__item list__item--action-item fs-00">
                  <a
                    className="btn--link darker"
                    href={orderListHashBuilder({
                      status: [AWAITING_FULFILLMENT],
                    })}
                  >
                    <strong>Awaiting Fulfillment:</strong>{' '}
                    <span>
                      <Count /> <Plural word="order" />
                    </span>
                  </a>
                </li>
              </PluralBlock>
            )}

            {showProductActionItems && lowInventoryCount > 0 && (
              <PluralBlock count={lowInventoryCount}>
                <li className="list__item list__item--action-item fs-00">
                  <a
                    className="btn--link darker"
                    href={
                      '#' +
                      buildPath([PRODUCT_PLURAL_URI_COMPONENT], {
                        isActive: true,
                        isLowInventory: true,
                      })
                    }
                  >
                    <strong>Low Inventory:</strong>{' '}
                    <span>
                      <Count /> <Plural word="product" />
                    </span>
                  </a>
                </li>
              </PluralBlock>
            )}

            {showOrderActionItems && needPrintingCount > 0 && (
              <PluralBlock count={needPrintingCount}>
                <li className="list__item list__item--action-item fs-00">
                  <a
                    className="btn--link darker"
                    href={orderListHashBuilder({status: [SHIPPED]})}
                    onClick={() => setShowUnprintedOnly(true)}
                  >
                    <Count /> <Plural word="label" />{' '}
                    <Plural s="has" p="have" /> not been printed
                  </a>
                </li>
              </PluralBlock>
            )}
          </ul>
        )}
      </div>
    </div>
  )
}, setActionItemsForm)
