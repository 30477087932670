import {createSelector} from 'reselect'

import {isPresent} from '../../../common/utils.js'
import parseAddress from '../../../common/parseAddress.js'
import {pickAddress} from '../../../common/address.js'
import {
  getState,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import {
  warehouseAddressSelector,
  nonFBAWarehouseOptionsSelector,
} from '../../../data/warehouses.js'
import {
  supplierAddressSelector,
  activeNonFBASupplierOptionsSelector,
} from '../../../data/suppliers.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {COMMUNICATION_DROPSHIPMENT} from '../../../common/constants/Suppliers.js'

export const EDIT_ADDRESS_MODAL = 'EDIT_ADDRESS_MODAL'

export function updateModalForm(updates) {
  updateForm(EDIT_ADDRESS_MODAL, updates)
}

export function removeModalForm() {
  removeForm(EDIT_ADDRESS_MODAL)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[EDIT_ADDRESS_MODAL],
)

export const hasAnyChangedSelector = createSelector(modalFormSelector, (form) =>
  [
    'name',
    'company',
    'street1',
    'street2',
    'city',
    'state',
    'zip',
    'country',
    'phone',
    'email',
  ].reduce((prev, param) => {
    if (prev) {
      return true
    }

    if (form[`hasChanged_${param}`]) {
      return true
    }
    return false
  }, false),
)

export const statusSelector = createSelector(
  modalFormSelector,
  hasAnyChangedSelector,
  ({validation}, hasAnyChanged) => {
    if (hasAnyChanged) {
      return 'needsSaving'
    }

    return validation ? validation.status : null
  },
)

export const titleSelector = createSelector(statusSelector, (status) => {
  if (status === 'validating') {
    return null
  }

  if (status === 'error') {
    return 'Invalid Address'
  }

  if (status === 'warning') {
    return 'Warning'
  }

  return null
})

export const messageSelector = createSelector(
  modalFormSelector,
  statusSelector,
  ({validation}, status) => {
    if (status === 'validating') {
      return null
    }

    if (status === 'needsSaving') {
      return 'The address needs to be revalidated. This will happen when you save it.'
    }

    return validation ? validation.additional_text : null
  },
)

export const errorsSelector = createSelector(
  modalFormSelector,
  ({required, ...form}) => {
    const errors = {}

    if (required.includes('title') && !isPresent(form.title)) {
      errors.title = 'Title is required'
    }

    if (required.includes('name') && !isPresent(form.name)) {
      errors.name = 'Name is required'
    }

    if (required.includes('company') && !isPresent(form.company)) {
      errors.company = 'Company is required'
    }

    if (required.includes('street1') && !isPresent(form.street1)) {
      errors.street1 = 'Street 1 is required'
    }

    if (required.includes('street2') && !isPresent(form.street2)) {
      errors.street2 = 'Street 2 is required'
    }

    if (required.includes('city') && !isPresent(form.city)) {
      errors.city = 'City is required'
    }

    if (required.includes('state') && !isPresent(form.state)) {
      errors.state = 'State is required'
    }

    if (required.includes('zip') && !isPresent(form.zip)) {
      errors.zip = 'Zip is required'
    }

    if (required.includes('country') && !isPresent(form.country)) {
      errors.country = 'Country is required'
    }

    if (required.includes('phone') && !isPresent(form.phone)) {
      errors.phone = 'Phone is required'
    }

    if (required.includes('email') && !isPresent(form.email)) {
      errors.email = 'Email is required'
    }

    return errors
  },
)

export const addressCopyOptionsSelector = createSelector(
  nonFBAWarehouseOptionsSelector,
  activeNonFBASupplierOptionsSelector,
  (warehouseOptions, supplierOptions) => [
    {value: '', display: 'Select an address to fill form...'},
    ...(warehouseOptions.length > 0
      ? [{disabled: true, display: '--- Warehouses ---'}]
      : []),
    ...warehouseOptions.map(({value, display}) => ({
      value: `warehouse_id__${value}`,
      display,
    })),
    ...(supplierOptions.length > 0
      ? [{disabled: true, display: '--- Suppliers ---'}]
      : []),
    ...supplierOptions.map(({value, display}) => ({
      value: `supplier_id__${value}`,
      display,
    })),
  ],
)

export function markChanged(address, hasChanged = true) {
  return Object.keys(pickAddress(address)).reduce((prev, param) => {
    prev[`hasChanged_${param}`] = hasChanged

    return prev
  }, {})
}

export function updateAddressFromID(type, id) {
  try {
    const address =
      type === 'warehouse_id'
        ? warehouseAddressSelector(getState(), {warehouseID: id})
        : type === 'supplier_id'
          ? supplierAddressSelector(getState(), {
              supplierID: id,
              type: COMMUNICATION_DROPSHIPMENT,
            })
          : null

    if (address) {
      updateModalForm({
        ...pickAddress(address),
        ...markChanged(address, true),
      })
    }
  } catch (err) {
    // long hair, don't care
  }
}

export async function saveAddress() {
  try {
    const {onSave, toastMessage, tookSuggestion, ...form} =
      modalFormSelector(getState())
    // clean up address shape and values
    const address = Object.entries(pickAddress(form)).reduce(
      (prev, [key, value]) => {
        prev[key] = isPresent(value) ? value.trim() : value
        return prev
      },
      {},
    )
    const hasAnyChanged = hasAnyChangedSelector(getState())

    updateModalForm({isSaving: true})

    if (hasAnyChanged && onSave) {
      await onSave(address, {tookSuggestion})

      showMessageToast(toastMessage)
    }

    removeModalForm()
  } catch (err) {
    updateModalForm({
      serverError: `Error updating address: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

export function setAddressFromParse() {
  const {addressFreeText} = modalFormSelector(getState())
  const newAddress = parseAddress(addressFreeText)

  updateModalForm({
    ...newAddress,
    ...markChanged(newAddress, true),
  })
}

export function resetAddress() {
  const {originalAddress} = modalFormSelector(getState())

  updateModalForm({
    ...originalAddress,
    ...markChanged(originalAddress, false),
  })
}
