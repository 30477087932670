import PropTypes from 'prop-types'

import {DataImportDataShape} from '../../../../common/PropTypes.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import TextArea from '../../../../common/components/Form/TextArea.js'
import Row from '../../../../common/components/Form/Row.js'
import BaseEditForm from '../BaseEditForm.js'

export default function EditForm(props) {
  const data = props.data
  const index = data.index

  return (
    <BaseEditForm className="medium-6 columns" {...props}>
      <Row>
        <TextInput
          value={data.payload.sku}
          errorMessage={data.errors.sku}
          name="sku"
          label="SKU"
          className="medium-12"
          onChange={(value) => props.updatePayload(index, {sku: value})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.name}
          errorMessage={data.errors.name}
          name="name"
          label="Description"
          className="medium-12"
          onChange={(value) => props.updatePayload(index, {name: value})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.category}
          errorMessage={data.errors.category}
          name="category"
          label="Category"
          className="medium-12"
          onChange={(value) => props.updatePayload(index, {category: value})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.price}
          errorMessage={data.errors.price}
          name="price"
          label="Price"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {price: value})}
        />
        <TextInput
          value={data.payload.weight}
          errorMessage={data.errors.weight}
          name="weight"
          label="Weight (lbs)"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {weight: value})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.cost}
          errorMessage={data.errors.cost}
          name="cost"
          label="Weighted Avg Unit Cost"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {cost: value})}
        />
        <TextInput
          value={data.payload.length}
          errorMessage={data.errors.length}
          name="length"
          label="Length"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {length: value})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.width}
          errorMessage={data.errors.width}
          name="width"
          label="Width"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {width: value})}
        />
        <TextInput
          value={data.payload.height}
          errorMessage={data.errors.height}
          name="height"
          label="Height"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {height: value})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.upc}
          errorMessage={data.errors.upc}
          name="upc"
          label="UPC"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {upc: value})}
        />
        <TextInput
          value={data.payload.asin}
          errorMessage={data.errors.asin}
          name="asin"
          label="ASIN"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {asin: value})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.hs_code}
          errorMessage={data.errors.hs_code}
          name="hs_code"
          label="Harmonized Code"
          className="medium-6"
          onChange={(value) => props.updatePayload(index, {hs_code: value})}
        />
        <TextInput
          value={data.payload.country_of_origin}
          errorMessage={data.errors.country_of_origin}
          name="country_of_origin"
          label="Country Of Origin"
          className="medium-6"
          onChange={(value) =>
            props.updatePayload(index, {country_of_origin: value})
          }
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.declared_value}
          errorMessage={data.errors.declared_value}
          name="declared_value"
          label="Declared Value"
          className="medium-6"
          onChange={(value) =>
            props.updatePayload(index, {declared_value: value})
          }
        />
        <TextInput
          value={data.payload.customs_description}
          errorMessage={data.errors.customs_description}
          name="customs_description"
          label="Customs Description"
          className="medium-6"
          onChange={(value) =>
            props.updatePayload(index, {customs_description: value})
          }
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.has_serial_numbers}
          errorMessage={data.errors.has_serial_numbers}
          name="has_serial_numbers"
          label="Has Serial Numbers"
          className="medium-6"
          onChange={(value) =>
            props.updatePayload(index, {has_serial_numbers: value})
          }
        />
      </Row>
      <Row>
        <TextArea
          value={data.payload.internal_notes}
          errorMessage={data.errors.internal_notes}
          name="internal_notes"
          label="Internal Notes"
          className="medium-12"
          rows="5"
          onChange={(value) =>
            props.updatePayload(index, {internal_notes: value})
          }
        />
      </Row>
    </BaseEditForm>
  )
}

EditForm.propTypes = {
  data: DataImportDataShape.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
