import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  getState,
} from '../../store.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import className from '../../common/className.js'
import {
  isNonZeroPositiveNumeric,
  isPositiveNumeric,
  isPresent,
} from '../../common/utils.js'
import {autoFillPostageConfigSelector, setShipper} from '../../data/shippers.js'
import api from '../../common/api.js'
import {
  CREDIT_CARD_PT,
  defaultPaymentAccountSelector,
} from '../../data/paymentAccounts.js'
import {
  netZeroPaymentDetailsSelector,
  usePaymentAccountsForCCSelector,
} from '../../data/company.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showAccountCreditCardModal} from './AccountCreditCardModal.js'

const MODAL_FORM = 'AUTO_FILL_POSTAGE_MODAL'

export function showAutoFillPostageModal(shipperID) {
  const {isEnabled, lowerThreshold, incrementAmount} =
    autoFillPostageConfigSelector(getState(), {shipperID})

  setForm(MODAL_FORM, {
    shipperID,
    isEnabled: !!isEnabled,
    lowerThreshold: String(lowerThreshold || ''),
    incrementAmount: String(incrementAmount || ''),
    serverError: null,
    isSaving: false,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const {lowerThreshold, incrementAmount} = modalFormSelector(state)
  const {disableAutoFill} = autoFillNoticeSelector(state)
  const errors = {}

  if (disableAutoFill) {
    errors.preventSave = true
  }

  if (isPresent(lowerThreshold) && !isPositiveNumeric(lowerThreshold)) {
    errors.lowerThreshold = 'Lower threshold must be a positive number'
    errors.preventSave = true
  }

  if (
    isPresent(incrementAmount) &&
    !isNonZeroPositiveNumeric(incrementAmount)
  ) {
    errors.incrementAmount = 'Increment amount must be a positive number'
    errors.preventSave = true
  }

  return errors
}

export function autoFillNoticeSelector(state) {
  const defaultPaymentAccount = defaultPaymentAccountSelector(state)
  const usePaymentAccountsForCC = usePaymentAccountsForCCSelector(state)
  const netZeroPaymentDetails = netZeroPaymentDetailsSelector(state)

  return {
    disableAutoFill: Object.keys(netZeroPaymentDetails).length === 0,
    usePaymentAccountsForCC,
    netZeroPaymentDetails: netZeroPaymentDetailsSelector(state),
    defaultIsCC: defaultPaymentAccount?.payment_type === CREDIT_CARD_PT,
  }
}

export async function saveAutoFillPostage() {
  try {
    const {shipperID, isEnabled, lowerThreshold, incrementAmount} =
      modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const {json} = await api.put(`/shipper/${shipperID}/`, {
      autorefill_config: {
        enabled: isEnabled,
        lower_threshold: Number(lowerThreshold) || 0,
        increment_amount: Number(incrementAmount) || 1,
      },
    })

    setShipper(json)

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function AutoFillPostageModal({form}) {
  const errors = useSelector(errorsSelector)

  const {
    disableAutoFill,
    usePaymentAccountsForCC,
    netZeroPaymentDetails,
    defaultIsCC,
  } = useSelector(autoFillNoticeSelector)

  return (
    <ConfirmModal
      title="Manage Postage Auto-Fill"
      modalSize="sm"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => saveAutoFillPostage()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      {disableAutoFill ? (
        <dl className="alert alert--warning margin-bottom-20">
          <dt className="fs-01 lh-md margin-bottom-10">
            Add a credit card to enable auto-fill.
          </dt>
          <dd className="fs-00 lh-md margin-bottom-5">
            <ButtonPrimary
              size="sm"
              isOutlined
              onClick={() => showAccountCreditCardModal()}
            >
              Add a Credit Card
            </ButtonPrimary>
          </dd>
        </dl>
      ) : usePaymentAccountsForCC && !defaultIsCC ? (
        <dl className="alert alert--warning margin-bottom-20">
          <dt className="fs-01 lh-md margin-bottom-10">
            Auto-fill cannot be configured with ACH accounts in Ordoro at this
            time.
          </dt>
          <dd className="fs-00 lh-md margin-bottom-10">
            You can still enable auto-fill, but please note that it will pull
            from this credit card:
          </dd>
          <dd className="fs-00 lh-md margin-bottom-0">
            <strong>
              {netZeroPaymentDetails.brand} (ending in{' '}
              {netZeroPaymentDetails.last4})
            </strong>
          </dd>
        </dl>
      ) : null}
      {!disableAutoFill && (
        <div className="margin-bottom-15">
          <ul className="list list--no-style">
            <li className="list__item margin-bottom-0">
              <label htmlFor="id_is_enabled">
                <input
                  type="checkbox"
                  className="margin-bottom-0 margin-right-5"
                  name="is_enabled"
                  id="id_is_enabled"
                  checked={form.isEnabled}
                  onChange={(event) =>
                    updateModalForm({isEnabled: event.target.checked})
                  }
                  disabled={disableAutoFill}
                />
                <span>Enable postage auto-fill</span>
              </label>
            </li>
            {form.isEnabled && (
              <>
                <li
                  className={className`list__item margin-top-10 ${{
                    error: errors.lowerThreshold,
                  }}`}
                >
                  <label htmlFor="id_lower_threshold">Lower Threshold</label>
                  <input
                    type="text"
                    className="input--md input--tall txt--lg"
                    name="lower_threshold"
                    id="id_lower_threshold"
                    value={form.lowerThreshold}
                    onChange={(event) =>
                      updateModalForm({lowerThreshold: event.target.value})
                    }
                  />
                  {errors.lowerThreshold && (
                    <small className="error error-message">
                      {errors.lowerThreshold}
                    </small>
                  )}
                </li>
                <li
                  className={className`list__item ${{
                    error: errors.incrementAmount,
                  }}`}
                >
                  <label htmlFor="id_increment_amount">Increment Amount</label>
                  <input
                    type="text"
                    className="input--md input--tall txt--lg"
                    name="increment_amount"
                    id="id_increment_amount"
                    value={form.incrementAmount}
                    onChange={(event) =>
                      updateModalForm({incrementAmount: event.target.value})
                    }
                  />
                  {errors.incrementAmount && (
                    <small className="error error-message">
                      {errors.incrementAmount}
                    </small>
                  )}
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </ConfirmModal>
  )
}

export default onlyIfForm(AutoFillPostageModal, modalFormSelector)
