import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import apiverson from '../../../common/apiverson.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  setOrderStateAndEnsureProductsLoaded,
  saveFinancialInfo,
  financialInfoSelector,
  ordersByOrderIDSelector,
  orderSelector,
  isDropshippedSelector,
} from '../../../data/orders.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {OrderLineShape} from '../../../common/PropTypes.js'
import Checkbox from '../../../common/components/Checkbox.js'
import {buildPath} from '../../../common/querystring.js'
import handleListSelection from '../../../common/handleListSelection.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {ORDER_LIST_PAGE} from '../../../common/constants/Pages.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'
import {isNumeric} from '../../../common/utils.js'
import Currency from '../../../common/components/Currency.js'
import Quantity from '../../../common/components/Quantity.js'
import {orderListQuerySelector} from '../orderListSelectors.js'
import {preferCartOrderLineNamesSelector} from '../../../data/company.js'
import Address from '../../../common/components/Order/Address.js'
import {refreshShippingAndReturnRates} from '../../../data/labelInfos/index.js'
import {useMemo} from 'react'

const MODAL_FORM = 'ORDER_REVISION_MODAL'
const SHIPPING_ADDRESS_REVISION = 'SHIPPING_ADDRESS_REVISION'

export async function showOrderRevisionModal(orderNumbers, index = 0) {
  setForm(MODAL_FORM, {
    orderNumbers,
    index,
    revision: null,
    loadingRevision: false,
    selectedRevisionsKeys: [],
    isSaving: false,
    serverError: null,
  })

  await getRevision(orderNumbers[index])
}

export async function informOrderRevisionModalOfUpdate(payload) {
  const form = modalFormSelector(getState())

  if (!form) {
    return
  }

  const {order_id} = payload

  const order = ordersByOrderIDSelector(getState())[order_id]
  const orderNumber = form.orderNumbers[form.index]

  if (!order || order.order_number !== orderNumber) {
    return
  }

  await getRevision(orderNumber)
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

function setSelectedRevisionsKeys(selectedRevisionsKeys) {
  updateModalForm({selectedRevisionsKeys})
}

function toggleSelectedRevisionKey(revisionKey, isSelected, isShiftKey) {
  const {selectedRevisionsKeys} = modalFormSelector(getState())
  const {allRevisionKeys} = revisionSummarySelector(getState())

  setSelectedRevisionsKeys(
    handleListSelection({
      value: revisionKey,
      isSelected,
      isShiftKey,
      selected: selectedRevisionsKeys,
      list: allRevisionKeys,
    }),
  )
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

const revisionSummarySelector = createSelector(
  createSelector(
    (state) => state,
    modalFormSelector,
    (state, {orderNumbers, index}) =>
      orderSelector(state, {orderNumber: orderNumbers[index]}),
  ),
  modalFormSelector,
  (order, {revision}) => {
    const summary = {
      lines: [],
      hasRevision: false,
      allRevisionKeys: [],
      allLineRevisionKeys: [],
    }

    if (!revision || revision.diff_ignored) {
      return summary
    }

    if (revision.diff.shipping_address) {
      summary.shipping_address = {
        ...order.shipping_address,
        changes: revision.diff.shipping_address,
      }

      summary.allRevisionKeys.push(SHIPPING_ADDRESS_REVISION)
    }

    for (const [index, line] of revision.diff.lines_added?.entries() || []) {
      const revisionKey = `added__${index}`

      summary.lines.push({
        ...line,
        type: 'added',
        typeIndex: index,
        revisionKey,
      })

      summary.allRevisionKeys.push(revisionKey)
      summary.allLineRevisionKeys.push(revisionKey)
    }

    for (const [index, lineChanges] of revision.diff.lines_changed?.entries() ||
      []) {
      const line = order.lines.find(({id}) => lineChanges.id === id)

      if (line) {
        const revisionKey = `changed__${index}`

        summary.lines.push({
          ...line,
          changes: lineChanges,
          type: 'changed',
          typeIndex: index,
          revisionKey,
        })

        summary.allRevisionKeys.push(revisionKey)
        summary.allLineRevisionKeys.push(revisionKey)
      }
    }

    for (const [
      index,
      removedLineID,
    ] of revision.diff.lines_removed?.entries() || []) {
      const line = order.lines.find(({id}) => removedLineID === id)

      if (line) {
        const revisionKey = `removed__${index}`

        summary.lines.push({
          ...line,
          removed: true,
          type: 'removed',
          typeIndex: index,
          revisionKey,
        })

        summary.allRevisionKeys.push(revisionKey)
        summary.allLineRevisionKeys.push(revisionKey)
      }
    }

    if (summary.allRevisionKeys.length) {
      summary.hasRevision = true
    }

    return summary
  },
)

function paramsSelector() {
  const {revision, selectedRevisionsKeys} = modalFormSelector(getState())

  const refreshes = {}
  const params = {}

  if (!revision) {
    return params
  }

  if (
    revision.diff.shipping_address &&
    selectedRevisionsKeys.includes(SHIPPING_ADDRESS_REVISION)
  ) {
    params.apply_shipping_address = Object.keys(revision.diff.shipping_address)

    refreshes.rates = true
  }

  for (const [index, line] of revision.diff.lines_added?.entries() || []) {
    if (!selectedRevisionsKeys.includes(`added__${index}`)) {
      continue
    }

    if (!params.apply_lines_added) {
      params.apply_lines_added = []
    }

    params.apply_lines_added.push(line.cart_orderitem_id)

    refreshes.financials = true
  }

  for (const [index, {id}] of revision.diff.lines_changed?.entries() || []) {
    if (!selectedRevisionsKeys.includes(`changed__${index}`)) {
      continue
    }

    if (!params.apply_lines_changed) {
      params.apply_lines_changed = []
    }

    params.apply_lines_changed.push([id, ['quantity', 'total_price']])

    refreshes.financials = true
  }

  for (const [index, removedLineID] of revision.diff.lines_removed?.entries() ||
    []) {
    if (!selectedRevisionsKeys.includes(`removed__${index}`)) {
      continue
    }

    if (!params.apply_lines_removed) {
      params.apply_lines_removed = []
    }

    params.apply_lines_removed.push(removedLineID)

    refreshes.financials = true
  }

  return [params, refreshes]
}

export const errorsSelector = createSelector(modalFormSelector, () => {
  const errors = {}

  return errors
})

async function getRevision(orderNumber) {
  try {
    updateModalForm({loadingRevision: true})

    const {json: revision} = await apiverson.get(
      buildPath(['order', orderNumber, 'revision']),
    )

    updateModalForm({revision, loadingRevision: false})

    const {allRevisionKeys} = revisionSummarySelector(getState())

    updateModalForm({
      selectedRevisionsKeys: allRevisionKeys,
    })
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      loadingRevision: false,
    })
  }
}

export async function confirmOrderRevisions() {
  try {
    const {orderNumbers, revision, index} = modalFormSelector(getState())
    const orderNumber = orderNumbers[index]
    const [params, refreshes] = paramsSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const {json: order} = await apiverson.post(
      buildPath(['order', orderNumber, 'revision', revision.id, 'apply']),
      params,
    )

    await setOrderStateAndEnsureProductsLoaded(order)

    if (refreshes.financials) {
      const financialInfo = financialInfoSelector(getState(), {orderNumber})
      await saveFinancialInfo(orderNumber, financialInfo)
    }

    if (refreshes.rates) {
      await refreshShippingAndReturnRates(orderNumber)
    }

    if (orderNumbers.length - 1 > index) {
      showOrderRevisionModal(orderNumbers, index + 1)
    } else {
      closeModal()
    }

    showMessageToast('External revisions were applied to the order.')

    if (
      currentPageSelector(getState()) === ORDER_LIST_PAGE &&
      orderListQuerySelector(getState()).has_revision
    ) {
      await refreshOrderListAndCounts()
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function OrderLine({line, include, preferCartOrderLineNames}) {
  const changedQuantity =
    line.type === 'changed' && isNumeric(line.changes.quantity)
      ? line.changes.quantity
      : line.quantity

  const changedTotalPrice =
    line.type === 'changed' && isNumeric(line.changes.total_price)
      ? line.changes.total_price
      : line.total_price

  return (
    <tr
      className={`table__tr--line ${include ? '' : 'table__tr--line-rejected'}`}
    >
      <td className="table__td">
        <Checkbox
          checked={include}
          onClick={(checked, event) =>
            toggleSelectedRevisionKey(line.revisionKey, checked, event.shiftKey)
          }
        />
      </td>
      <td className="table__td">
        <div className="margin-bottom-3">
          <strong className="fs-n0">
            {preferCartOrderLineNames && line.order_line_product_name
              ? line.order_line_product_name
              : line.product_name}
          </strong>
        </div>
        <div className="fs-n0">{line.sku}</div>
      </td>
      <td className="table__td">
        {line.type === 'added' ? (
          <span className="fs-n0">Line added</span>
        ) : line.type === 'removed' ? (
          <span className="fs-n0">Line removed</span>
        ) : line.type === 'changed' ? (
          <span className="fs-n0">
            {line.changes.quantity !== undefined &&
            line.changes.total_price !== undefined
              ? 'Line'
              : line.changes.total_price !== undefined
                ? 'Price'
                : 'Qty'}{' '}
            changed
          </span>
        ) : null}
      </td>
      <td className="table__td align-right">
        {line.type === 'changed' || line.type === 'removed' ? (
          <span className="fs-n0">
            <Quantity value={line.quantity} />
          </span>
        ) : (
          <span className="fs-n0 op-50">—</span>
        )}
      </td>
      <td className="table__td align-right">
        {line.type === 'changed' || line.type === 'removed' ? (
          <span className="fs-n0">
            <Currency value={line.total_price} />
          </span>
        ) : (
          <span className="fs-n0 op-50">—</span>
        )}
      </td>
      <td className="table__td align-right border-left--light">
        {line.type === 'changed' ? (
          <strong className="fs-n0">
            <Quantity value={changedQuantity} />
          </strong>
        ) : line.type === 'added' ? (
          <strong className="fs-n0">
            <Quantity value={line.quantity} />
          </strong>
        ) : line.type === 'removed' ? (
          <span className="fs-n0 op-50">—</span>
        ) : null}
      </td>
      <td className="table__td align-right">
        {line.type === 'changed' ? (
          <strong className="fs-n0">
            <Currency value={changedTotalPrice} />
          </strong>
        ) : line.type === 'added' ? (
          <strong className="fs-n0">
            <Currency value={line.total_price} />
          </strong>
        ) : line.type === 'removed' ? (
          <span className="fs-n0 op-50">—</span>
        ) : null}
      </td>
      <td className="table__td align-right padding-right-0">
        {!include && (
          <strong className="label__callout label__callout--red fs-n2">
            Rejected
          </strong>
        )}
      </td>
    </tr>
  )
}

OrderLine.propTypes = {
  line: OrderLineShape,
  include: PropTypes.bool.isRequired,
  preferCartOrderLineNames: PropTypes.bool,
}

function OrderRevisionModal({form}) {
  const errors = useSelector(errorsSelector)
  const {hasRevision, lines, shipping_address, allLineRevisionKeys} =
    useSelector(revisionSummarySelector)
  const lineSelectedCount = useMemo(
    () =>
      form.selectedRevisionsKeys.filter(
        (key) => key !== SHIPPING_ADDRESS_REVISION,
      ).length,
    [form.selectedRevisionsKeys],
  )
  const allLinesSelected =
    allLineRevisionKeys.length > 0 &&
    allLineRevisionKeys.length === lineSelectedCount
  const indeterminateSelected = !allLinesSelected && lineSelectedCount > 0
  const {index} = form
  const orderNumber = form.orderNumbers[index]
  const isFirst = index === 0
  const isLast = index === form.orderNumbers.length - 1
  const isDropshipped = useSelector((state) =>
    isDropshippedSelector(state, {orderNumber}),
  )
  const preferCartOrderLineNames = useSelector(preferCartOrderLineNamesSelector)
  const includeShippingAddressRevision = form.selectedRevisionsKeys.includes(
    SHIPPING_ADDRESS_REVISION,
  )

  return (
    <ConfirmModal
      title={
        form.orderNumbers.length === 1
          ? 'Manage External Order Revision'
          : `Manage External Order Revisions (${index + 1}/${
              form.orderNumbers.length
            })`
      }
      modalSize="lg"
      onConfirm={() => confirmOrderRevisions()}
      onCancel={() => closeModal()}
      confirmText="Confirm Revisions"
      cancelText="Cancel"
      MiddleButtons={() => (
        <>
          {!isFirst && (
            <div className="margin-right-10">
              <ButtonPrimary
                isOutlined
                onClick={() =>
                  showOrderRevisionModal(form.orderNumbers, index - 1)
                }
                isDisabled={form.isSaving}
              >
                Previous
              </ButtonPrimary>
            </div>
          )}
          {!isLast && (
            <div className="margin-right-10">
              <ButtonPrimary
                isOutlined
                onClick={() =>
                  showOrderRevisionModal(form.orderNumbers, index + 1)
                }
                isDisabled={form.isSaving}
              >
                Next
              </ButtonPrimary>
            </div>
          )}
        </>
      )}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      {form.loadingRevision ? (
        <div className="loading align-center">
          <span className="list-processing animate-spin v-align-middle" />
          <strong className="inline-block v-align-middle fs-02 op-75 margin-left-5">
            Loading...
          </strong>
        </div>
      ) : (
        <>
          <div className="w-70">
            <div className="fs-01">
              <strong>Order ID:</strong> <span>{orderNumber}</span>
            </div>
            {hasRevision && (
              <>
                <p className="fs-01 lh-md margin-top-10 margin-bottom-10">
                  Select which external order revisions you’d like to merge into
                  Ordoro.{' '}
                </p>
                <p className="fs-00 lh-md margin-top-10 alert alert--neutral margin-bottom-0">
                  <strong>
                    Please note that revisions that are not applied will no
                    longer be available after clicking the “Confirm Revisions”
                    button
                  </strong>
                  .
                </p>
                {isDropshipped && (
                  <p className="fs-00 lh-md alert alert--warning margin-top-15 margin-bottom-0">
                    <strong>
                      For any revisions that ARE applied for this dropshipment,
                      you will need to reach out to the assigned supplier to let
                      them know of these changes or resubmit your dropshipment
                      request to them manually
                    </strong>
                    .
                  </p>
                )}
              </>
            )}
          </div>
          {!hasRevision ? (
            <p className="fs-00 lh-md margin-top-20">
              <em>This order has no outstanding external revisions.</em>
            </p>
          ) : (
            <>
              {shipping_address && (
                <div className="margin-top-20 w-70">
                  <div className="flex margin-bottom-5">
                    <p className="fs-00 lh-md margin-bottom-0 margin-right-10">
                      <strong>Shipping Address Revisions</strong>
                    </p>
                    {!includeShippingAddressRevision && (
                      <strong className="label__callout label__callout--red fs-n2">
                        Rejected
                      </strong>
                    )}
                  </div>
                  <div className="flex margin-top-15">
                    <div className="divider--right dark padding-right-20 margin-right-20">
                      <div className="margin-bottom-1">
                        <strong className="fs-n0 lh-md text--md-grey">
                          CURRENT:
                        </strong>
                      </div>
                      <Address address={shipping_address} />
                    </div>
                    <div>
                      <div className="margin-bottom-1">
                        <strong className="fs-n0 lh-md text--md-grey">
                          NEW:
                        </strong>
                      </div>
                      <Address
                        address={{
                          ...shipping_address,
                          ...shipping_address.changes,
                        }}
                      />
                    </div>
                  </div>
                  <div className="margin-top-20">
                    <Checkbox
                      id="shipping_address"
                      label="Accept these shipping address revisions"
                      checked={includeShippingAddressRevision}
                      onChange={(checked) => {
                        const allKeysSansThis =
                          form.selectedRevisionsKeys.filter(
                            (k) => k !== SHIPPING_ADDRESS_REVISION,
                          )

                        if (checked) {
                          allKeysSansThis.push(SHIPPING_ADDRESS_REVISION)
                        }

                        setSelectedRevisionsKeys(allKeysSansThis)
                      }}
                    />
                  </div>
                </div>
              )}
              {shipping_address && lines.length > 0 && (
                <hr className="w-70 margin-top-20" />
              )}
              {lines.length > 0 && (
                <>
                  <p className="fs-00 lh-md margin-top-30 margin-bottom-5">
                    <strong>Order Line Revisions</strong>
                  </p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="table__th table__th--sm w-1">
                          <Checkbox
                            className="margin-bottom-0"
                            indeterminate={indeterminateSelected}
                            checked={allLinesSelected}
                            onChange={(checked) => {
                              const selectedKeys =
                                includeShippingAddressRevision
                                  ? [SHIPPING_ADDRESS_REVISION]
                                  : []

                              setSelectedRevisionsKeys(
                                checked
                                  ? [
                                      ...selectedKeys,
                                      ...lines.map(
                                        ({revisionKey}) => revisionKey,
                                      ),
                                    ]
                                  : selectedKeys,
                              )
                            }}
                          />
                        </th>
                        <th className="table__th table__th--sm w-15">
                          <span className="fs-n0">Product/SKU</span>
                        </th>
                        <th className="table__th table__th--sm w-15">
                          <span className="fs-n0">Revision Type</span>
                        </th>
                        <th className="table__th table__th--sm align-right w-15">
                          <span className="fs-n0">Current Qty</span>
                        </th>
                        <th className="table__th table__th--sm align-right w-15">
                          <span className="fs-n0">Current Price</span>
                        </th>
                        <th className="table__th table__th--sm border-left--light align-right w-10">
                          <span className="fs-n0">New Qty</span>
                        </th>
                        <th className="table__th table__th--sm align-right w-15">
                          <span className="fs-n0">New Price</span>
                        </th>
                        <th className="table__th table__th--sm w-10" />
                      </tr>
                    </thead>
                    <tbody className="table__tbody--lines">
                      {lines.map((line, index) => (
                        <OrderLine
                          key={index}
                          line={line}
                          include={form.selectedRevisionsKeys.includes(
                            line.revisionKey,
                          )}
                          preferCartOrderLineNames={preferCartOrderLineNames}
                        />
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}
        </>
      )}
    </ConfirmModal>
  )
}

OrderRevisionModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    index: PropTypes.number.isRequired,
    selectedRevisionsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    loadingRevision: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(OrderRevisionModal, modalFormSelector)
