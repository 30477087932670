import {createSelector} from 'reselect'

import {setForm, formsSelector, updateForm} from '../store.js'
import apiverson from '../common/apiverson.js'
import {isNumeric} from '../common/utils.js'
import api from '../common/api.js'
import {ACH_PT, CREDIT_CARD_PT, SAME_DAY_ACH_PT} from './paymentAccounts.js'

export const ACCOUNT = 'ACCOUNT'

export function accountSelector(state) {
  return formsSelector(state)[ACCOUNT] || accountSelector.default
}
accountSelector.default = {}

export function accountHaveLoadedSelector(state) {
  return !!formsSelector(state)[ACCOUNT]
}

export const accountBalanceSelector = createSelector(
  accountSelector,
  ({balance}) => (isNumeric(balance) ? balance : null), // `null` means: we haven't got it yet
)

export const dailyPurchaseLimitSelector = createSelector(
  accountSelector,
  (account) => account.daily_purchase_limit || 0,
)

export const todaysPurchasesSelector = createSelector(
  accountSelector,
  (account) => account.todays_purchases || 0,
)

export function amountPostageThatCanBePurchasedSelector(state, {amount}) {
  const dailyPurchaseLimit = dailyPurchaseLimitSelector(state)
  const todaysPurchases = todaysPurchasesSelector(state)

  const amountUntilLimit = dailyPurchaseLimit - todaysPurchases

  if (amount <= 0 || amountUntilLimit <= 0) {
    return 0
  }

  return amount > amountUntilLimit ? amountUntilLimit : amount
}

export function amountExceedsLimitSelector(state, {amount}) {
  const dailyPurchaseLimit = dailyPurchaseLimitSelector(state)
  const todaysPurchases = todaysPurchasesSelector(state)

  return amount > 0 && amount + todaysPurchases > dailyPurchaseLimit
}

export function setAccount(account) {
  setForm(ACCOUNT, account)
}

export function setAccountBalance(balance) {
  updateForm(ACCOUNT, {balance})
}

export async function getAccount() {
  const {json} = await apiverson.get('/account/balance')

  setAccount(json)
}

export async function addToAccountBalance(
  amount,
  description,
  paymentType,
  paymentAccountID,
) {
  if (paymentAccountID) {
    if ([ACH_PT, SAME_DAY_ACH_PT].includes(paymentType)) {
      await initiateTransfer(paymentAccountID, amount, paymentType)
    } else if (paymentType === CREDIT_CARD_PT) {
      await initiateCharge(paymentAccountID, amount, description)
    }

    return
  }

  const params = {
    amount,
    description,
  }

  const {json} = await apiverson.post('/account/add_funds', params)

  setAccountBalance(json.balance)

  await getAccount()
}

export async function initiateTransfer(paymentAccountID, amount, paymentType) {
  await api.post(`/company/payment/${paymentAccountID}/transfer/`, {
    amount: Number(amount),
    ...(paymentType ? {payment_type: paymentType} : null),
  })
}

export async function initiateCharge(paymentAccountID, amount, description) {
  await api.post(`/company/payment/${paymentAccountID}/charge/`, {
    amount: Number(amount),
    description,
  })
}
