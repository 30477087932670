import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  setFormValue,
} from '../../../store.js'
import ConfirmModal from './ConfirmModal.js'
import deferPromise from '../../deferPromise.js'

const MODAL_FORM = 'CONFIRM_MESSAGE_MODAL'

export function showConfirmMessageModal({
  message,
  Message,
  confirmValue = true,
  cancelValue = false,
  hideConfirm = false,
  hideCancel = false,
  errorsSelector,
  ...props
}) {
  const deferredPromise = deferPromise()

  setForm(MODAL_FORM, {
    message,
    Message,
    confirmValue,
    cancelValue,
    hideConfirm,
    hideCancel,
    errorsSelector,
    ...props,
    deferredPromise,
  })

  return deferredPromise.promise
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function setModalFormValue(...args) {
  setFormValue(MODAL_FORM, ...args)
}

export function closeModal(outcome = null) {
  const form = modalFormSelector(getState())

  removeForm(MODAL_FORM)

  form.deferredPromise.resolve(outcome)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const {errorsSelector} = modalFormSelector(state)

  if (errorsSelector) {
    return errorsSelector(state, {formName: MODAL_FORM})
  } else {
    return {}
  }
}

function ConfirmMessageModal({form}) {
  const {message, Message, confirmValue, cancelValue, ...props} = form

  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title="Confirm"
      modalSize="sm"
      onConfirm={props.hideConfirm ? null : () => closeModal(confirmValue)}
      onCancel={props.hideCancel ? null : () => closeModal(cancelValue)}
      isDisabled={errors.preventSave}
      {...props}
    >
      {message}
      {Message && (
        <Message
          form={form}
          updateModalForm={updateModalForm}
          setModalFormValue={setModalFormValue}
        />
      )}
    </ConfirmModal>
  )
}

export default onlyIfForm(ConfirmMessageModal, modalFormSelector)
