import {formSelector} from '../../../store.js'

export function setupExportKitInventoryForm() {
  return {}
}

export function exportKitInventoryErrorsSelector() {
  return {}
}

export function exportKitInventoryPayloadSelector(state, {formName}) {
  const {reportType} = formSelector(state, {formName})

  const payload = {
    type: reportType,
    params: {},
  }

  return payload
}

export default function ExportKitInventoryForm() {
  return null
}
