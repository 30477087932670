import {
  getState,
  updateForm,
  formsSelector,
  useAutoForm,
} from '../../../../store.js'
import api from '../../../../common/api.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {setShipper} from '../../../../data/shippers.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import Join from '../../../../common/components/Join.js'

function handleFile(formName, file) {
  updateForm(formName, {serverError: null, isSaving: true})

  const reader = new FileReader()

  reader.addEventListener(
    'loadend',
    () => {
      updateForm(formName, {
        imageData: reader.result,
        serverError: reader.error ? reader.error.message : null,
        isSaving: false,
      })
    },
    false,
  )

  if (file) {
    reader.readAsDataURL(file)
  }
}

async function uploadShipperImage(formName, shipperID, imageType) {
  const {imageData} = formsSelector(getState())[formName]

  try {
    updateForm(formName, {serverError: null, isSaving: true})

    const {json} = await api.post(`/shipper/${shipperID}/image/`, {
      data: imageData,
      image_type: imageType,
    })

    setShipper(json)

    updateForm(formName, {isSaving: false})

    showMessageToast('Image successfully uploaded to FedEx')
  } catch (err) {
    updateForm(formName, {
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

export default function ShipperImage({
  shipperID,
  imageType,
  label,
  maxDimensions = [10, 10],
  acceptableFormats = ['.gif', '.png'],
  hasExistingImage,
}) {
  const formName = `SHIPPER_IMAGE_${shipperID}_${imageType}`

  const form = useAutoForm(formName, {
    imageData: null,
    isSaving: false,
    serverError: null,
  })

  return (
    <div className="divider--top margin-bottom-20">
      <fieldset className="margin-bottom-0">
        <ul className="form-list margin-bottom-0">
          <li className="form-list-item margin-bottom-10">
            <label htmlFor="id_logo_upload">{label}</label>
          </li>
          {hasExistingImage && (
            <li className="form-list-item margin-bottom-10">
              <em>Image has been added</em>
            </li>
          )}
          {form.imageData && !form.serverError && (
            <li className="form-list-item bigger row margin-bottom-10">
              <div className="medium-12 columns logo-wrap">
                <img alt="logo" src={form.imageData} />
              </div>
            </li>
          )}
          <li className="form-list-item row logo-upload-wrap">
            <div
              className={`medium-12 columns end ${
                form.serverError ? 'error' : ''
              }`}
            >
              <label
                className="file-upload-wrapper btn btn--primary btn--primary-ol btn--sm make-inline-block"
                htmlFor="id_logo_upload"
              >
                <span
                  className="icon icon-upload fs-01 v-align-middle margin-right-3"
                  aria-hidden="true"
                />
                <span>
                  Select {!form.imageData ? 'a ' : 'another '} file from your
                  computer
                </span>
                <input
                  type="file"
                  className={form.isSaving ? 'disabled submitting' : ''}
                  name="logo_upload"
                  id="id_logo_upload"
                  disabled={form.isSaving}
                  onChange={(event) =>
                    handleFile(formName, event.target.files[0])
                  }
                />
              </label>
              {form.serverError && (
                <small className="error error-message">
                  {form.serverError}
                </small>
              )}
              <ul className="helper panel margin-bottom-0">
                <li className="fs-n0 margin-bottom-5">
                  <strong>Accepted image formats:</strong>{' '}
                  <Join array={acceptableFormats} />.
                </li>
                <li className="fs-n0 margin-bottom-0">
                  <strong>Rectangular logos:</strong> Should fit within a{' '}
                  {maxDimensions[0]}px x {maxDimensions[1]}px region
                </li>
              </ul>
            </div>
          </li>
          <li className="list__item list__item--no-style">
            <ButtonPrimary
              className="btn--primary-alt btn--sm"
              onClick={() => uploadShipperImage(formName, shipperID, imageType)}
              disabled={!form.imageData}
              isLoading={form.isSaving}
            >
              Upload
            </ButtonPrimary>
          </li>
        </ul>
      </fieldset>
    </div>
  )
}
