import {isNumeric} from '../../common/utils.js'
import {
  setShowOrderTray,
  setShowPackingList,
  setPackingListSort,
  setShowPickList,
  setPickListSort,
  setTemplateID,
  setShowPrice,
  setShowBarcode,
  setShowLogoOnLabel,
  setLabelPosition,
  setShowFullPage,
  setBreakPageOnOrder,
  setShowPackingListImages,
  setShowPickListImages,
  setShowPickListTotalItems,
  setShowPickListTotalSKUs,
  setShowPickListTotalOrders,
  setHighlightPickLineQty,
  setPackingListKitViewMode,
  setShowSiblingOrderQuantities,
  setShowZeroQuantityLines,
  setHighlightPackLineQty,
  setShowPickListPOH,
  setShowShippingInfo,
  setShowWarehouseLocation,
  setShowBillTo,
  setShowPackSerialNumbers,
  setShowPickSerialNumbers,
  setShowCustomerNotes,
  setPickListKitViewMode,
  setUseCustomLayoutSettings,
  setPrintConfigID,
  setSize,
  setShowPackingListDetails,
  setShowPackSKUBarcode,
  setShowPickSKUBarcode,
  allowedParamsSelector,
  setLayoutID,
} from './abodeFormFunctions.js'
import {canUseLogosOnLabelsSelector} from '../../data/company.js'

import {sortedPackingListsByIDSelector} from '../../redux/selectors/data/packingLists.js'
import {printConfigsSortedByNameSelector} from '../../data/printConfigs.js'

import {
  KIT_VIEW_PARENTS,
  KIT_VIEW_BOTH,
  KIT_VIEW_COMPONENTS,
  STANDARD_LAYOUT,
  ABODE_FORM_DEFAULTS,
} from '../../common/constants/Abode.js'
import Checkbox from '../../common/components/Form/Checkbox.js'
import Radio from '../../common/components/Form/Radio.js'
import Select from '../../common/components/Form/Select.js'

import PageMargins from './Fields/PageMargins.js'
import LabelDimensions from './Fields/LabelDimensions.js'
import {onlyIfAutoForm, useSelector} from '../../store.js'
import {LAYOUT_LABEL, layoutsByTypeSortedByName} from '../../data/layout.js'

/**
 * Abode Form to let user select configuration of abode request
 *
 * @param {Object} props
 * @param {Object} props.form
 * @param {String} props.formName
 * @param {Array} [props.docTypes]
 * @param {Boolean} [props.includeSplitQuantitiesCheckbox]
 * @param {Boolean} [props.allowPrintConfigs]
 * @param {Boolean} [props.fromModal]
 * @returns {ReactNode}
 */
function AbodeForm({
  form,
  formName,
  docTypes,
  includeSplitQuantitiesCheckbox,
  allowPrintConfigs,
  fromModal,
}) {
  docTypes = docTypes || ['label', 'pick', 'pack']

  const allowedParams = useSelector((state) =>
    allowedParamsSelector(state, {
      formName,
      docTypes,
      includeSplitQuantitiesCheckbox,
    }),
  )
  const canUseLogosOnLabels = useSelector(canUseLogosOnLabelsSelector)
  const packingLists = useSelector(sortedPackingListsByIDSelector)
  const printConfigs = useSelector((state) =>
    allowPrintConfigs ? printConfigsSortedByNameSelector(state) : [],
  )
  const layouts = useSelector(layoutsByTypeSortedByName)[LAYOUT_LABEL] || []

  return (
    <dl className="list margin-bottom-15">
      <dt className="list__title--print-options">Document Options</dt>
      {printConfigs.length > 0 && (
        <Select
          label="Layout"
          name={`${formName}__layout`}
          value={form.printConfigID}
          onChange={(printConfigID) =>
            setPrintConfigID(
              formName,
              isNumeric(printConfigID) ? Number(printConfigID) : printConfigID,
            )
          }
          className="margin-bottom-20"
        >
          <option value={STANDARD_LAYOUT}>Standard</option>
          {printConfigs.map((printConfig) => (
            <option key={printConfig.id} value={printConfig.id}>
              {printConfig.name}
            </option>
          ))}
        </Select>
      )}
      {allowedParams.layout_id && layouts.length > 0 && (
        <Select
          label="Render Layout"
          name={`${formName}__layout`}
          value={form.layout_id}
          onChange={(layoutID) =>
            setLayoutID(formName, isNumeric(layoutID) ? Number(layoutID) : null)
          }
          className="margin-bottom-20"
        >
          <option value="">Standard</option>
          {layouts.map((layout) => (
            <option key={layout.id} value={layout.id}>
              {layout.name}
            </option>
          ))}
        </Select>
      )}
      {allowedParams.showLogoOnLabel && (
        <div className="margin-bottom-15">
          <Checkbox
            label="Show Logo on Label"
            name={`${formName}__show_logo_on_label`}
            checked={form.showLogoOnLabel}
            onChange={(checked) => setShowLogoOnLabel(formName, checked)}
          />
        </div>
      )}
      {!canUseLogosOnLabels && !allowedParams.showLogoOnLabel && (
        <div className="flex margin-bottom-15">
          <Checkbox
            label="Show Logo on Label"
            name={`${formName}__show_logo_on_label`}
            checked={false}
            onChange={() => null}
            disabled
          />
          <a
            className="btn btn--link no-underline tooltip--top"
            data-hint="Available in Express and above"
            href="https://support.ordoro.com/how-do-i-upload-my-logo-used-on-the-shipping-label-feature/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className="icon icon-support icon-block fs-n1 margin-left-5"
              aria-hidden="true"
            />
          </a>
        </div>
      )}

      {allowedParams.showOrderTray && (
        <div className="margin-bottom-15">
          <Checkbox
            label="Display Order Tray Numbers"
            name={`${formName}__show_order_tray`}
            checked={form.showOrderTray}
            onChange={(checked) => setShowOrderTray(formName, checked)}
          />
        </div>
      )}

      {allowedParams.showPackingList && (
        <Checkbox
          label="Attach Packing List"
          name={`${formName}__doc_packing`}
          checked={form.showPackingList}
          onChange={(checked) => setShowPackingList(formName, checked)}
        >
          {allowedParams.packingListSort && (
            <Select
              label="Sort By"
              name={`${formName}__packing_list_sort_by`}
              value={form.packingListSort}
              onChange={(value) => setPackingListSort(formName, value)}
              className="margin-bottom-10"
            >
              <option value="">Original order lines</option>
              <option value="sku">SKU</option>
              <option value="description">Description</option>
              <option value="location">Location (A→Z)</option>
              <option value="location-reverse">Location (Z→A)</option>
              <option value="quantity-reverse">Quantity (High to Low)</option>
              <option value="quantity">Quantity (Low to High)</option>
            </Select>
          )}
          {allowedParams.showPrice && (
            <Checkbox
              label="Show Price"
              name={`${formName}__show_price`}
              checked={form.showPrice}
              onChange={(checked) => setShowPrice(formName, checked)}
            />
          )}
          {allowedParams.showBarcode && (
            <Checkbox
              label="Show Barcode"
              name={`${formName}__show_barcode`}
              checked={form.showBarcode}
              onChange={(checked) => setShowBarcode(formName, checked)}
            />
          )}
          {allowedParams.showPackingListImages && (
            <Checkbox
              label="Show Images"
              name={`${formName}__show_packing_images`}
              checked={form.showPackingListImages}
              onChange={(checked) =>
                setShowPackingListImages(formName, checked)
              }
            />
          )}
          {allowedParams.packingListKitViewMode && (
            <Checkbox
              label="Show Kit Components"
              name={`${formName}__show_packing_kit_components`}
              checked={form.packingListKitViewMode !== KIT_VIEW_PARENTS}
              onChange={(checked) => {
                setPackingListKitViewMode(
                  formName,
                  checked ? KIT_VIEW_BOTH : KIT_VIEW_PARENTS,
                )
              }}
            >
              <Select
                name={`${formName}__packing_list_kit_view_mode`}
                value={form.packingListKitViewMode}
                onChange={(value) => setPackingListKitViewMode(formName, value)}
                className="margin-bottom-10"
              >
                <option value={KIT_VIEW_BOTH}>Parents & Components</option>
                <option value={KIT_VIEW_COMPONENTS}>Components Only</option>
              </Select>
            </Checkbox>
          )}
          {allowedParams.showSiblingOrderQuantities && (
            <Checkbox
              label="Show Quantities from Split Orders"
              name={`${formName}__show_sibling_order_quantities`}
              checked={form.showSiblingOrderQuantities}
              onChange={(checked) =>
                setShowSiblingOrderQuantities(formName, checked)
              }
            />
          )}
          {allowedParams.showZeroQuantityLines && (
            <Checkbox
              label="Show Lines with Zero Quantity"
              name={`${formName}__show_zero_quantity_lines`}
              checked={form.showZeroQuantityLines}
              onChange={(checked) =>
                setShowZeroQuantityLines(formName, checked)
              }
            />
          )}
          {allowedParams.highlight_pack_line_qty && (
            <Checkbox
              label="Highlight Line Item Quantities Greater Than One"
              name={`${formName}__highlight_pack_line_qty`}
              checked={form.highlight_pack_line_qty}
              onChange={(checked) => setHighlightPackLineQty(formName, checked)}
            />
          )}
          {allowedParams.showShippingInfo && (
            <Checkbox
              label="Show Shipping Info"
              name={`${formName}__show_shipping_info`}
              checked={form.showShippingInfo}
              onChange={(checked) => setShowShippingInfo(formName, checked)}
            />
          )}
          {allowedParams.showWarehouseLocation && (
            <Checkbox
              label="Show Warehouse Location"
              name={`${formName}__show_warehouse_location`}
              checked={form.showWarehouseLocation}
              onChange={(checked) =>
                setShowWarehouseLocation(formName, checked)
              }
            />
          )}
          {allowedParams.showBillTo && (
            <Checkbox
              label="Show Bill-To"
              name={`${formName}__show_bill_to`}
              checked={form.showBillTo}
              onChange={(checked) => setShowBillTo(formName, checked)}
            />
          )}
          {allowedParams.showPackSerialNumbers && (
            <Checkbox
              label="Show Serial Numbers"
              name={`${formName}__show_pack_serial_numbers`}
              checked={form.showPackSerialNumbers}
              onChange={(checked) =>
                setShowPackSerialNumbers(formName, checked)
              }
            />
          )}
          {allowedParams.showCustomerNotes && (
            <Checkbox
              label="Show Customer Notes"
              name={`${formName}__show_customer_notes`}
              checked={form.showCustomerNotes}
              onChange={(checked) => setShowCustomerNotes(formName, checked)}
            />
          )}
          {allowedParams.show_packing_list_details && (
            <Checkbox
              label="Show Order Line Additional Details"
              name={`${formName}__show_packing_list_details`}
              checked={form.show_packing_list_details}
              onChange={(checked) =>
                setShowPackingListDetails(formName, checked)
              }
            />
          )}
          {allowedParams.show_pack_sku_barcode && (
            <Checkbox
              label="Show Barcode of SKU"
              name={`${formName}__show_pack_sku_barcode`}
              checked={form.show_pack_sku_barcode}
              onChange={(checked) => setShowPackSKUBarcode(formName, checked)}
            />
          )}
        </Checkbox>
      )}
      {allowedParams.showPickList && (
        <Checkbox
          label="Attach Pick List"
          name={`${formName}__doc_pick`}
          checked={form.showPickList}
          onChange={() => setShowPickList(formName, !form.showPickList)}
        >
          {allowedParams.pickListSort && (
            <Select
              label="Sort By"
              name={`${formName}__pick_list_sort_by`}
              value={form.pickListSort}
              onChange={(value) => setPickListSort(formName, value)}
              className="margin-bottom-10"
            >
              <option value="sku">SKU</option>
              <option value="description">Description</option>
              <option value="location">Location</option>
              <option value="quantity-reverse">Quantity (High to Low)</option>
              <option value="quantity">Quantity (Low to High)</option>
            </Select>
          )}
          {allowedParams.showPickListImages && (
            <Checkbox
              label="Show Images"
              name={`${formName}__show_pick_images`}
              checked={form.showPickListImages}
              onChange={(checked) => setShowPickListImages(formName, checked)}
            />
          )}
          {allowedParams.showPickListTotalItems && (
            <Checkbox
              label="Show Total Items Count"
              name={`${formName}__show_pick_list_total_items`}
              checked={form.showPickListTotalItems}
              onChange={(checked) =>
                setShowPickListTotalItems(formName, checked)
              }
            />
          )}
          {allowedParams.highlight_pick_line_qty && (
            <Checkbox
              label="Highlight Line Item Quantities Greater Than One"
              name={`${formName}__highlight_pick_line_qty`}
              checked={form.highlight_pick_line_qty}
              onChange={(checked) => setHighlightPickLineQty(formName, checked)}
            />
          )}
          {allowedParams.showPickListTotalItems && (
            <Checkbox
              label="Show Total SKU Count"
              name={`${formName}__show_pick_list_total_skus`}
              checked={form.showPickListTotalSKUs}
              onChange={(checked) =>
                setShowPickListTotalSKUs(formName, checked)
              }
            />
          )}
          {allowedParams.showPickListTotalItems && (
            <Checkbox
              label="Show Total Order Count"
              name={`${formName}__show_pick_list_total_orders`}
              checked={form.showPickListTotalOrders}
              onChange={(checked) =>
                setShowPickListTotalOrders(formName, checked)
              }
            />
          )}
          {allowedParams.showPickSerialNumbers && (
            <Checkbox
              label="Show Serial Numbers"
              name={`${formName}__show_pick_serial_numbers`}
              checked={form.showPickSerialNumbers}
              onChange={(checked) =>
                setShowPickSerialNumbers(formName, checked)
              }
            />
          )}
          {allowedParams.show_pick_list_poh && (
            <Checkbox
              label="Show Physical Units On Hand (POH)"
              name={`${formName}__show_pick_list_poh`}
              checked={form.show_pick_list_poh}
              onChange={(checked) => setShowPickListPOH(formName, checked)}
            />
          )}
          {allowedParams.show_pick_sku_barcode && (
            <Checkbox
              label="Show Barcode of SKU"
              name={`${formName}__show_pick_sku_barcode`}
              checked={form.show_pick_sku_barcode}
              onChange={(checked) => setShowPickSKUBarcode(formName, checked)}
            />
          )}
          {allowedParams.pickListKitViewMode && (
            <Checkbox
              label="Show Kit Components"
              name={`${formName}__show_pick_kit_components`}
              checked={form.pickListKitViewMode !== KIT_VIEW_PARENTS}
              onChange={(checked) => {
                setPickListKitViewMode(
                  formName,
                  checked ? KIT_VIEW_BOTH : KIT_VIEW_PARENTS,
                )
              }}
            >
              <Select
                name={`${formName}__pick_list_kit_view_mode`}
                value={form.pickListKitViewMode}
                onChange={(value) => setPickListKitViewMode(formName, value)}
                className="margin-bottom-10"
              >
                <option value={KIT_VIEW_BOTH}>Parents & Components</option>
                <option value={KIT_VIEW_COMPONENTS}>Components Only</option>
              </Select>
            </Checkbox>
          )}
        </Checkbox>
      )}
      {allowedParams.templateID &&
        packingLists.length > 1 &&
        (form.showPackingList || form.showLogoOnLabel) && (
          <Select
            label="Profile"
            name={`${formName}__template`}
            value={form.templateID}
            onChange={(newTemplateID) =>
              setTemplateID(formName, Number(newTemplateID))
            }
            className="margin-bottom-10"
          >
            <option value="0">Use preconfigured profile</option>
            <option disabled>---------------------------</option>
            {packingLists.map((packingList) => (
              <option value={packingList.id} key={packingList.id}>
                {!packingList.website
                  ? packingList.name
                  : `${packingList.name} - ${packingList.website}`}
              </option>
            ))}
          </Select>
        )}
      {allowedParams.size && (
        <>
          <dt className="list__title--print-options">Printer Options</dt>
          <Radio
            label={'Thermal Printer'}
            value="thermal"
            name={`${formName}_${fromModal ? '_modal_' : ''}_thermal_size`}
            checked={['thermal', 'thermal_8_5', 'thermal_10_5'].includes(
              form.size,
            )}
            onChange={() => setSize(formName, 'thermal')}
          >
            <Select
              label="Size"
              name={`${formName}_${
                fromModal ? '_modal_' : ''
              }_thermal_size_select`}
              value={form.size}
              onChange={(value) => setSize(formName, value)}
              className="margin-bottom-10"
            >
              <option value="thermal">4&quot; x 6&quot;</option>
              <option value="thermal_8_5">4&quot; x 8.5&quot;</option>
              <option value="thermal_10_5">4&quot; x 10.5&quot;</option>
            </Select>
          </Radio>
          <Radio
            label={'Desktop Printer (8.5" x 11")'}
            value="desktop"
            name={`${formName}_${fromModal ? '_modal_' : ''}_desktop_size`}
            checked={form.size === 'desktop'}
            onChange={(value) => setSize(formName, value)}
          >
            {allowedParams.showFullPage && (
              <>
                <Radio
                  name={`${formName}_${
                    fromModal ? '_modal_' : ''
                  }_show_one_per_page`}
                  label="Full page"
                  onChange={() => setShowFullPage(formName, true)}
                  checked={form.showFullPage}
                />
                <Radio
                  name={`${formName}_${
                    fromModal ? '_modal_' : ''
                  }_show_two_per_page`}
                  label="Side-by-side"
                  onChange={() => setShowFullPage(formName, false)}
                  checked={!form.showFullPage}
                >
                  {allowedParams.labelPosition && (
                    <Select
                      label="Placement of Label on Page"
                      name={`${formName}__label_position`}
                      value={form.labelPosition}
                      onChange={(value) => setLabelPosition(formName, value)}
                      className="margin-bottom-10"
                    >
                      <option value="">Either side (flows with content)</option>
                      <option value="left">Left side always</option>
                      <option value="right">Right side always</option>
                    </Select>
                  )}
                  {allowedParams.breakPageOnOrder && (
                    <Checkbox
                      label="Break Page on Order"
                      name={`${formName}__break_page_on_order`}
                      checked={form.breakPageOnOrder}
                      onChange={(checked) =>
                        setBreakPageOnOrder(formName, checked)
                      }
                    />
                  )}
                </Radio>
              </>
            )}
          </Radio>
        </>
      )}
      {allowedParams.useCustomLayoutSettings && (
        <Checkbox
          label="Custom Settings"
          name={`${formName}__custom_settings`}
          checked={form.useCustomLayoutSettings}
          onChange={(checked) => setUseCustomLayoutSettings(formName, checked)}
        >
          <ul className="list">
            <li className="list__item--shipping-options flex flex-wrap flex-wrap-margin-10">
              <PageMargins formName={formName} />
              <LabelDimensions formName={formName} />
            </li>
          </ul>
        </Checkbox>
      )}
    </dl>
  )
}

export default onlyIfAutoForm(AbodeForm, ({formName}) => {
  formName = formName || ''

  return {
    formName,
    initialForm: {...ABODE_FORM_DEFAULTS},
  }
})
