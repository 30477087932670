import {AMAZON} from './CartVendorOptions.js'
import {EXPAND_MODE_EXPANDED} from '../components/List/ExpandAllButton.js'
import {getNounsFromValue} from '../tokenizeOptions.js'

export const DROPSHIP_RESUPPLY_METHOD = 'dropship'
export const IN_HOUSE_RESUPPLY_METHOD = 'inhouse'
export const IN_HOUSE_FULFILLMENT = 'in_house'
export const DROPSHIPPABLE_FULFILLMENT = 'dropshippable'
export const AUTOMATICALLY_DROPSHIPPED_FULFILLMENT = 'automatically_dropshipped'
export const FULFILLMENT_OPTIONS = [
  {
    value: IN_HOUSE_FULFILLMENT,
    display: 'In House',
  },
  {
    value: DROPSHIPPABLE_FULFILLMENT,
    display: 'Dropshippable',
  },
  {
    value: AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
    display: 'Automatically Dropshipped',
  },
]
export const FULFILLMENT_NO_AUTO_OPTIONS = [
  {
    value: IN_HOUSE_FULFILLMENT,
    display: 'In House',
  },
  {
    value: DROPSHIPPABLE_FULFILLMENT,
    display: 'Dropshippable',
  },
]

export const NAME_SORT = 'name'
export const SKU_SORT = 'sku'
export const CREATED_SORT = 'created'
export const DEFAULT_SUPPLIER_SORT = 'default_supplier'
export const TOTAL_COMMITTED_SORT = 'total_committed'
export const TOTAL_AVAILABLE_SORT = 'total_available'
export const TOTAL_ON_HAND_SORT = 'total_on_hand'
export const PO_TOTAL_COMMITTED_SORT = 'po_total_committed'

export const DEFAULT_EXPAND_MODE = EXPAND_MODE_EXPANDED
export const DEFAULT_SORT = NAME_SORT
export const DEFAULT_PER_PAGE = 10

export const PRODUCT_SORT_OPTIONS_BASIC = [
  {
    display: 'Product (A→Z)',
    value: NAME_SORT,
  },
  {
    display: 'Product (Z→A)',
    value: `-${NAME_SORT}`,
  },
  {
    display: 'SKU (A→Z)',
    value: SKU_SORT,
  },
  {
    display: 'SKU (Z→A)',
    value: `-${SKU_SORT}`,
  },
  {
    display: 'Date Created (Newest)',
    value: `-${CREATED_SORT}`,
  },
  {
    display: 'Date Created (Oldest)',
    value: CREATED_SORT,
  },
]

export const PRODUCT_SORT_OPTIONS = [
  ...PRODUCT_SORT_OPTIONS_BASIC,
  {
    display: 'Supplier (A→Z)',
    value: DEFAULT_SUPPLIER_SORT,
  },
  {
    display: 'Supplier (Z→A)',
    value: `-${DEFAULT_SUPPLIER_SORT}`,
  },
  {
    display: 'Committed Quantity (Highest)',
    value: `-${TOTAL_COMMITTED_SORT}`,
  },
  {
    display: 'Committed Quantity (Lowest)',
    value: TOTAL_COMMITTED_SORT,
  },

  {
    display: 'Available Quantity (Highest)',
    value: `-${TOTAL_AVAILABLE_SORT}`,
  },
  {
    display: 'Available Quantity (Lowest)',
    value: TOTAL_AVAILABLE_SORT,
  },

  {
    display: 'On Hand Quantity (Highest)',
    value: `-${TOTAL_ON_HAND_SORT}`,
  },
  {
    display: 'On Hand Quantity (Lowest)',
    value: TOTAL_ON_HAND_SORT,
  },

  {
    display: 'Reordered Quantity (Highest)',
    value: `-${PO_TOTAL_COMMITTED_SORT}`,
  },
  {
    display: 'Reordered Quantity (Lowest)',
    value: PO_TOTAL_COMMITTED_SORT,
  },
]

export const EQUAL_COMPARATOR = 'equal'
export const LESS_THAN_COMPARATOR = 'less_than'
export const GREATER_THAN_COMPARATOR = 'greater_than'

export const COMPARATORS = [
  {
    display: 'Equal to',
    value: EQUAL_COMPARATOR,
    symbol: '=',
  },
  {
    display: 'Greater than',
    value: GREATER_THAN_COMPARATOR,
    symbol: '>',
  },
  {
    display: 'Less than',
    value: LESS_THAN_COMPARATOR,
    symbol: '<',
  },
]

export const DEFAULT_QUANTITY_COMPARATOR = EQUAL_COMPARATOR

export const IS_ACTIVE_FILTER = 'isActive'
export const IS_LOW_INVENTORY_FILTER = 'isLowInventory'
export const IS_KIT_FILTER = 'isKit'
export const IS_KIT_COMPONENT_FILTER = 'isKitComponent'
export const IS_BOM_FILTER = 'isBOM'
export const IS_BOM_COMPONENT_FILTER = 'isBOMComponent'
export const IS_DROPSHIP_FILTER = 'isDropship'
export const NEEDS_SYNC_FILTER = 'needsSync'
export const IS_TO_BE_SHIPPED_FILTER = 'isToBeShipped'
export const IS_OVERSOLD_FILTER = 'isOversold'
export const IS_REORDERED_FILTER = 'isReordered'
export const INVENTORY_SYNC_SUSPENDED = 'inventory_sync_suspended'
export const API_PRODUCT_ACTIVE = 'active'
export const API_PRODUCT_KIT = 'kit'
export const API_PRODUCT_KIT_COMPONENT = 'kit_component'
export const API_PRODUCT_MANUFACTURED = 'manufactured'
export const API_PRODUCT_MANUFACTURING_COMPONENT = 'manufacturing_component'
export const API_PRODUCT_DROPSHIP = 'dropship'
export const API_PRODUCT_NEEDS_SYNC = 'needs_sync'
export const API_PRODUCT_TO_BE_SHIPPED = 'to_be_shipped'
export const API_PRODUCT_OVERSOLD = 'oversold'
export const API_PRODUCT_REORDERED = 'reordered'
export const API_PRODUCT_INVENTORY_SYNC_SUSPENDED = 'inventory_sync_suspended'
export const API_PRODUCT_LOW_INVENTORY_AOH = 'low_inventory_aoh'
export const API_PRODUCT_LOW_INVENTORY_POH = 'low_inventory_poh'
export const PRODUCT_STATUS = 'PRODUCT_STATUS'
export const IS_LOW_INVENTORY_AOH_FILTER = API_PRODUCT_LOW_INVENTORY_AOH
export const IS_LOW_INVENTORY_POH_FILTER = API_PRODUCT_LOW_INVENTORY_POH

export const PRODUCT_BOOLEAN_FILTER_OPTIONS = [
  {
    display: 'Active',
    reverseDisplay: 'Not Active',
    value: API_PRODUCT_ACTIVE,
    urlValue: IS_ACTIVE_FILTER,
  },
  {
    display: 'Low AOH Inventory',
    reverseDisplay: 'Not Low AOH Inventory',
    value: API_PRODUCT_LOW_INVENTORY_AOH,
    urlValue: IS_LOW_INVENTORY_AOH_FILTER,
  },
  {
    display: 'Low POH Inventory',
    reverseDisplay: 'Not Low POH Inventory',
    value: API_PRODUCT_LOW_INVENTORY_POH,
    urlValue: IS_LOW_INVENTORY_POH_FILTER,
  },
  {
    display: 'Kits',
    reverseDisplay: 'Not Kits',
    value: API_PRODUCT_KIT,
    urlValue: IS_KIT_FILTER,
  },
  {
    display: 'Kit Components',
    reverseDisplay: 'Not Kit Components',
    value: API_PRODUCT_KIT_COMPONENT,
    urlValue: IS_KIT_COMPONENT_FILTER,
  },
  {
    display: 'BOMs',
    reverseDisplay: 'Not BOMs',
    value: API_PRODUCT_MANUFACTURED,
    urlValue: IS_BOM_FILTER,
  },
  {
    display: 'BOM Components',
    reverseDisplay: 'Not BOM Components',
    value: API_PRODUCT_MANUFACTURING_COMPONENT,
    urlValue: IS_BOM_COMPONENT_FILTER,
  },
  {
    display: 'Dropship',
    reverseDisplay: 'Not Dropship',
    value: API_PRODUCT_DROPSHIP,
    urlValue: IS_DROPSHIP_FILTER,
  },
  {
    display: 'Need Sync',
    reverseDisplay: 'Not Need Sync',
    value: API_PRODUCT_NEEDS_SYNC,
    urlValue: NEEDS_SYNC_FILTER,
  },
  {
    display: 'On Open Orders',
    reverseDisplay: 'Not On Open Orders',
    value: API_PRODUCT_TO_BE_SHIPPED,
    urlValue: IS_TO_BE_SHIPPED_FILTER,
  },
  {
    display: 'Oversold',
    reverseDisplay: 'Not Oversold',
    value: API_PRODUCT_OVERSOLD,
    urlValue: IS_OVERSOLD_FILTER,
  },
  {
    display: 'Reordered',
    reverseDisplay: 'Not Reordered',
    value: API_PRODUCT_REORDERED,
    urlValue: IS_REORDERED_FILTER,
  },
  {
    display: 'Inventory Sync Suspended',
    reverseDisplay: 'Inventory Sync Not Suspended',
    value: API_PRODUCT_INVENTORY_SYNC_SUSPENDED,
    urlValue: INVENTORY_SYNC_SUSPENDED,
  },
].map((option) => ({
  ...option,
  entity: API_PRODUCT_ACTIVE,
  type: PRODUCT_STATUS,
  nouns: [...getNounsFromValue(`${option.display} ${option.reverseDisplay}`)],
}))

export const [
  API_TO_URL_BOOLEAN_FILTERS,
  URL_TO_API_BOOLEAN_FILTERS,
  PRODUCT_BOOLEAN_FILTERS,
  API_PRODUCT_BOOLEAN_FILTERS,
] = PRODUCT_BOOLEAN_FILTER_OPTIONS.reduce(
  (prev, {value, urlValue}) => {
    prev[0][value] = urlValue
    prev[1][urlValue] = value
    prev[2].push(urlValue)
    prev[3].push(value)
    return prev
  },
  [{}, {}, [], []],
)

export const IS_PRODUCT_FBA = 'fba'
export const IS_PRODUCT_FBM = 'fbm'

export const PRODUCT_FULFILLMENT_CHANNEL_OPTIONS = [
  {
    display: 'FBA',
    value: IS_PRODUCT_FBA,
    requiresCartVendor: [AMAZON],
  },
  {
    display: 'FBM',
    value: IS_PRODUCT_FBM,
    requiresCartVendor: [AMAZON],
  },
]

export const [
  PRODUCT_FULFILLMENT_CHANNELS,
  PRODUCT_FULFILLMENT_CHANNELS_BY_VALUE,
] = PRODUCT_FULFILLMENT_CHANNEL_OPTIONS.reduce(
  (prev, option) => {
    prev[0].push(option.value)
    prev[1][option.value] = option

    return prev
  },
  [[], {}],
)

export const PRODUCT_DETAIL_WAREHOUSES_PANEL = ''
export const PRODUCT_DETAIL_SUPPLIERS_PANEL = 'suppliers'
export const PRODUCT_DETAIL_SALES_CHANNELS_PANEL = 'sales_channels'
export const PRODUCT_DETAIL_KITTING_PANEL = 'kitting'
export const PRODUCT_DETAIL_BOM_PANEL = 'bom'
export const PRODUCT_DETAIL_ANALYTICS_PANEL = 'analytics'
export const PRODUCT_DETAIL_PANELS = [
  PRODUCT_DETAIL_WAREHOUSES_PANEL,
  PRODUCT_DETAIL_SUPPLIERS_PANEL,
  PRODUCT_DETAIL_SALES_CHANNELS_PANEL,
  PRODUCT_DETAIL_KITTING_PANEL,
  PRODUCT_DETAIL_BOM_PANEL,
  PRODUCT_DETAIL_ANALYTICS_PANEL,
]

export const PRODUCT_PLURAL_URI_COMPONENT = 'products'
export const PRODUCT_SINGLE_URI_COMPONENT = 'product'

export const BOM_LINK_TYPE = 'bom'
export const PRODUCT_LINK_TYPE = 'product'
