import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import get from 'lodash/get.js'

import {
  getState,
  dispatch,
  setForm,
  updateForm,
  formsSelector,
} from '../store.js'
import apiverson from '../common/apiverson.js'
import {fetchAllApiverson} from '../common/fetchAll.js'
import {TAG_TYPE__RETURN_ORDER} from '../common/constants/Tags.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {patchReturnOrderTag} from '../redux/actions/data/returnOrders.js'
import {returnOrdersSelector} from '../redux/selectors/data/returnOrders.js'
import {refreshReturnOrderList} from '../ordoro/ReturnOrderListPage/returnOrderListActions.js'
import {hasUseReturnOrdersFeatureSelector} from '../data/company.js'
import {tokenizeOptions, getNounsFromValue} from '../common/tokenizeOptions.js'

export const RETURN_ORDER_TAGS = 'RETURN_ORDER_TAGS'

export function setReturnOrderTags(returnOrderTags) {
  setForm(RETURN_ORDER_TAGS, keyBy(returnOrderTags, 'id'))
}

export function updateReturnOrderTag(returnOrderTag) {
  updateForm(RETURN_ORDER_TAGS, {[returnOrderTag.id]: returnOrderTag})
}

export function removeReturnOrderTag(returnOrderTag) {
  const returnOrderTags = {...returnOrderTagsSelector(getState())}

  delete returnOrderTags[returnOrderTag.id]

  setForm(RETURN_ORDER_TAGS, returnOrderTags)
}

export function returnOrderTagsSelector(state) {
  return (
    formsSelector(state)[RETURN_ORDER_TAGS] || returnOrderTagsSelector.default
  )
}
returnOrderTagsSelector.default = {}

export function returnOrderTagSelector(state, {returnOrderTagID}) {
  return returnOrderTagsSelector(state)[returnOrderTagID]
}

export const returnOrderTagsSortedByNameSelector = createSelector(
  returnOrderTagsSelector,
  (returnOrderTags) => sortBy(returnOrderTags, ({name}) => name.toLowerCase()),
)

export const returnOrderTagOptionsSelector = createSelector(
  returnOrderTagsSortedByNameSelector,
  (tags) =>
    tags.map((tag) => ({
      value: tag.id,
      display: tag.name,
      entity: tag,
      type: TAG_TYPE__RETURN_ORDER,
      nouns: getNounsFromValue(tag.name),
    })),
)

export const returnOrderTagOptionTokensSelector = createSelector(
  returnOrderTagOptionsSelector,
  (tagOptions) => tokenizeOptions(tagOptions),
)

export function returnOrderTagsHaveLoadedSelector(state) {
  return !!formsSelector(state)[RETURN_ORDER_TAGS]
}

export async function getReturnOrderTags() {
  try {
    if (!hasUseReturnOrdersFeatureSelector(getState())) {
      return
    }

    const returnOrderTags = await fetchAllApiverson('/return_order/tag/', 'tag')

    setReturnOrderTags(returnOrderTags)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting RMA tags.',
        details: err.error_message || err.message,
      },
      err,
    )

    setReturnOrderTags([])
  }
}

export async function saveReturnOrderTag(name, color, id) {
  const params = {name: name.trim(), color}
  const originalTag = id
    ? returnOrderTagSelector(getState(), {returnOrderTagID: id})
    : null

  const {json} = id
    ? await apiverson.put(
        `/return_order/tag/${encodeURIComponent(originalTag.name)}`,
        params,
      )
    : await apiverson.post('/return_order/tag', params)

  updateReturnOrderTag(json)

  if (id) {
    dispatch(refreshReturnOrderList())
  }

  return json
}

export async function deleteReturnOrderTag(tagID) {
  const tag = returnOrderTagSelector(getState(), {returnOrderTagID: tagID})

  await apiverson.delete(`/return_order/tag/${encodeURIComponent(tag.name)}`)

  removeReturnOrderTag(tag)
}

export async function addTagToReturnOrders(returnOrderTagID, referenceIDs) {
  const rmas = returnOrdersSelector(getState())
  const tag = returnOrderTagSelector(getState(), {returnOrderTagID})

  const referenceIDsWithoutTag = referenceIDs.filter(
    (referenceID) =>
      !get(rmas, [referenceID, 'tags'], []).find(
        (t) => t.id === returnOrderTagID,
      ),
  )

  referenceIDsWithoutTag.map((referenceID) =>
    patchReturnOrderTag(referenceID, tag, true),
  )

  try {
    await Promise.all(
      referenceIDsWithoutTag.map((referenceID) =>
        apiverson.post(
          `/return_order/${encodeURIComponent(
            referenceID,
          )}/tag/${encodeURIComponent(tag.name)}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error adding RMA tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    referenceIDsWithoutTag.map((referenceID) =>
      patchReturnOrderTag(referenceID, tag, false),
    )
  }
}

export async function removeTagFromReturnOrders(
  returnOrderTagID,
  referenceIDs,
) {
  const rmas = returnOrdersSelector(getState())
  const tag = returnOrderTagSelector(getState(), {returnOrderTagID})

  const referenceIDsWithTag = referenceIDs.filter((referenceID) =>
    get(rmas, [referenceID, 'tags'], []).find((t) => t.id === returnOrderTagID),
  )

  referenceIDsWithTag.map((referenceID) =>
    patchReturnOrderTag(referenceID, tag, false),
  )

  try {
    await Promise.all(
      referenceIDsWithTag.map((referenceID) =>
        apiverson.delete(
          `/return_order/${encodeURIComponent(
            referenceID,
          )}/tag/${encodeURIComponent(tag.name)}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error removing RMA tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    referenceIDsWithTag.map((referenceID) =>
      patchReturnOrderTag(referenceID, tag, true),
    )
  }
}
