import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import values from 'lodash/values.js'
import get from 'lodash/get.js'
import isBefore from 'date-fns/isBefore'
import isEqual from 'lodash/isEqual.js'
import cloneDeep from 'lodash/cloneDeep.js'

import {getState, setForm, updateForm, updateFormObject} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import api from '../common/api.js'
import {
  AMAZON,
  AMAZON_CA,
  AMAZON_UK,
  BIGCOMMERCE,
  CARTS_WITHOUT_ORDER_REVISIONS,
  CUSTOM_INTEGRATION,
  ECWID,
  ETSY,
  JET,
  MAGENTO_V2,
  MANUAL,
  ORDORO_VENDOR_CONNECT,
  OTHER,
  REVERB,
  SHIP_STATION,
  SHOPIFY,
  SPS_COMMERCE,
  SQUARE,
  SQUARESPACE,
  THREEDCART,
  VENDOR_PORTAL,
  VOLUSION,
  WALMART,
  WAYFAIR,
  WOOCOMMERCE,
  cartVendorOptions,
} from '../common/constants/CartVendorOptions.js'
import {
  companyFeaturesSelector,
  canUseSPSCommerceSelector,
  canUseShipStationSelector,
  useShopifyLocationAsWarehouseMappedSelector,
  usesInventorySelector,
  useInventoryWritebackSelector,
  maxCartCountSelector,
} from '../data/company.js'
import {
  hasOrderImportFromCartPermissionSelector,
  hasOrderManageRevisionPermissionSelector,
  hasProductImportFromCartPermissionSelector,
  hasProductWritebackInventoryPermissionSelector,
} from '../data/me.js'
import {
  warehousesSelector,
  warehousesSortedByNameSelector,
} from './warehouses.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {tokenizeOptions, getNounsFromValue} from '../common/tokenizeOptions.js'
import {buildPath} from '../common/querystring.js'
import {
  accountingIntegrationsSelector,
  integrationsSelector,
} from './integrations.js'

export const CARTS = 'CARTS'

export function setCarts(carts) {
  setForm(CARTS, keyBy(carts, 'id'))
}

export function setCart(cart) {
  updateForm(CARTS, {[cart.id]: cart})
}

export async function getCarts() {
  try {
    const carts = await fetchAllAPI('/cart', 'cart', {all: true})

    setCarts(carts)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting carts.',
        details: err.message || err.error_message,
      },
      err,
    )

    setCarts([])
  }
}

export function cartsSelector(state) {
  return state.ui.forms[CARTS] || {}
}

export function cartsHaveLoadedSelector(state) {
  return !!state.ui.forms[CARTS]
}

export function cartSelector(state, {cartID}) {
  return cartsSelector(state)[cartID]
}

export function getCartName(cart) {
  return get(cart, 'name', '')
}

export const cartsByNameSelector = createSelector(cartsSelector, (carts) =>
  keyBy(carts, 'name'),
)

export const cartsByNameAndIndexSelector = createSelector(
  cartsSelector,
  (carts) => keyBy(carts, ({index, name}) => `${name} - ${index}`),
)

export const cartsSortedByNameSelector = createSelector(
  cartsByNameAndIndexSelector,
  (cartsByName) =>
    Object.keys(cartsByName)
      .sort()
      .map((index) => cartsByName[index]),
)

export const activeCartsSortedByNameSelector = createSelector(
  cartsSortedByNameSelector,
  (carts) => carts.filter((cart) => !getIsCartArchived(cart)),
)

export const cartOptionsSelector = createSelector(
  activeCartsSortedByNameSelector,
  (carts) =>
    carts.map((cart) => ({
      value: cart.id,
      display: getCartName(cart),
      entity: cart,
      type: CARTS,
      nouns: [...getNounsFromValue(getCartName(cart)), cart.vendor],
    })),
)

export const cartOptionTokensSelector = createSelector(
  cartOptionsSelector,
  (cartOptions) => tokenizeOptions(cartOptions),
)

export function getIsCartArchived(cart) {
  return !!(cart && cart.archived_date)
}

export function isCartArchivedSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return getIsCartArchived(cart)
}

export const allCartsAreWebhookedSelector = createSelector(
  cartsSelector,
  (carts) =>
    values(carts).every((cart) => {
      if (cart.vendor === MANUAL) {
        return true
      }
      return cart.vendor_config && cart.vendor_config.use_webhooks
    }),
)

export const userCreatedCartsSelector = createSelector(
  activeCartsSortedByNameSelector,
  (carts) => carts.filter((cart) => cart.vendor !== MANUAL),
)

export const needsCartSelector = createSelector(
  userCreatedCartsSelector,
  (carts) => carts.length === 0,
)

export const userCreatedCartCountSelector = createSelector(
  userCreatedCartsSelector,
  (userCreatedCarts) => userCreatedCarts.length,
)

export const canAddCartsSelector = createSelector(
  userCreatedCartCountSelector,
  maxCartCountSelector,
  (cartCount, maxCartCount) => cartCount < maxCartCount,
)

export const cartVendorsSelector = createSelector(
  activeCartsSortedByNameSelector,
  (carts) =>
    Array.from(
      carts.reduce((prev, {vendor}) => {
        prev.add(vendor)

        return prev
      }, new Set()),
    ),
)

export function getIsAmazonCart(cart) {
  return !!cart.vendor.match(/amazon/)
}

export function isAmazonCartSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return cart ? getIsAmazonCart(cart) : false
}

export const amazonCartsSelector = createSelector(
  cartsSortedByNameSelector,
  (carts) => carts.filter((cart) => getIsAmazonCart(cart)),
)

export const hasAmazonCartsSelector = createSelector(
  amazonCartsSelector,
  (carts) => carts.length > 0,
)

export function cartVendorSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return get(cart, 'vendor') || null
}

export function cartVendorNameSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  if (!cart) {
    return ''
  }

  const option = cartVendorOptions.find(({value}) => cart.vendor === value)

  return option ? option.display : ''
}

export function getMultiLocations(cart) {
  if (!cart) {
    return null
  }

  if ([SHOPIFY, SQUARE, WAYFAIR].includes(cart.vendor) === false) {
    return null
  }

  if (
    cart.vendor === SHOPIFY &&
    !get(cart, 'vendor_config.multi_location_enabled')
  ) {
    return null
  }

  return get(cart, 'vendor_config.locations', null)
}

export function multiLocationsSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return getMultiLocations(cart)
}

export function getHasMultiLocations(cart) {
  return !!getMultiLocations(cart)
}

export function hasMultiLocationsSelector(state, {cartID}) {
  return !!multiLocationsSelector(state, {cartID})
}

export function vendorConfigSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return get(cart, 'vendor_config', {})
}

export function getAutoSyncActivities(cart) {
  return get(cart, 'autosync_activities', [])
}

export function autoSyncActivitiesSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return getAutoSyncActivities(cart)
}

export function autoSyncOrdersSelector(state, {cartID}) {
  const autoSyncActivities = autoSyncActivitiesSelector(state, {cartID})

  return autoSyncActivities.includes('import_orders_from_cart')
}

export function autoSyncProductsSelector(state, {cartID}) {
  const autoSyncActivities = autoSyncActivitiesSelector(state, {cartID})

  return autoSyncActivities.includes('import_products_from_cart')
}

export function autoSyncProductImagesSelector(state, {cartID}) {
  const autoSyncActivities = autoSyncActivitiesSelector(state, {cartID})

  return autoSyncActivities.includes('import_images_from_cart')
}

export function getAutoSyncInventory(cart) {
  const autoSyncActivities = getAutoSyncActivities(cart)

  return autoSyncActivities.includes('export_products_to_cart')
}

export function autoSyncInventorySelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return getAutoSyncInventory(cart)
}

export function autoSyncFBAInventorySelector(state, {cartID}) {
  const autoSyncActivities = autoSyncActivitiesSelector(state, {cartID})

  return autoSyncActivities.includes('import_fba_inventory')
}

export function autoSyncFBAOrdersSelector(state, {cartID}) {
  const autoSyncActivities = autoSyncActivitiesSelector(state, {cartID})

  return autoSyncActivities.includes('import_orders_fba_from_cart')
}

export function associatedCartWarehouseSelector(state, {cartID}) {
  return Object.values(warehousesSelector(state)).find(
    ({cart}) => get(cart, 'id') === cartID,
  )
}

export function isFBACartSelector(state, {cartID}) {
  return !!associatedCartWarehouseSelector(state, {cartID})
}

export function useWebhooksSelector(state, {cartID}) {
  const vendorConfig = vendorConfigSelector(state, {cartID})

  return get(vendorConfig, 'use_webhooks', false)
}

export function hasWebhookCartSelector(state, {cartIDs}) {
  return cartIDs.reduce(
    (prev, cartID) => prev || useWebhooksSelector(state, {cartID}),
    false,
  )
}

export function usesNewOrderImportProcessSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return [
    BIGCOMMERCE,
    ECWID,
    ETSY,
    JET,
    MAGENTO_V2,
    ORDORO_VENDOR_CONNECT,
    REVERB,
    SHOPIFY,
    SQUARE,
    SQUARESPACE,
    THREEDCART,
    VOLUSION,
    WALMART,
    WOOCOMMERCE,
  ].includes(cart.vendor)
}

export function canImportOrdersSelector(state) {
  const hasOrderImportFromCartPermission =
    hasOrderImportFromCartPermissionSelector(state)

  return hasOrderImportFromCartPermission
}

export function canImportProductImagesSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})
  const hasProductImportFromCartPermission =
    hasProductImportFromCartPermissionSelector(state)

  if (!hasProductImportFromCartPermission) {
    return false
  }

  return [BIGCOMMERCE, SHOPIFY, SQUARESPACE].includes(cart.vendor)
}

export function canImportProductsSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})
  const hasProductImportFromCartPermission =
    hasProductImportFromCartPermissionSelector(state)

  if (!hasProductImportFromCartPermission) {
    return false
  }

  return (
    get(cart, 'vendor_config.permissions.product_import', true) &&
    [SPS_COMMERCE].includes(cart.vendor) === false
  )
}

export function canExportProductsSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})
  const hasProductWritebackInventoryPermission =
    hasProductWritebackInventoryPermissionSelector(state)

  if (!hasProductWritebackInventoryPermission) {
    return false
  }

  return (
    get(cart, 'vendor_config.permissions.product_export', true) &&
    [SHIP_STATION].includes(cart.vendor) === false
  )
}

export function canReviseOrdersSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})
  const hasOrderManageRevisionPermission =
    hasOrderManageRevisionPermissionSelector(state)

  if (
    !cart ||
    !hasOrderManageRevisionPermission ||
    CARTS_WITHOUT_ORDER_REVISIONS.includes(cart.vendor)
  ) {
    return false
  }

  return true
}

export function canReviseLineTotalPriceSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})
  const canReviseOrders = canReviseOrdersSelector(state, {cartID})

  if (!canReviseOrders) {
    return false
  }

  return get(cart, 'vendor_config.permissions.line_total', true)
}

export function usesWalmartNewAuthSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  if (!cart) {
    return false
  }

  return cart.vendor === WALMART && cart.vendor_config.client_id
}

export function canHaveFulfillmentLatencySelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  if (!cart) {
    return false
  }

  const usesWalmartNewAuth = usesWalmartNewAuthSelector(state, {cartID})

  return (
    [AMAZON, AMAZON_CA, AMAZON_UK].includes(cart.vendor) ||
    (cart.vendor === WALMART && !usesWalmartNewAuth)
  )
}

export function cartsCanHaveFulfillmentLatencySelector(state) {
  const carts = cartsSelector(state)

  return Object.keys(carts).reduce((prev, cartID) => {
    cartID = Number(cartID)

    prev[cartID] = canHaveFulfillmentLatencySelector(state, {
      cartID,
    })

    return prev
  }, {})
}

export function getPreventWriteBackSettingsAdjustment(cart) {
  return get(cart, 'prevent_write_back_settings_adjustment', false)
}

export function preventWriteBackSettingsAdjustmentSelector(state, {cartID}) {
  const useInventoryWriteback = useInventoryWritebackSelector(state)
  const cart = cartSelector(state, {cartID})

  return !useInventoryWriteback || getPreventWriteBackSettingsAdjustment(cart)
}

export function getFulfillmentChannelFromVendor(vendor) {
  if (vendor === AMAZON) {
    return 'AMAZON_NA'
  } else if (vendor === AMAZON_CA) {
    return 'AMAZON_CA'
  } else if (vendor === AMAZON_UK) {
    return 'AMAZON_UK'
  }

  return null
}

export const cartVendorOptionsSelector = createSelector(
  companyFeaturesSelector,
  canUseSPSCommerceSelector,
  canUseShipStationSelector,
  (features, canUseSPSCommerce, canUseShipStation) =>
    cartVendorOptions.filter(
      (option) =>
        option.value !== OTHER &&
        option.value !== MAGENTO_V2 &&
        option.value !== ORDORO_VENDOR_CONNECT &&
        option.value !== VENDOR_PORTAL &&
        option.value !== CUSTOM_INTEGRATION &&
        option.value !== AMAZON_CA &&
        option.value !== AMAZON_UK &&
        !(option.value === JET && !features.use_jet) &&
        !(option.value === SHIP_STATION && !canUseShipStation) &&
        !(option.value === SPS_COMMERCE && !canUseSPSCommerce),
    ),
)

export function bigCommerceStoreURLSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})
  const context = get(cart, ['vendor_config', 'context'])

  return context
    ? `https://store-${context.replace('stores/', '')}.mybigcommerce.com`
    : ''
}

export function tokenExpirationSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  if (!cart) {
    return {tokenExpiration: null, isExpired: false}
  }

  const tokenExpiration =
    cart.vendor_config.refresh_token_expiration ||
    cart.vendor_config.token_expiration ||
    null

  const isExpired = tokenExpiration
    ? isBefore(new Date(tokenExpiration), new Date())
    : false

  return {tokenExpiration, isExpired}
}

export function updateCartWarehouses(cartID, cartWarehouses) {
  updateFormObject(CARTS, [cartID, 'cweb'], cartWarehouses)
}

export function updateCartWarehouse(cartID, warehouseID, cartWarehouse) {
  updateFormObject(CARTS, [cartID, 'cweb', warehouseID], cartWarehouse)
}

function getCartWarehouseLinks(cart) {
  return cart ? cart.cweb : {}
}

export function cartWarehouseLinksSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  return getCartWarehouseLinks(cart)
}

export function cartWarehouseLinkSelector(state, {cartID, warehouseID}) {
  const cartWarehouseLinks = cartWarehouseLinksSelector(state, {cartID})

  return get(cartWarehouseLinks, warehouseID) || {}
}

export function getHasLinkedWarehouses(
  cartWarehouseLinks,
  hasMultiLocations,
  warehouses,
) {
  return warehouses.reduce((prev, {id}) => {
    const {include, locations} = cartWarehouseLinks[id] || {
      include: true,
      locations: [],
    }

    if (include !== false && (!hasMultiLocations || locations.length !== 0)) {
      return true
    }

    return prev
  }, false)
}

export function hasLinkedWarehousesSelector(state, {cartID}) {
  const cartWarehouseLinks = cartWarehouseLinksSelector(state, {cartID})
  const hasMultiLocations = hasMultiLocationsSelector(state, {cartID})
  const warehouses = warehousesSortedByNameSelector(state)

  return getHasLinkedWarehouses(
    cartWarehouseLinks,
    hasMultiLocations,
    warehouses,
  )
}

export const cartsHaveLinkedWarehousesSelector = createSelector(
  cartsSelector,
  warehousesSortedByNameSelector,
  (carts, warehouses) =>
    Object.entries(carts).reduce((prev, [cartID, cart]) => {
      const cartWarehouseLinks = getCartWarehouseLinks(cart)
      const hasMultiLocations = getHasMultiLocations(cart)

      prev[cartID] = getHasLinkedWarehouses(
        cartWarehouseLinks,
        hasMultiLocations,
        warehouses,
      )

      return prev
    }, {}),
)

export function canSyncTrackingSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})
  const useShopifyLocationAsWarehouseMapped =
    useShopifyLocationAsWarehouseMappedSelector(state)

  return useShopifyLocationAsWarehouseMapped && cart.vendor === SHOPIFY
}

export function canSyncTrackingAndInventorySelector(state, {cartID}) {
  const usesInventory = usesInventorySelector(state)
  const canSyncTracking = canSyncTrackingSelector(state, {cartID})

  if (!canSyncTracking && !usesInventory) {
    return false
  }

  return true
}

export function cartsSyncTrackingAndInventorySelector(state) {
  const carts = activeCartsSortedByNameSelector(state)

  return carts.reduce((prev, cart) => {
    if (
      canSyncTrackingAndInventorySelector(state, {cartID: cart.id}) &&
      cart.vendor !== MANUAL
    ) {
      prev.push(cart)
    }

    return prev
  }, [])
}

export function cartsWithMultiLocationsSelector(state) {
  const carts = activeCartsSortedByNameSelector(state)

  return carts.reduce((prev, cart) => {
    if (hasMultiLocationsSelector(state, {cartID: cart.id})) {
      prev.push(cart)
    }

    return prev
  }, [])
}

export async function updateCartWarehouseLinks(cartID, updatedWarehouseLinks) {
  const warehouseLinks = cartWarehouseLinksSelector(getState(), {cartID})

  const updates = Object.keys(updatedWarehouseLinks).reduce(
    (prev, warehouseID) => {
      const og = warehouseLinks[warehouseID]
      const nd = updatedWarehouseLinks[warehouseID]

      if (
        !og ||
        og.include !== nd.include ||
        !isEqual(og.locations, nd.locations)
      ) {
        prev.push({
          warehouseID: Number(warehouseID),
          form: nd,
        })
      }

      return prev
    },
    [],
  )

  for (let {warehouseID, form} of updates) {
    await saveCartWarehouse(cartID, warehouseID, form)
  }
}

export async function saveCartWarehouse(cartID, warehouseID, form) {
  const url = `/cart/${cartID}/warehouse/${warehouseID}/`
  const cartWarehouse = cartWarehouseLinkSelector(getState(), {
    cartID,
    warehouseID,
  })

  updateCartWarehouse(cartID, warehouseID, form)

  let cartWarehouses
  if (form.include !== false && cartWarehouse.include === false) {
    const {json} = await api.post(url)
    cartWarehouses = json
  }
  if (!isEqual(cartWarehouse.locations, form.locations)) {
    const {json} = await api.put(url, {include: false, locations: [], ...form})
    cartWarehouses = json
  }
  if (form.include === false && cartWarehouse.include !== false) {
    const {json} = await api.delete(url)
    cartWarehouses = json
  }

  updateCartWarehouses(cartID, cartWarehouses)
}

export function cartIntegrationBridgeSelector(
  state,
  {cartIDs = [], integrationIDs = []},
) {
  let cib = []
  const integrations = integrationsSelector(state)
  const integrationsSorted = accountingIntegrationsSelector(state)
  const carts = cartsSelector(state)
  const cartsSorted = cartsSortedByNameSelector(state)
  const isCartFocused = cartIDs.length === 1

  if (isCartFocused) {
    const cart = carts[cartIDs[0]]

    for (const integration of integrationsSorted) {
      // if we have a list of integration IDs then make sure we only include those
      if (integrationIDs.length && !integrationIDs.includes(integration.id)) {
        continue
      }
      // otherwise show all active integrations
      if (integration.archived_date) {
        continue
      }

      cib.push(
        cloneDeep(
          cart.cib.find(
            ({integration_id}) => integration.id === integration_id,
          ),
        ) || {
          cart_id: cart.id,
          integration_id: integration.id,
          active: true,
          config: {export_tax_as_line_item: true},
        },
      )
    }
  } else {
    const integration = integrations[integrationIDs[0]]

    for (const cart of cartsSorted) {
      // if we have a list of cart IDs then make sure we only include those
      if (cartIDs.length && !cartIDs.includes(cart.id)) {
        continue
      }
      // otherwise show all active carts
      if (cart.archived_date) {
        continue
      }

      cib.push(
        cloneDeep(cart.cib.find(({cart_id}) => cart.id === cart_id)) || {
          cart_id: cart.id,
          integration_id: integration.id,
          active: true,
          config: {export_tax_as_line_item: true},
        },
      )
    }
  }

  return cib
}

export async function updateCartIntegrations(cib) {
  const promises = []
  const cartList = new Set()

  for (const bridge of cib) {
    const cart = cartSelector(getState(), {cartID: bridge.cart_id})
    const originalBridge = cart.cib.find(
      ({integration_id}) => bridge.integration_id === integration_id,
    )

    if (
      !originalBridge ||
      originalBridge.active !== bridge.active ||
      !isEqual(originalBridge.config, bridge.config)
    ) {
      promises.push(
        api.put(
          buildPath([
            'cart',
            bridge.cart_id,
            'integration',
            bridge.integration_id,
          ]),
          {config: bridge.config, active: bridge.active},
        ),
      )

      cartList.add(bridge.cart_id)
    }
  }

  await Promise.all(promises)

  for (const cartID of cartList) {
    const {json} = await api.get(buildPath(['cart', cartID]))

    setCart(json)
  }
}
