import PropTypes from 'prop-types'
import Checkbox from '../../../common/components/Checkbox.js'
import {
  formSelector,
  getState,
  setFormValue,
  useForm,
  useSelector,
} from '../../../store.js'
import FormListItem from '../Common/FormListItem.js'
import {
  canReviseLineTotalPriceSelector,
  canReviseOrdersSelector,
} from '../../../data/carts.js'
import uniq from 'lodash/uniq.js'
import {useMemo} from 'react'

function toggleRevisionConfigShippingAddress(formName, propertiesToToggle) {
  const form = formSelector(getState(), {formName})

  const oldProperties = form.revision_config?.shipping_address || []

  const notThoseProperties = oldProperties.filter(
    (p) => !propertiesToToggle.includes(p),
  )

  let properties = []
  if (notThoseProperties.length !== oldProperties.length) {
    // turn all off
    properties = notThoseProperties
  } else {
    properties = uniq([...oldProperties, ...propertiesToToggle])
  }

  setFormValue(
    formName,
    ['revision_config', 'shipping_address'],
    properties.length ? properties : undefined,
  )
}

const SHIPPING_ADDRESS_NAME = ['name', 'company']
const SHIPPING_ADDRESS_ADDRESS = [
  'street1',
  'street2',
  'city',
  'state',
  'zip',
  'country',
]
const SHIPPING_ADDRESS_CONTACT = ['email', 'phone']

export default function RevisionConfig({className, formName}) {
  const {id} = useForm(formName)
  const canReviseOrders = useSelector((state) =>
    canReviseOrdersSelector(state, {cartID: id}),
  )
  const canReviseLineTotalPrice = useSelector((state) =>
    canReviseLineTotalPriceSelector(state, {cartID: id}),
  )
  const {revision_config} = useForm(formName)
  const shippingAddressProperties = useMemo(
    () => revision_config.shipping_address || [],
    [revision_config],
  )
  const isShippingAddressName = useMemo(
    () =>
      shippingAddressProperties.filter((property) =>
        SHIPPING_ADDRESS_NAME.includes(property),
      ).length > 0,
    [shippingAddressProperties],
  )
  const isShippingAddressAddress = useMemo(
    () =>
      shippingAddressProperties.filter((property) =>
        SHIPPING_ADDRESS_ADDRESS.includes(property),
      ).length > 0,
    [shippingAddressProperties],
  )
  const isShippingAddressContact = useMemo(
    () =>
      shippingAddressProperties.filter((property) =>
        SHIPPING_ADDRESS_CONTACT.includes(property),
      ).length > 0,
    [shippingAddressProperties],
  )

  if (!canReviseOrders) {
    return null
  }

  return (
    <FormListItem className={className}>
      <p className="fs-00 lh-md margin-bottom-5">
        <strong>External Order Revisions</strong>{' '}
        <span className="margin-right-3">(if supported)</span>
        <a
          className="btn btn--link no-underline light"
          href="https://support.ordoro.com/how-do-i-sync-order-line-revisions-in-ordoro/"
          target="_blank"
          rel="noopener noreferrer"
          title="Learn about external order line revisions"
        >
          <span className="i-help fs-01 lh-sm margin-right-3"></span>
        </a>
      </p>
      <p className="fs-00 lh-md margin-bottom-5">
        Select from the following list which elements of an order you would like
        to receive notifications for if they are updated outside of Ordoro. Once
        notified, you can choose to accept the revision in Ordoro.
      </p>
      <p className="fs-00 lh-md">
        <a
          className="btn btn--link light"
          href="https://support.ordoro.com/how-do-i-sync-order-line-revisions-in-ordoro/"
          target="_blank"
          rel="noopener noreferrer"
          title="Learn about external order line revisions"
        >
          View list of supported sales channels
        </a>{' '}
        →
      </p>
      <div className="wrap--v-align-inner margin-bottom-10">
        <Checkbox
          id="revision_config__shipping_address__name"
          label="Changes to Shipping Address Name"
          checked={isShippingAddressName}
          onChange={() =>
            toggleRevisionConfigShippingAddress(formName, SHIPPING_ADDRESS_NAME)
          }
        />
      </div>
      <div className="wrap--v-align-inner margin-bottom-10">
        <Checkbox
          id="revision_config__shipping_address__contact"
          label="Changes to Shipping Address Email or Phone"
          checked={isShippingAddressContact}
          onChange={() =>
            toggleRevisionConfigShippingAddress(
              formName,
              SHIPPING_ADDRESS_CONTACT,
            )
          }
        />
      </div>
      <div className="wrap--v-align-inner margin-bottom-10">
        <Checkbox
          id="revision_config__shipping_address__address"
          label="Changes to Shipping Address Street, City, State or Zipcode"
          checked={isShippingAddressAddress}
          onChange={() =>
            toggleRevisionConfigShippingAddress(
              formName,
              SHIPPING_ADDRESS_ADDRESS,
            )
          }
        />
      </div>
      <div className="wrap--v-align-inner margin-bottom-10">
        <Checkbox
          id="revision_config__line_changes__quantity"
          label="Changes to Line Quantity"
          checked={revision_config.lines_changed.includes('quantity')}
          onChange={(checked) => {
            let line = revision_config.lines_changed.filter(
              (v) => v !== 'quantity',
            )

            if (checked) {
              line.push('quantity')
            }

            setFormValue(formName, ['revision_config', 'lines_changed'], line)
          }}
        />
      </div>
      {canReviseLineTotalPrice && (
        <div className="wrap--v-align-inner margin-bottom-10">
          <Checkbox
            id="revision_config__line_changes__total_price"
            label="Changes to the Line Total Price"
            checked={revision_config.lines_changed.includes('total_price')}
            onChange={(checked) => {
              let line = revision_config.lines_changed.filter(
                (v) => v !== 'total_price',
              )

              if (checked) {
                line.push('total_price')
              }

              setFormValue(formName, ['revision_config', 'lines_changed'], line)
            }}
          />
        </div>
      )}
      <div className="wrap--v-align-inner margin-bottom-10">
        <Checkbox
          id="revision_config__lines_added"
          label="Additional Lines"
          checked={revision_config.lines_added}
          onChange={(checked) =>
            setFormValue(formName, ['revision_config', 'lines_added'], checked)
          }
        />
      </div>
      <div className="wrap--v-align-inner margin-bottom-10">
        <Checkbox
          id="revision_config__lines_removed"
          label="Removed Lines"
          checked={revision_config.lines_removed}
          onChange={(checked) =>
            setFormValue(
              formName,
              ['revision_config', 'lines_removed'],
              checked,
            )
          }
        />
      </div>
      <p className="fs-00 lh-md margin-top-15 margin-bottom-5">
        <strong>Want to accept external order revisions automatically?</strong>
      </p>
      <p className="fs-00 lh-md margin-bottom-10">
        Checking the following option will bypass notifications of your selected
        order revision types and instead automatically apply them to the
        respective order in Ordoro.
      </p>
      <div className="wrap--v-align-inner margin-bottom-10">
        <Checkbox
          id="revision_config__auto_apply"
          label="Automatically accept my selected order revisions"
          checked={!!revision_config.auto_apply}
          onChange={(checked) =>
            setFormValue(formName, ['revision_config', 'auto_apply'], checked)
          }
        />
      </div>
    </FormListItem>
  )
}

RevisionConfig.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
