import {formSelector} from '../../../store.js'

export function setupExportBOMInventoryForm() {
  return {}
}

export function exportBOMInventoryErrorsSelector() {
  return {}
}

export function exportBOMInventoryPayloadSelector(state, {formName}) {
  const {reportType} = formSelector(state, {formName})

  const payload = {
    type: reportType,
    params: {},
  }

  return payload
}

export default function ExportBOMInventoryForm() {
  return null
}
