import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import get from 'lodash/get.js'

import {getState, setForm, updateForm, formsSelector} from '../store.js'
import apiverson from '../common/apiverson.js'
import {fetchAllApiverson} from '../common/fetchAll.js'
import {TAG_TYPE__MO} from '../common/constants/Tags.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {patchMOTag, mosSelector} from './mos.js'
import {refreshMOList} from '../ordoro/MOListPage/moListActions.js'
import {hasUseMOsFeatureSelector} from '../data/company.js'
import {tokenizeOptions, getNounsFromValue} from '../common/tokenizeOptions.js'

export const MO_TAGS = 'MO_TAGS'

export function setMOTags(moTags) {
  setForm(MO_TAGS, keyBy(moTags, 'id'))
}

export function updateMOTag(moTag) {
  updateForm(MO_TAGS, {[moTag.id]: moTag})
}

export function removeMOTag(moTag) {
  const moTags = {...moTagsSelector(getState())}

  delete moTags[moTag.id]

  setForm(MO_TAGS, moTags)
}

export function moTagsSelector(state) {
  return formsSelector(state)[MO_TAGS] || moTagsSelector.default
}
moTagsSelector.default = {}

export function moTagSelector(state, {moTagID}) {
  return moTagsSelector(state)[moTagID]
}

export const moTagsSortedByNameSelector = createSelector(
  moTagsSelector,
  (moTags) => sortBy(moTags, ({name}) => name.toLowerCase()),
)

export const moTagOptionsSelector = createSelector(
  moTagsSortedByNameSelector,
  (tags) =>
    tags.map((tag) => ({
      value: tag.id,
      display: tag.name,
      entity: tag,
      type: TAG_TYPE__MO,
      nouns: getNounsFromValue(tag.name),
    })),
)

export const moTagOptionTokensSelector = createSelector(
  moTagOptionsSelector,
  (tagOptions) => tokenizeOptions(tagOptions),
)

export function moTagsHaveLoadedSelector(state) {
  return !!formsSelector(state)[MO_TAGS]
}

export async function getMOTags() {
  try {
    if (!hasUseMOsFeatureSelector(getState())) {
      return
    }

    const moTags = await fetchAllApiverson('/manufacturing_order/tag/', 'tag')

    setMOTags(moTags)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting MFG Order tags.',
        details: err.error_message || err.message,
      },
      err,
    )

    setMOTags([])
  }
}

export async function saveMOTag(name, color, id) {
  const params = {name: name.trim(), color}
  const originalTag = id ? moTagSelector(getState(), {moTagID: id}) : null

  const {json} = id
    ? await apiverson.put(
        `/manufacturing_order/tag/${encodeURIComponent(originalTag.name)}`,
        params,
      )
    : await apiverson.post('/manufacturing_order/tag', params)

  updateMOTag(json)

  if (id) {
    await refreshMOList()
  }

  return json
}

export async function deleteMOTag(tagID) {
  const tag = moTagSelector(getState(), {moTagID: tagID})

  await apiverson.delete(
    `/manufacturing_order/tag/${encodeURIComponent(tag.name)}`,
  )

  removeMOTag(tag)
}

export async function addTagToMOs(moTagID, referenceIDs) {
  const mos = mosSelector(getState())
  const tag = moTagSelector(getState(), {moTagID})

  const referenceIDsWithoutTag = referenceIDs.filter(
    (referenceID) =>
      !get(mos, [referenceID, 'tags'], []).find((t) => t.id === moTagID),
  )

  referenceIDsWithoutTag.map((referenceID) =>
    patchMOTag(referenceID, tag, true),
  )

  try {
    await Promise.all(
      referenceIDsWithoutTag.map((referenceID) =>
        apiverson.post(
          `/manufacturing_order/${encodeURIComponent(
            referenceID,
          )}/tag/${encodeURIComponent(tag.name)}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error adding MFG Order tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    referenceIDsWithoutTag.map((referenceID) =>
      patchMOTag(referenceID, tag, false),
    )
  }
}

export async function removeTagFromMOs(moTagID, referenceIDs) {
  const mos = mosSelector(getState())
  const tag = moTagSelector(getState(), {moTagID})

  const referenceIDsWithTag = referenceIDs.filter((referenceID) =>
    get(mos, [referenceID, 'tags'], []).find((t) => t.id === moTagID),
  )

  referenceIDsWithTag.map((referenceID) => patchMOTag(referenceID, tag, false))

  try {
    await Promise.all(
      referenceIDsWithTag.map((referenceID) =>
        apiverson.delete(
          `/manufacturing_order/${encodeURIComponent(
            referenceID,
          )}/tag/${encodeURIComponent(tag.name)}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error removing MFG Order tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    referenceIDsWithTag.map((referenceID) => patchMOTag(referenceID, tag, true))
  }
}
