import {createSelector, lruMemoize} from 'reselect'
import reduce from 'lodash/reduce.js'
import keyBy from 'lodash/keyBy.js'
import uniqBy from 'lodash/uniqBy.js'
import round from 'lodash/round.js'
import get from 'lodash/get.js'
import head from 'lodash/head.js'
import isEmpty from 'lodash/isEmpty.js'
import maxBy from 'lodash/maxBy.js'
import entries from 'lodash/entries.js'
import countBy from 'lodash/countBy.js'
import compact from 'lodash/compact.js'
import last from 'lodash/last.js'
import sortBy from 'lodash/sortBy.js'
import pickBy from 'lodash/pickBy.js'
import pick from 'lodash/pick.js'
import isEqual from 'lodash/isEqual.js'
import omit from 'lodash/omit.js'
import chunk from 'lodash/chunk.js'
import isAfter from 'date-fns/isAfter'
import moment from 'moment'

import {memOutcome} from '../common/mem.js'
import {
  AMAZON,
  AMAZON_UK,
  AMAZON_CA,
  ORDORO_VENDOR_CONNECT,
  EBAY,
  MANUAL,
  SHOPIFY,
  SHOPSITE,
} from '../common/constants/CartVendorOptions.js'
import {AMAZON_SFP, CANADA_POST, UPS} from '../common/constants/ShipperNames.js'
import {
  STRIPE_PAYMENT_COMMENT,
  ADDRESS_COMMENT,
} from '../common/constants/Comments.js'
import {getRealDate} from '../common/date.js'
import {buildPath} from '../common/querystring.js'
import apiverson from '../common/apiverson.js'
import {
  ORDER_ALTERNATE_SHIP_FROM_ADDRESS,
  ORDER_SHIPPING_ADDRESS,
  ORDER_BILLING_ADDRESS,
} from '../common/constants/AddressTypes.js'
import {
  canUsePackShipSelector,
  hasManualDeliverByDateFeatureSelector,
  useDropshippingSelector,
  showFeatureLocksSelector,
} from '../data/company.js'
import {
  hasOrderPermissionSelector,
  hasOrderCreateLabelWithoutContentsVerifiedTagPermissionSelector,
} from '../data/me.js'
import {
  productsSelector,
  productIsDropshippedSelector,
  ensureProductsLoaded,
} from '../data/products.js'
import {makeProductKitSelector} from '../redux/selectors/data/productKits.js'
import {
  hasAmazonSFPSelector,
  shipperTypeSelector,
  shipperNameSelector,
  shipperCurrencySymbolSelector,
} from './shippers.js'
import {warehouseSelector, getWarehouseName} from './warehouses.js'
import {
  supplierSelector,
  supplierNameSelector,
  suppliersSortedByNameSelector,
} from './suppliers.js'
import {
  cartsSelector,
  cartSelector,
  activeCartsSortedByNameSelector,
} from './carts.js'
import {
  defaultPackingListSelector,
  firstPackingListSelector,
} from '../redux/selectors/data/packingLists.js'
import {
  shippingMethodNameSelector,
  boxShapeNameSelector,
} from './shipperOptions.js'
import {
  dimensionsAreRequired,
  getShipsuranceCost,
  ensureLabelInfosLoaded,
  refreshShippingAndReturnRates,
} from './labelInfos/index.js'
import {addTagToOrders, ensureTagExists} from './orderTags.js'
import {updateOrderCounts, updateOrderRevisionCount} from './orderCounts.js'
import {
  formsSelector,
  getState,
  setForm,
  setFormValue,
  updateForm,
  updateFormObject,
} from '../store.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {showMessageToast} from '../ordoro/Header/Toast/index.js'
import {showEditAddressModal} from '../ordoro/Modals/EditAddressModal/index.js'
import {refreshOrderListAndCounts} from '../ordoro/OrderListPage/orderListActions.js'

export const ORDERS = 'ORDERS'

export function setOrders(orders) {
  const ordersForm = formsSelector(getState())[ORDERS]
  let updatedOrdersForm = keyBy(orders, 'order_number')

  if (ordersForm) {
    updatedOrdersForm = handlePendingStatesFromUpdates(
      ordersForm,
      updatedOrdersForm,
    )

    updateForm(ORDERS, updatedOrdersForm)
  } else {
    setForm(ORDERS, updatedOrdersForm)
  }
}

export function setOrder(order) {
  setOrders([order])
}

export function handlePendingStatesFromUpdates(original, updates) {
  // we need to keep the pending tag states while things resolve
  for (const [orderNumber, order] of Object.entries(updates)) {
    const originalOrder = original[orderNumber]

    if (!originalOrder) {
      continue
    }

    const [pendingRemoveTagIDs, pendingAddTags] = originalOrder.tags.reduce(
      (prev, tag) => {
        if (tag.pending === -1) {
          prev[0].push(tag.id)
        } else if (tag.pending === 1) {
          prev[1].push(tag)
        }

        return prev
      },
      [[], []],
    )

    order.tags = order.tags.map((tag) => {
      if (pendingRemoveTagIDs.includes(tag.id)) {
        // trying to remove and it's still there
        // so keep the pending status
        tag.pending = -1
      }

      return tag
    })

    order.tags = pendingAddTags.reduce((prev, pendingNewTag) => {
      if (!prev.find(({id}) => id === pendingNewTag.id)) {
        // this new tag isn't in the list of order tags
        // so keep it with it's pending state
        prev.push(pendingNewTag)
      }

      return prev
    }, order.tags)
  }

  return updates
}

export function removeOrders(orderNumbers) {
  const orders = allOrdersSelector(getState())

  setForm(ORDERS, omit(orders, orderNumbers))
}

export function updateOrder(orderNumber, updates) {
  updateFormObject(ORDERS, [orderNumber], updates)
}

export function setOrderAddress(orderNumber, addressType, address) {
  setFormValue(
    ORDERS,
    [
      orderNumber,
      addressType === ORDER_ALTERNATE_SHIP_FROM_ADDRESS
        ? 'alternate_ship_from_address'
        : addressType === ORDER_SHIPPING_ADDRESS
          ? 'shipping_address'
          : 'billing_address',
    ],
    address,
  )
}

export function setOrderComments(orderNumber, comments) {
  setFormValue(ORDERS, [orderNumber, 'comments'], comments)
}

export function setOrderFinancial(orderNumber, financial) {
  setFormValue(ORDERS, [orderNumber, 'financial'], financial)
}

export function markTagPending(orderNumber, orderTag, isAdding) {
  const {tags} = orderSelector(getState(), {orderNumber})

  let exists = false
  const newOrderTags = tags.map((tag) => {
    if (tag.id === orderTag.id) {
      tag.pending = isAdding ? 1 : -1
      exists = true
    }

    return tag
  })

  if (!exists) {
    newOrderTags.push({...orderTag, pending: isAdding ? 1 : -1})
  }

  setFormValue(ORDERS, [orderNumber, 'tags'], newOrderTags)
}

export function clearPendingTagAfterError(
  orderNumber,
  orderTagID,
  wasTryingToAdd,
) {
  const {tags} = orderSelector(getState(), {orderNumber})

  const newOrderTags = wasTryingToAdd
    ? tags.filter(({id}) => id !== orderTagID)
    : tags.map((tag) => {
        if (tag.id === orderTagID && tag.pending) {
          delete tag.pending
        }

        return tag
      })

  setFormValue(ORDERS, [orderNumber, 'tags'], newOrderTags)
}

export const allOrdersSelector = createSelector(
  formsSelector,
  (forms) => forms[ORDERS] || allOrdersSelector.default,
)
allOrdersSelector.default = {}

export const ordersByOrderIDSelector = createSelector(
  allOrdersSelector,
  (ordersByOrderNumber) =>
    Object.values(ordersByOrderNumber).reduce((prev, order) => {
      prev[order.id] = order

      return prev
    }, {}),
)

export function orderSelector(state, {orderNumber}) {
  return allOrdersSelector(state)[orderNumber]
}

export const createOrderSelector = (orderNumber) =>
  createSelector(allOrdersSelector, (orders) => orders[orderNumber])

export function ordersSelector(state, {orderNumbers}) {
  return orderNumbers.reduce((prev, orderNumber) => {
    const order = orderSelector(state, {orderNumber})

    if (order) {
      prev.push(order)
    }

    return prev
  }, [])
}

export const orderLinkSelector = createSelector(orderSelector, (order) =>
  get(order, 'link', ''),
)

export const canUseOrdersSelector = createSelector(
  hasOrderPermissionSelector,
  (hasPermission) => hasPermission,
)

export function getOrderIsSplittable(order) {
  if (['shipped', 'cancelled'].includes(order.status)) {
    return false
  }

  return (
    order.lines.length > 1 ||
    (order.lines.length == 1 && order.lines[0].quantity >= 2)
  )
}

export function getOrderIsAllocateable(order) {
  return (
    order.status === 'awaiting_fulfillment' &&
    ['unallocated', 'could_not_allocate', 'partial'].includes(
      order.allocation_status,
    )
  )
}

export function getOrderIsDeallocateable(order) {
  return (
    order.status === 'awaiting_fulfillment' &&
    ['could_not_allocate', 'allocated', 'overallocated', 'partial'].includes(
      order.allocation_status,
    )
  )
}

export function getOrderIsTrackingWritebackable(order, carts) {
  if (['awaiting_fulfillment'].includes(order.status)) {
    return false
  }

  if (
    !order.shipping_info ||
    !order.shipping_info.tracking_number ||
    order.shipping_info.tracking_number === 'Tracking Number Not Provided'
  ) {
    return false
  }

  const cartID = get(order, 'sales_channel.id')
  const vendor = get(carts, [cartID, 'vendor'])

  return vendor !== MANUAL
}

export function deallocatableOrdersSelector(state, {orderNumbers}) {
  const orders = ordersSelector(state, {orderNumbers})

  return orders.filter((order) => getOrderIsDeallocateable(order))
}

export function getOrderActions(
  order,
  useInventoryAllocation,
  hasOrderManageRevisionPermission,
  carts,
) {
  const defaultActions = {
    clone: true,
    cancel: true,
    split: getOrderIsSplittable(order),
    create_charge: true,
    send_invoice: true,
    edit_dimensions: true,
    edit_weight: true,
    address_labels: true,
    mark_as_awaiting_fulfillment: true,
    mark_as_shipped: true,
    initiate_return: false,
    attempt_to_allocate: false,
    deallocate: false,
    retry_tracking_writeback: getOrderIsTrackingWritebackable(order, carts),
    manage_revision: hasOrderManageRevisionPermission && order.has_revision,
  }

  switch (order.status) {
    case 'awaiting_fulfillment':
      return {
        ...defaultActions,
        mark_as_awaiting_fulfillment: false,
        merge: true,
        attempt_to_allocate:
          useInventoryAllocation && getOrderIsAllocateable(order),
        deallocate: useInventoryAllocation && getOrderIsDeallocateable(order),
      }
    case 'shipped':
      return {
        ...defaultActions,
        mark_as_shipped: false,
      }
    case 'dropshipment_requested':
      return {
        ...defaultActions,
      }
    case 'cancelled':
      return {
        ...defaultActions,
        cancel: false,
        clone: true,
        create_charge: false,
        send_invoice: false,
        mark_as_awaiting_fulfillment: false,
        mark_as_shipped: false,
        restore: true,
        edit_dimensions: false,
        edit_weight: false,
      }
    default:
      return {}
  }
}

function getOrdersAreMergable(orders, carts) {
  if (orders.length > 1) {
    const cartID = get(orders[0], 'sales_channel.id')
    const vendor = get(carts, [cartID, 'vendor'])

    if ([AMAZON, AMAZON_UK, AMAZON_CA, SHOPSITE].includes(vendor)) {
      return false
    }

    return orders.every((order) => {
      return (
        order.status === 'awaiting_fulfillment' &&
        getIsAnUnsplitOrder(order) &&
        order.sales_channel.id === cartID
      )
    })
  }
  return false
}

export function getOrdersActions(
  orders,
  canUseReturnOrders,
  carts,
  useInventoryAllocation,
  hasOrderManageRevisionPermission,
) {
  if (orders.length === 0 || !orders[0]) {
    return {}
  }

  const ordersActions = uniqBy(orders, (order) => order.status).map((order) =>
    getOrderActions(
      order,
      useInventoryAllocation,
      hasOrderManageRevisionPermission,
      carts,
    ),
  )
  if (orders.length > 1) {
    // split and clone should be disabled for multiple orders, but the others should be allowed
    ordersActions.push({
      mark_as_awaiting_fulfillment: true,
      mark_as_shipped: true,
      restore: true,
      cancel: true,
      split: false,
      clone: false,
      create_charge: true,
      send_invoice: false,
      edit_dimensions: true,
      edit_weight: true,
      address_labels: true,
      retry_tracking_writeback: true,
      manage_revision: hasOrderManageRevisionPermission,
    })
  }

  const availableActions = ordersActions.slice(1).reduce((acc, next) => {
    Object.keys(acc).forEach((key) => {
      if (!next[key]) {
        delete acc[key]
      }
    })

    return acc
  }, ordersActions[0])

  availableActions.merge = getOrdersAreMergable(orders, carts)
  availableActions.initiate_return = canUseReturnOrders && orders.length === 1

  if (useInventoryAllocation) {
    availableActions.attempt_to_allocate = orders.some(getOrderIsAllocateable)
    availableActions.deallocate = orders.some(getOrderIsDeallocateable)
  }

  return pickBy(availableActions, (action) => action)
}

export function getOrderNumber(order) {
  return order.order_number
}

export const getUnfulfilledOrderNumbers = memOutcome(
  (orders, pendingLabels) =>
    orders
      .filter(
        (order) =>
          order.status === 'awaiting_fulfillment' ||
          pendingLabels.includes(order.order_number),
      )
      .map(getOrderNumber),
  isEqual,
)

export const getIsCancelled = (order) =>
  order ? order.status === 'cancelled' : false

export const getCancelledOrderNumbers = lruMemoize((orders) =>
  orders.filter(getIsCancelled).map(getOrderNumber),
)

export const getIsMerged = (order) =>
  order ? order.status === 'merged' : false

export const getMergedOrderNumbers = lruMemoize((orders) =>
  orders.filter(getIsMerged).map(getOrderNumber),
)

export const orderAddressCanBeEditedSelector = createSelector(
  orderSelector,
  (order) => !(getIsMerged(order) || getIsCancelled(order)),
)

export const getOrderNumbersThatCanHaveShippingLabels = lruMemoize(
  (orders, pendingLabels) =>
    orders
      .filter(
        (order) =>
          !getIsCancelled(order) &&
          !getIsMerged(order) &&
          (!order.shipping_info.ship_date ||
            pendingLabels.includes(order.order_number)) &&
          !getIsMarkedAsShipped(order),
      )
      .map(getOrderNumber),
)

export const getOrderNumbersWithShippingLabels = lruMemoize(
  (orders, pendingLabels) =>
    orders
      .filter(
        (order) =>
          !!order.shipping_info.has_label &&
          !pendingLabels.includes(order.order_number),
      )
      .map(getOrderNumber),
)

export function ordersHaveTrackingCountSelector(state, {orderNumbers}) {
  const orders = ordersSelector(state, {orderNumbers})

  return orders.filter((order) => get(order, 'shipping_info.ship_date')).length
}

export const getIsDropshipped = (order) =>
  !!order.dropshipping_info.requested_date &&
  order.status !== 'awaiting_fulfillment'

export const isDropshippedSelector = createSelector(
  orderSelector,
  getIsDropshipped,
)

export const getDropshippedOrderNumbers = lruMemoize((orders) =>
  orders.filter(getIsDropshipped).map(getOrderNumber),
)

export const getIsArchived = (order) => order.status === 'archived'

export const getArchivedOrderNumbers = lruMemoize((orders) =>
  orders.filter(getIsArchived).map(getOrderNumber),
)

export const getHasTrackingInfo = (order) =>
  !!order.shipping_info.tracking_number

export const getIsMarkedAsShipped = (order) =>
  order.status === 'shipped' && !order.shipping_info.has_label

export const getMarkAsShippedOrderNumbers = lruMemoize((orders) =>
  orders.filter(getIsMarkedAsShipped).map(getOrderNumber),
)

export const getIsManuallyTracked = (order) =>
  order.status === 'shipped' &&
  order.shipping_info.has_label === false &&
  order.shipping_info.tracking_number !== 'Tracking Number Not Provided'

export const getManuallyTrackedOrderNumbers = lruMemoize((orders) =>
  orders.filter(getIsManuallyTracked).map(getOrderNumber),
)

export function orderNumbersWithShippingLabelsSelector(state, {orderNumbers}) {
  const orders = ordersSelector(state, {orderNumbers})

  return orders.reduce((prev, order) => {
    if (get(order, 'shipping_info.has_label')) {
      prev.push(order.order_number)
    }

    return prev
  }, [])
}

export function orderNumbersWithReturnLabelsSelector(state, {orderNumbers}) {
  const orders = ordersSelector(state, {orderNumbers})

  return orders.reduce((prev, order) => {
    if (get(order, 'return_shipping_info.has_label')) {
      prev.push(order.order_number)
    }

    return prev
  }, [])
}

export function shippingInfoSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  return get(order, 'shipping_info')
}

export function shippedShipperIDSelector(state, {orderNumber}) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  return get(shippingInfo, 'carrier.id')
}

export function getShippedShipperType(order) {
  return get(order, 'shipping_info.carrier_name')
}

export function shippedShipperTypeSelector(state, {orderNumber}) {
  const shipperID = shippedShipperIDSelector(state, {orderNumber})

  return shipperID ? shipperTypeSelector(state, {shipperID}) : null
}

export function shippedShipperNameSelector(state, {orderNumber}) {
  const shipperID = shippedShipperIDSelector(state, {orderNumber})

  return shipperID ? shipperNameSelector(state, {shipperID}) : null
}

export function shippedCostSelector(state, {orderNumber}) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  return get(shippingInfo, 'cost')
}

export function shippedShipperCurrencySymbolSelector(state, {orderNumber}) {
  const shipperID = shippedShipperIDSelector(state, {orderNumber})

  return shipperID ? shipperCurrencySymbolSelector(state, {shipperID}) : null
}

export function shippedTotalWeightSelector(state, {orderNumber}) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  return get(shippingInfo, 'packages', []).reduce(
    (prev, {weight}) => prev + weight,
    0,
  )
}

export function shippedDimensionsSelector(state, {orderNumber, packagesIndex}) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  return pick(get(shippingInfo, ['packages', packagesIndex], {}), [
    'height',
    'width',
    'length',
  ])
}

export function shippedBoxShapeSelector(state, {orderNumber, packagesIndex}) {
  packagesIndex = packagesIndex || 0

  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  return get(shippingInfo, ['packages', packagesIndex, 'box_shape'])
}

export function shippedBoxShapeNameSelector(
  state,
  {orderNumber, packagesIndex},
) {
  packagesIndex = packagesIndex || 0

  const shipperType = shippedShipperTypeSelector(state, {orderNumber})
  const boxShape = shippedBoxShapeSelector(state, {
    orderNumber,
    packagesIndex,
  })

  return shipperType && boxShape
    ? boxShapeNameSelector(state, {shipperType, boxShape})
    : null
}

export function showShippedDimensionsSelector(
  state,
  {orderNumber, packagesIndex},
) {
  const shipperType = shippedShipperTypeSelector(state, {orderNumber})
  const boxShape = shippedBoxShapeSelector(state, {orderNumber, packagesIndex})

  return dimensionsAreRequired([shipperType], {[shipperType]: [boxShape]})
}

export function isShippedMultiboxSelector(state, {orderNumber}) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  return get(shippingInfo, 'packages.length') > 1
}

export function shippedShippingMethodSelector(state, {orderNumber}) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})
  const shipperType = shippedShipperTypeSelector(state, {orderNumber})
  const methodCode = get(shippingInfo, 'shipping_method')

  return shipperType && methodCode
    ? shippingMethodNameSelector(state, {shipperType, methodCode})
    : methodCode
}

export function shippedConfigureIndividualBoxShapeSelector(
  state,
  {orderNumber},
) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})
  const shipperType = shippedShipperTypeSelector(state, {orderNumber})
  const shippingMethod = get(shippingInfo, 'shipping_method')

  return shipperType === UPS && shippingMethod === '01'
}

export function shippedInsuredValueSelector(state, {orderNumber}) {
  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  return get(shippingInfo, 'insured_value')
}

export function shippedInsuranceCostSelector(state, {orderNumber}) {
  const insuredValue = shippedInsuredValueSelector(state, {orderNumber})
  const shipperType = shippedShipperTypeSelector(state, {orderNumber})
  const shippingInfo = shippingInfoSelector(state, {orderNumber})

  const cost = get(shippingInfo, ['insurance', 'insurance_cost'])

  if (cost > 0) {
    return cost
  }

  return getShipsuranceCost(shipperType, insuredValue)
}

export function orderNumbersShippedWithAdditionalDocumentsSelector(
  state,
  {orderNumbers},
) {
  const orders = ordersSelector(state, {orderNumbers})

  return orders.reduce((prev, order) => {
    if (get(order, 'shipping_info.has_additional_docs')) {
      prev.push(order.order_number)
    }

    return prev
  }, [])
}

export function orderNumbersShippedWithCanadaPostSelector(
  state,
  {orderNumbers},
) {
  return orderNumbers.reduce((prev, orderNumber) => {
    if (shippedShipperTypeSelector(state, {orderNumber}) === CANADA_POST) {
      prev.push(orderNumber)
    }

    return prev
  }, [])
}

export function orderNumbersShippedWithAmazonSFPGroundSelector(
  state,
  {orderNumbers},
) {
  return orderNumbers.reduce((prev, orderNumber) => {
    if (shippedShipperTypeSelector(state, {orderNumber}) === AMAZON_SFP) {
      const shippingInfo = shippingInfoSelector(state, {orderNumber})
      const methodCode = get(shippingInfo, 'shipping_method')

      // Amazon Shipping Ground
      if (methodCode === 'std-us-swa-mfn') {
        prev.push(orderNumber)
      }
    }

    return prev
  }, [])
}

export const getOrderNumbersThatCanHaveReturnLabels = lruMemoize(
  (orders, pendingLabels) =>
    orders
      .filter(
        (order) =>
          !getIsMerged(order) &&
          !isPrimeOrder(order) &&
          !getIsCancelled(order) &&
          (isWithoutReturnInfo(order) ||
            pendingLabels.includes(order.order_number)),
      )
      .map(getOrderNumber),
)

export const getOrderNumbersWithReturnLabels = lruMemoize(
  (orders, pendingLabels) =>
    orders
      .filter(
        (order) =>
          !!order.return_shipping_info.has_label &&
          !pendingLabels.includes(order.order_number),
      )
      .map(getOrderNumber),
)

export function isWithoutReturnInfo(order) {
  return (
    !order.return_shipping_info.created_date &&
    !getIsArchived(order) &&
    !getIsMerged(order)
  )
}

export const getIsPrimeOrderNumbers = lruMemoize((orders) =>
  orders
    .filter((order) => order.status === 'awaiting_fulfillment')
    .filter((order) => isPrimeOrder(order))
    .map(getOrderNumber),
)

export const getIsNotPrimeOrderNumbers = lruMemoize((orders) =>
  orders
    .filter((order) => order.status === 'awaiting_fulfillment')
    .filter((order) => !isPrimeOrder(order))
    .map(getOrderNumber),
)

export const getIsPrimeWithoutReturnLabelOrderNumbers = lruMemoize((orders) =>
  orders
    .filter((order) => isWithoutReturnInfo(order) && isPrimeOrder(order))
    .map(getOrderNumber),
)

export function getProductSKUsFromOrders(orders) {
  return Object.keys(
    reduce(
      orders,
      (prev, order) => {
        order.lines.forEach((line) => {
          prev[line.sku] = true
        })

        return prev
      },
      {},
    ),
  )
}

export function getLabelInfoIDsFromOrders(orders) {
  return orders.reduce((prev, order) => {
    const labelInfoIDs = order.label_infos || []

    labelInfoIDs.forEach((labelInfoID) => {
      if (!prev.includes(labelInfoID)) {
        prev.push(labelInfoID)
      }
    })

    return prev
  }, [])
}

export const getContainsMixOfPrimeAndNotPrimeOrders = lruMemoize((orders) => {
  const allOrders = orders.filter(
    (order) => order.status === 'awaiting_fulfillment',
  )
  const allArePrime = allOrders.every((order) => isPrimeOrder(order))
  const allAreNotPrime = allOrders.every((order) => !isPrimeOrder(order))

  return !(allArePrime || allAreNotPrime)
})

export function isAmazonOrderSelector(state, {orderNumber}) {
  if (!orderNumber) {
    return false
  }

  const order = allOrdersSelector(state)[orderNumber]

  if (!order) {
    return false
  }

  const carts = cartsSelector(state)

  return get(carts, [order.sales_channel.id, 'vendor'], '') === AMAZON
}

export function areAllAmazonOrdersSelector(state, {orderNumbers}) {
  return !orderNumbers.find(
    (orderNumber) => !isAmazonOrderSelector(state, {orderNumber}),
  )
}

function isPrimeOrder(order) {
  return !!(order.additional_cart_info && order.additional_cart_info.is_prime)
}

export function isPrimeOrderSelector(state, {orderNumber}) {
  if (!orderNumber) {
    return false
  }

  const order = allOrdersSelector(state)[orderNumber]

  if (!order) {
    return false
  }

  return isPrimeOrder(order)
}

export function arePrimeOrdersSelector(state, {orderNumbers}) {
  return !!orderNumbers.find((orderNumber) =>
    isPrimeOrderSelector(state, {orderNumber}),
  )
}

export const needsAmazonSFPSelector = createSelector(
  hasAmazonSFPSelector,
  (state, props) => props.orders,
  (hasAmazonSFP, orders) =>
    !hasAmazonSFP && getIsPrimeOrderNumbers(orders).length > 0,
)

export function orderProductsSelector(state, {orderNumber}) {
  const order = allOrdersSelector(state)[orderNumber]

  if (!order) {
    return {}
  }

  const productSKUs = getOrderProductSKUs(order)

  return reduce(
    productsSelector(state),
    (prev, product) => {
      if (productSKUs.includes(product.sku)) {
        return {
          ...prev,
          [product.sku]: product,
        }
      }
      return prev
    },
    {},
  )
}

export const getOrderProductSKUs = (order) =>
  order.lines.map((line) => line.sku)

export const orderProductSKUsSelector = (state, {orderNumber}) =>
  getOrderProductSKUs(orderSelector(state, {orderNumber}))

export const ordersProductKitsSelector = (state, {orderNumbers}) => {
  const productKitSelector = makeProductKitSelector()

  const skus = []
  for (var orderNumber of orderNumbers) {
    orderProductSKUsSelector(state, {orderNumber}).forEach((sku) =>
      skus.push(sku),
    )
  }

  return skus.map((sku) => {
    return productKitSelector(state, {sku})
  })
}

export const getWarehouseID = (order) => get(order, 'warehouse.id')

export const orderWarehouseIDSelector = (state, {orderNumber}) => {
  const order = orderSelector(state, {orderNumber})
  return getWarehouseID(order)
}

export function orderWarehouseSelector(state, {orderNumber}) {
  const warehouseID = orderWarehouseIDSelector(state, {orderNumber})

  return warehouseSelector(state, {warehouseID})
}

export function orderWarehouseNameSelector(state, {orderNumber}) {
  const warehouse = orderWarehouseSelector(state, {orderNumber})
  return getWarehouseName(warehouse)
}

export function orderShippingAddressSelector(state, {orderNumber}) {
  return get(orderSelector(state, {orderNumber}), 'shipping_address')
}

export function orderBillingAddressSelector(state, {orderNumber}) {
  return get(orderSelector(state, {orderNumber}), 'billing_address')
}

export function orderAlternateShipFromAddressSelector(state, {orderNumber}) {
  return get(orderSelector(state, {orderNumber}), 'alternate_ship_from_address')
}

export const orderWarehouseAddressSelector = (state, {orderNumber}) =>
  get(orderWarehouseSelector(state, {orderNumber}), 'address')

export const shipFromAddressSelector = (state, {orderNumber}) => {
  const order = orderSelector(state, {orderNumber})

  if (order.shipping_info.ship_from) {
    return order.shipping_info.ship_from
  }

  if (order.alternate_ship_from_address) {
    return order.alternate_ship_from_address
  }

  return orderWarehouseAddressSelector(state, {orderNumber})
}

export function getShippabilityHint(shippability) {
  /*
    off: SHIPPABILITY TURNED OFF -- FREE AND BASIC USERS
    unshippable: Using Inventory and UNSHIPPABLE
    partially-shippable: Using Inventory and is PARTIALLY SHIPPABLE
    shippable: Using Inventory and SHIPPABLE
    dropshippable
    shipped
    */

  switch (shippability) {
    case 'unshippable':
      return 'Line item is out of stock'
    case 'partially_shippable':
    case 'partially-shippable':
      return 'Line can only be partially shipped'
    case 'shippable':
      return 'Line can be shipped'
    case 'dropshippable':
      // FIXME: I have no idea if shippability can even be dropshippable
      // This was just here from https://github.com/ordoro/pappy/commit/3341037feb102080a2083dc718e66a7b9d6ef45b
      return 'Line can be dropshipped'
    case 'shipped':
      return 'Line has been shipped'
    default:
      return ''
  }
}

export function getOrderLineIsDropshipHint(state, line) {
  if (line && line.shippability.is_dropship) {
    const supplierName = supplierNameSelector(state, {
      supplierID: line.shippability.supplier_id,
    })
    return `Line can be dropshipped by ${supplierName}`
  }
  return ''
}

export function getOrderLineShippabilityHint(state, line) {
  if (!(line && line.shippability)) {
    return ''
  }

  if (line.shippability.is_dropship) {
    const supplierName = supplierNameSelector(state, {
      supplierID: line.shippability.supplier_id,
    })
    return `Line can be dropshipped by ${supplierName}`
  }

  return getShippabilityHint(line.shippability.shippability)
}

export function getOrderAllocationHint(allocationStatus) {
  switch (allocationStatus) {
    case 'partial':
      return 'Some lines were unable to be allocated'
    case 'allocated':
      return 'All lines have been allocated'
    case 'could_not_allocate':
      return 'Not enough inventory to allocate lines'
    case 'overallocated':
      return 'Some lines have been overallocated'
    default:
      return ''
  }
}

export function getOrderLineAllocationHint(line) {
  if (!(line && line.line_allocation_status)) {
    return ''
  }

  switch (line.line_allocation_status) {
    case 'allocated':
      return 'Line item is allocated'
    case 'overallocated':
      return 'Line item is overallocated'
    default:
      return 'Line item has not been allocated'
  }
}

export function orderLinesSelector(state, {orderNumber}) {
  return orderSelector(state, {orderNumber}).lines || []
}

export function orderLineSelector(state, {orderNumber, orderLineID}) {
  const lines = orderLinesSelector(state, {orderNumber})

  return lines.find((line) => line.id === orderLineID)
}

export function orderLineIDsSelector(state, {orderNumber}) {
  return orderLinesSelector(state, {orderNumber}).map(({id}) => id)
}

export function orderLineSKUSelector(state, {orderNumber, orderLineID}) {
  const line = orderLineSelector(state, {orderNumber, orderLineID})
  return get(line, 'sku')
}

export function orderLineShippabilitySelector(
  state,
  {orderNumber, orderLineID},
) {
  const line = orderLineSelector(state, {orderNumber, orderLineID})
  return get(line, 'shippability', {})
}

export function orderLineShippabilityHintSelector(
  state,
  {orderNumber, orderLineID},
) {
  const line = orderLineSelector(state, {orderNumber, orderLineID})

  return getOrderLineShippabilityHint(state, line)
}

export function orderLineIsDropshipHintSelector(
  state,
  {orderNumber, orderLineID},
) {
  const line = orderLineSelector(state, {orderNumber, orderLineID})

  return getOrderLineIsDropshipHint(state, line)
}

export function orderLineAllocationSelector(state, {orderNumber, orderLineID}) {
  const line = orderLineSelector(state, {orderNumber, orderLineID})

  return get(line, 'line_allocation_status')
}

export function orderLineAllocationHintSelector(
  state,
  {orderNumber, orderLineID},
) {
  const line = orderLineSelector(state, {orderNumber, orderLineID})

  return getOrderLineAllocationHint(line)
}

export const dropshippingInfoSelector = (state, {orderNumber}) =>
  orderSelector(state, {orderNumber}).dropshipping_info

export const getSupplierID = (order) =>
  get(order, 'dropshipping_info.supplier.id')

export const orderSupplierIDSelector = createSelector(
  orderSelector,
  getSupplierID,
)

export const orderSupplierSelector = (state, {orderNumber}) =>
  supplierSelector(state, {
    supplierID: orderSupplierIDSelector(state, {orderNumber}),
  })

export const orderSupplierNameSelector = (state, {orderNumber}) =>
  supplierNameSelector(state, {
    supplierID: orderSupplierIDSelector(state, {orderNumber}),
  })

const mostCommon = (array) => head(maxBy(entries(countBy(array)), last))

export const getMostCommonOrderLineSupplierId = (orders) =>
  mostCommon(
    compact(
      orders.reduce(
        (prev, order) => [
          ...prev,
          ...order.lines.map((line) => line.shippability.supplier_id),
        ],
        [],
      ),
    ),
  )

export const mostCommonOrderLineSupplierSelector = (state, {orderNumbers}) =>
  supplierSelector(state, {
    supplierID: getMostCommonOrderLineSupplierId(
      ordersSelector(state, {orderNumbers}),
    ),
  })

export function productTotalSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  if (!order) {
    return 0
  }

  return round(
    order.lines.reduce(
      (sum, line) => sum + line.total_price - line.discount_amount,
      0,
    ),
    2,
  )
}

export function grandTotalSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  if (!order) {
    return 0
  }

  const productTotal = productTotalSelector(state, {orderNumber})
  const {
    discount_amount: discountAmount,
    shipping_amount: shippingAmount,
    tax_amount: taxAmount,
  } = order.financial

  return round(productTotal + shippingAmount + taxAmount - discountAmount, 2)
}

export function financialInfoSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  if (!order) {
    return null
  }

  return {
    discount_amount: order.financial.discount_amount,
    shipping_amount: order.financial.shipping_amount,
    tax_amount: order.financial.tax_amount,
    product_amount: productTotalSelector(state, {orderNumber}),
    grand_total: grandTotalSelector(state, {orderNumber}),
  }
}

export const isPackableOrderSelector = createSelector(
  orderSelector,
  canUsePackShipSelector,
  (order, canUsePackShip) =>
    canUsePackShip &&
    (order.status === 'awaiting_fulfillment' || order.status === 'shipped'),
)

export function parseComment(comment) {
  if (comment.text.match(/^Created stripe charge /)) {
    const [, chargeID, amount, currency] = comment.text.match(
      /^Created stripe charge (\S+) for (\d+) in (\w+)$/,
    )

    return {
      ...comment,
      type: STRIPE_PAYMENT_COMMENT,
      chargeID,
      amount,
      currency,
    }
  }

  if (comment.text.match(/^Updated address /)) {
    return {
      ...comment,
      type: ADDRESS_COMMENT,
    }
  }

  return comment
}

export function getCommentGroups(comments) {
  const daysIndex = {}

  return sortBy(comments, 'date')
    .reverse()
    .map(parseComment)
    .reduce((days, comment) => {
      const day = moment(comment.date).format('MMM D, YYYY')

      if (daysIndex[day]) {
        daysIndex[day].comments.push(comment)
      } else {
        daysIndex[day] = {
          day,
          comments: [comment],
        }

        days.push(daysIndex[day])
      }

      return days
    }, [])
}

export const createOrderCommentGroupsSelector = (orderSelector) =>
  createSelector(orderSelector, (order) =>
    getCommentGroups(get(order, 'comments', [])),
  )

export const getIsAnUnsplitOrder = (order) =>
  order.sibling_order_numbers && order.sibling_order_numbers.length === 0

export const getIsReadOnlyOrder = (order) => order.status === 'merged'

export function isReadOnlyOrderSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})
  return !!order && getIsReadOnlyOrder(order)
}

export function billingEmailSelector(state, {orderNumber}) {
  return get(orderBillingAddressSelector(state, {orderNumber}), ['email']) || ''
}

export function defaultPackingListIDFromOrderNumbersSelector(
  state,
  {orderNumbers},
) {
  const ids = orderNumbers.map((orderNumber) =>
    defaultPackingListIDFromOrderNumberSelector(state, {orderNumber}),
  )

  // Since defaultPackingListIDFromOrderNumberSelector will provide a valid ID if packing lists exist
  // then we only need to check the first ID to see if all IDs will be valid
  if (ids[0]) {
    return ids
  }

  // Returning a null instead an empty array is easier to check for but do take care to check
  return null
}

export function packingListIDFromOrderShippingInfoSelector(
  state,
  {orderNumber},
) {
  const order = orderSelector(state, {orderNumber})

  return get(order, 'shipping_info.packing_list_id')
}

export function packingListIDFromOrderCartLinkSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  const cart = cartSelector(state, {cartID: get(order, 'sales_channel.id')})

  if (cart && cart.default_packing_list_id) {
    return cart.default_packing_list_id
  }

  return null
}

export function defaultPackingListIDFromOrderNumberSelector(
  state,
  {orderNumber},
) {
  const shippingInfoPackingListID = packingListIDFromOrderShippingInfoSelector(
    state,
    {
      orderNumber,
    },
  )

  if (shippingInfoPackingListID) {
    return shippingInfoPackingListID
  }

  const cartPackingListID = packingListIDFromOrderCartLinkSelector(state, {
    orderNumber,
  })

  if (cartPackingListID) {
    return cartPackingListID
  }

  const defaultPackingList = defaultPackingListSelector(state)

  if (defaultPackingList) {
    return defaultPackingList.id
  }

  const firstPackingList = firstPackingListSelector(state)

  if (firstPackingList) {
    return firstPackingList.id
  }

  return null
}

export function orderCartSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  return cartSelector(state, {cartID: get(order, 'sales_channel.id')})
}

export function orderCartNameSelector(state, {orderNumber}) {
  const cart = orderCartSelector(state, {orderNumber})

  return cart && cart.name
}

export function orderCartTypeSelector(state, {orderNumber}) {
  const cart = orderCartSelector(state, {orderNumber})

  return cart && cart.vendor
}

export function canNotifyCartAboutTrackingDeleteSelector(
  state,
  {orderNumber, labelType},
) {
  const cartType = orderCartTypeSelector(state, {
    orderNumber,
  })

  return (
    labelType === 'shipping' &&
    [SHOPIFY, ORDORO_VENDOR_CONNECT].includes(cartType)
  )
}

export function canNotifyCartAboutTrackingDeletesSelector(
  state,
  {orderNumbers, labelType},
) {
  return !!orderNumbers.find((orderNumber) =>
    canNotifyCartAboutTrackingDeleteSelector(state, {orderNumber, labelType}),
  )
}

export function getIsContentsVerified(order) {
  return !!get(order, 'tags', []).find(({text}) => text === 'Contents Verified')
}

export function orderNumbersNotReadyToCreateLabelSelector(
  state,
  {orderNumbers},
) {
  if (hasOrderCreateLabelWithoutContentsVerifiedTagPermissionSelector(state)) {
    return []
  }

  const orders = ordersSelector(state, {orderNumbers})

  return orders
    .filter((order) => !getIsContentsVerified(order))
    .map(({order_number}) => order_number)
}

export function getExpectedRestoreStatus(order) {
  const hasShippingInfo = !isEmpty(get(order, 'shipping_info.created_date'))
  const hasDropshippingInfo = !isEmpty(
    get(order, 'dropshipping_info.requested_date'),
  )

  if (hasShippingInfo) {
    return 'shipped'
  }
  if (hasDropshippingInfo) {
    return 'dropshipment_requested'
  }

  return 'awaiting_fulfillment'
}

export function getRequestedShippingMethod(order) {
  return get(order, 'requested_shipping_method')
}

export function requestedShippingMethodSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  return getRequestedShippingMethod(order)
}

export function getDeliverByDate(order) {
  return get(order, 'deliver_by_date')
}

export function deliverByDateSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  return getDeliverByDate(order)
}

export function getShipByDate(order) {
  return get(order, 'ship_by_date')
}

export function shipByDateSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  return getShipByDate(order)
}

export const canUseDeliverByDateSelector = createSelector(
  hasManualDeliverByDateFeatureSelector,
  activeCartsSortedByNameSelector,
  (hasManualDeliverByDateFeature, carts) =>
    hasManualDeliverByDateFeature ||
    !!carts.find(({vendor}) =>
      [AMAZON, AMAZON_UK, AMAZON_CA, EBAY].includes(vendor),
    ),
)

export function returnOrderReferenceIDsSelector(state, {orderNumber}) {
  const order = orderSelector(state, {orderNumber})

  return get(order, 'return_order_reference_ids') || []
}

export function estimatedDeliveryDateSelector(state, {orderNumber}) {
  return (
    get(
      shippingInfoSelector(state, {orderNumber}),
      'estimated_delivery_date',
    ) || null
  )
}

export function deliveredDateSelector(state, {orderNumber}) {
  const {status, carrier_updated_date} = shippingInfoSelector(state, {
    orderNumber,
  })

  return status === 'delivered' && carrier_updated_date
    ? carrier_updated_date
    : null
}

export function dropshippableOrderNumbersSelector(state, {orderNumbers}) {
  return orderNumbers.filter((orderNumber) => {
    const productSKUs = orderProductSKUsSelector(state, {orderNumber})
    const dropshippableProduct = productSKUs.find((sku) =>
      productIsDropshippedSelector(state, {sku}),
    )
    return !!dropshippableProduct
  })
}

export const commonDefaultSupplierForOrderNumbersSelector = createSelector(
  mostCommonOrderLineSupplierSelector,
  suppliersSortedByNameSelector,
  (commonSupplier, suppliers) => commonSupplier || head(suppliers),
)

export function markedTrackingWritebackFailureSelector(state, {orderNumbers}) {
  const orders = ordersSelector(state, {orderNumbers})

  return orders.reduce((prev, order) => {
    const hasFailureTag = order.tags.find(
      (tag) => tag.text === 'Failed Shipment Notification',
    )

    if (hasFailureTag) {
      prev.push(order.order_number)
    }

    return prev
  }, [])
}

export const canUseDropshipsSelector = createSelector(
  hasOrderPermissionSelector,
  useDropshippingSelector,
  (hasPermission, hasFeature) => hasPermission && hasFeature,
)

export function showDropshipNavSelector(state) {
  const showFeatureLocks = showFeatureLocksSelector(state)
  const useDropshipping = useDropshippingSelector(state)
  const hasOrderPermission = hasOrderPermissionSelector(state)

  return hasOrderPermission && (useDropshipping || showFeatureLocks)
}

export const orderNumbersInBatchSelector = createSelector(
  (state, {orderNumbers}) => ordersSelector(state, {orderNumbers}),
  (orders) =>
    orders.reduce((prev, order) => {
      if (order.batch_reference_id) {
        prev.push(order.order_number)
      }

      return prev
    }, []),
)

export function batchReferenceIDsFromOrdersSelector(state, {orderNumbers}) {
  const orders = ordersSelector(state, {orderNumbers})

  return orders.reduce((prev, order) => {
    if (order.batch_reference_id && !prev.includes(order.batch_reference_id)) {
      prev.push(order.batch_reference_id)
    }

    return prev
  }, [])
}

export function getBatchOrderErrors(order) {
  return get(order, ['batch', 'bob_errors'], [])
}

export async function ensureOrder(orderNumber, {reload} = {}) {
  const order = orderSelector(getState(), {orderNumber})

  if (!reload && order) {
    return order
  }

  const res = await apiverson.get(`/order/${encodeURIComponent(orderNumber)}`)

  return res.json
}

export async function ensureOrdersLoaded(orderNumbers, {reload} = {}) {
  try {
    const orders = []

    if (!reload) {
      const allOrders = allOrdersSelector(getState())

      orderNumbers = orderNumbers.filter((orderNumber) => {
        const order = allOrders[orderNumber]

        if (order) {
          orders.push(order)

          return false
        }

        return true
      })
    }

    for (const o of chunk(orderNumbers, 100)) {
      const {json} = await apiverson.get(buildPath(['multi_orders'], {o}))

      orders.push(...json.order)
    }

    await setOrdersStateAndEnsureProductsLoaded(orders)

    return orders
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error ensuring orders loaded.',
        details: error.message,
      },
      error,
    )

    return []
  }
}

export async function postAndSetOrderResponse(url, data) {
  const {json} = await apiverson.post(url, data || null)

  await setOrderStateAndEnsureProductsLoaded(json)
}

export async function setOrderStateAndEnsureProductsLoaded(order, options) {
  await setOrdersStateAndEnsureProductsLoaded([order], options)
}

export async function setOrdersStateAndEnsureProductsLoaded(
  orders,
  {refreshProducts} = {},
) {
  setOrders(orders)

  await ensureProductsLoaded(getProductSKUsFromOrders(orders), {
    refresh: refreshProducts,
  })

  await ensureLabelInfosLoaded(getLabelInfoIDsFromOrders(orders))
}

export async function markOrdersAsShipped(orderNumbers) {
  try {
    await Promise.all(
      orderNumbers.map((orderNumber) =>
        postAndSetOrderResponse(
          `/order/${encodeURIComponent(orderNumber)}/mark_as_shipped`,
        ),
      ),
    )

    const orderString = orderNumbers.length > 1 ? 'orders were' : 'order was'
    showMessageToast(
      `${orderNumbers.length} ${orderString} successfully marked as shipped.`,
    )

    await refreshOrderListAndCounts()
  } catch (error) {
    showGlobalError(
      {
        summary: `Error marking order${
          orderNumbers.length === 1 ? '' : 's'
        } as shipped.`,
        details: error.message,
      },
      error,
    )
  }
}

export async function updateOrderWarehouse(orderNumber, warehouseID) {
  const orderLink = orderLinkSelector(getState(), {orderNumber})
  const order = orderSelector(getState(), {orderNumber})
  const currentWarehouse = order.warehouse
  const warehouse = warehouseSelector(getState(), {warehouseID})

  if (!warehouse || currentWarehouse.id === warehouse.id) {
    return
  }

  let updatedOrder

  try {
    // optimistic update
    setOrder({
      ...order,
      warehouse: {id: warehouse.id, link: warehouse._link},
    })

    const {json} = await apiverson.put(`${orderLink}/warehouse`, {
      warehouse_id: warehouseID,
    })

    updatedOrder = json
  } catch (err) {
    // put things back to the before times
    setOrder({
      ...order,
      warehouse: currentWarehouse,
    })

    throw err
  }

  await setOrderStateAndEnsureProductsLoaded(updatedOrder)
}

export async function deleteLabel(orderNumber, labelType, notifyCart) {
  if (!orderNumber) {
    return
  }

  const orderLink = orderLinkSelector(getState(), {orderNumber})
  const params = {return: labelType === 'return', notify_cart: notifyCart}

  await apiverson.delete(`${orderLink}/label`, params)

  await updateOrders([orderNumber])

  updateOrderCounts()
}

export async function updateOrders(orderNumbers) {
  const ordersByOrderNumber = {}
  const deletedOrderNumbers = []

  async function updateOrder(orderNumber) {
    try {
      const {json: order} = await apiverson.get(
        `/order/${encodeURIComponent(orderNumber)}`,
      )
      ordersByOrderNumber[order.order_number] = order
    } catch (err) {
      // One of the orders we're updating may have been deleted.
      // This can happen when dropshipping or splitting an order.
      if (get(err, 'response.status') === 404) {
        deletedOrderNumbers.push(orderNumber)
      }
    }
  }

  await Promise.all(orderNumbers.map(updateOrder))

  const orders = Object.values(ordersByOrderNumber)
  await setOrdersStateAndEnsureProductsLoaded(orders)

  removeOrders(deletedOrderNumbers)
}

export async function addOrderComment(orderNumber, comment) {
  const link = orderLinkSelector(getState(), {orderNumber})

  const {json} = await apiverson.post(`${link}/comment`, {comment})

  setOrderComments(orderNumber, json)
}

export async function saveFinancialInfo(orderNumber, financialInfo) {
  const orderLink = orderLinkSelector(getState(), {orderNumber})

  const {json} = await apiverson.put(`${orderLink}/financial`, financialInfo)

  setOrderFinancial(orderNumber, json)
}

export async function saveOrder(orderNumber, params) {
  const orderLink = orderLinkSelector(getState(), {orderNumber})

  const {json} = await apiverson.put(orderLink, params)

  await setOrderStateAndEnsureProductsLoaded(json)
}

async function removeLine(orderNumber, orderLineID) {
  const {json} = await apiverson.delete(
    `/order/${encodeURIComponent(orderNumber)}/line/${orderLineID}`,
  )

  return json
}

export async function removeLines(orderNumber, orderLineIDs) {
  const orderShapes = await Promise.all(
    orderLineIDs.map((orderLineID) => removeLine(orderNumber, orderLineID)),
  )

  // We need to get the final order shape and we don't know which promise that is, so if we have
  // multiple requests then just ask for the order again
  const order =
    orderLineIDs.length === 1
      ? orderShapes[0]
      : await ensureOrder(orderNumber, {reload: true})

  await setOrderStateAndEnsureProductsLoaded(order, {refreshProducts: true})

  const financialInfo = financialInfoSelector(getState(), {orderNumber})

  await saveFinancialInfo(orderNumber, financialInfo)
}

export async function updateOrderAddress(
  orderNumber,
  addressType,
  address,
  tookSuggestion,
) {
  const data = omit(address, 'validation')

  if (tookSuggestion) {
    data.set_revision_lock = false
  }

  const {json} = await apiverson.put(
    `/order/${encodeURIComponent(orderNumber)}/${
      addressType === ORDER_ALTERNATE_SHIP_FROM_ADDRESS
        ? 'alternate_ship_from_address'
        : addressType === ORDER_SHIPPING_ADDRESS
          ? 'shipping_address'
          : 'billing_address'
    }/`,
    data,
  )

  setOrderAddress(orderNumber, addressType, json)
}

export function showOrderAddressModal(orderNumber, addressType) {
  if (addressType === ORDER_ALTERNATE_SHIP_FROM_ADDRESS) {
    showAlternateShipFromAddressModal(orderNumber)
  } else if (addressType === ORDER_SHIPPING_ADDRESS) {
    showOrderShippingAddressModal(orderNumber)
  } else {
    showOrderBillingAddressModal(orderNumber)
  }
}

export function showAlternateShipFromAddressModal(orderNumber) {
  const alternateShipFromAddress = orderAlternateShipFromAddressSelector(
    getState(),
    {orderNumber},
  )

  showEditAddressModal({
    address: alternateShipFromAddress,
    toastMessage: 'Ship From address was successfully updated.',
    onSave: async (address, {tookSuggestion}) => {
      await updateOrderAddress(
        orderNumber,
        ORDER_ALTERNATE_SHIP_FROM_ADDRESS,
        address,
        tookSuggestion,
      )

      refreshShippingAndReturnRates(orderNumber)
    },
  })
}

export function showOrderShippingAddressModal(orderNumber) {
  const shippingAddress = orderShippingAddressSelector(getState(), {
    orderNumber,
  })

  showEditAddressModal({
    willValidate: true,
    title: 'Edit Ship To Address',
    address: shippingAddress,
    toastMessage: 'Ship-to info was successfully updated.',
    onSave: async (address, {tookSuggestion}) => {
      await updateOrderAddress(
        orderNumber,
        ORDER_SHIPPING_ADDRESS,
        address,
        tookSuggestion,
      )

      refreshOrderListAndCounts()

      refreshShippingAndReturnRates(orderNumber)
    },
  })
}

export function showOrderBillingAddressModal(orderNumber) {
  const billingAddress = orderBillingAddressSelector(getState(), {orderNumber})

  showEditAddressModal({
    title: 'Edit Bill To Address',
    address: billingAddress,
    toastMessage: 'Bill-to info was successfully updated.',
    onSave: async (address, {tookSuggestion}) => {
      await updateOrderAddress(
        orderNumber,
        ORDER_BILLING_ADDRESS,
        address,
        tookSuggestion,
      )
    },
  })
}

export async function receivedOrderUpdate(items) {
  // if one of the items contain an insert/update to order_revision table
  // then update that count
  if (
    items.reduce(
      (prev, item) => prev || item.tables.includes('order_revision'),
      false,
    )
  ) {
    await updateOrderRevisionCount()
  }

  const ordersByID = ordersByOrderIDSelector(getState())

  // filter to orders that this app has in state
  const orderNumbers = items
    .filter(
      ({order_id, updated}) =>
        ordersByID[order_id] &&
        isAfter(new Date(updated), new Date(ordersByID[order_id].updated_date)),
    )
    .map(({order_id}) => ordersByID[order_id].order_number)

  if (orderNumbers.length === 0) {
    return
  }

  // refresh those orders
  await ensureOrdersLoaded(orderNumbers, {reload: true})
}

export function receivedOrderUpdateThrottled(payload, timeout = 1000) {
  const func = receivedOrderUpdateThrottled
  let {order_id, updated, table} = payload

  updated = updated || getRealDate().toISOString()
  const item = func.queue[order_id]

  if (item) {
    item.updated = updated
    if (!item.tables.includes(table)) {
      item.tables.push(table)
    }
  } else {
    func.queue[order_id] = {order_id, updated, tables: [table]}
  }

  if (func.promise) {
    return func.promise
  }

  func.promise = new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const items = Object.values(func.queue)
        func.queue = {}
        func.promise = null

        await receivedOrderUpdate(items)
      } catch (err) {
        reject(err)
      }

      resolve()
    }, timeout)
  })

  return func.promise
}
receivedOrderUpdateThrottled.queue = {}
receivedOrderUpdateThrottled.promise = null

export async function ensureOrdersTagged({label, color, orderNumbers}) {
  const tag = await ensureTagExists(label, color)

  await addTagToOrders(tag.id, orderNumbers)
}
