import TextInput from '../../../common/components/TextInput.js'

import {updateModalForm} from './EditAddressFunctions.js'
import Select from '../../../common/components/Select.js'
import className from '../../../common/className.js'

export default function AddressForm({form, errors}) {
  form.include = form.include || []
  const separateNames = form.include.includes('given_name')
  const hasTitle = form.include.includes('title')

  return (
    <ul className="list list--form">
      <li className="list__item--form flex--justify">
        <div
          className={className`${
            separateNames ? 'wrap--input-two-thirds' : 'wrap--input-half'
          } ${hasTitle && 'flex--justify'}`}
        >
          {hasTitle && (
            <div className="wrap--input-eighth">
              <Select
                label="Title"
                id="title"
                className="border-box w-100"
                fsExclude
                value={form.title || ''}
                onChange={(value) =>
                  updateModalForm({title: value, hasChanged_title: true})
                }
                required={form.required.includes('title')}
                errorMessage={form.hasChanged_title && errors.title}
              >
                <option value=""></option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Miss.">Miss.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mx.">Mx.</option>
                <option value="Sir">Sir</option>
                <option value="Dr.">Dr.</option>
              </Select>
            </div>
          )}
          {separateNames ? (
            <div className="flex--justify wrap--input-seven-eighths">
              <div className="wrap--input-half">
                <TextInput
                  label={'First/Given Name'}
                  id="given_name"
                  fsExclude
                  value={form.given_name || ''}
                  onChange={(value) =>
                    updateModalForm({
                      given_name: value,
                      hasChanged_given_name: true,
                    })
                  }
                  required={form.required.includes('given_name')}
                  errorMessage={form.hasChanged_given_name && errors.given_name}
                />
              </div>
              <div className="wrap--input-half">
                <TextInput
                  label={'Last/Family Name'}
                  id="family_name"
                  fsExclude
                  value={form.family_name || ''}
                  onChange={(value) =>
                    updateModalForm({
                      family_name: value,
                      hasChanged_family_name: true,
                    })
                  }
                  required={form.required.includes('family_name')}
                  errorMessage={
                    form.hasChanged_family_name && errors.family_name
                  }
                />
              </div>
            </div>
          ) : (
            <div>
              <TextInput
                label={'Name'}
                id="full_name"
                fsExclude
                value={form.name || ''}
                onChange={(value) =>
                  updateModalForm({name: value, hasChanged_name: true})
                }
                required={form.required.includes('name')}
                errorMessage={form.hasChanged_name && errors.name}
              />
            </div>
          )}
        </div>
        <div
          className={className`${
            separateNames ? 'wrap--input-third' : 'wrap--input-half'
          }`}
        >
          <TextInput
            label={'Company'}
            id="company"
            fsExclude
            value={form.company || ''}
            onChange={(value) =>
              updateModalForm({company: value, hasChanged_company: true})
            }
            required={form.required.includes('company')}
            errorMessage={form.hasChanged_company && errors.company}
          />
        </div>
      </li>
      <li className="list__item--form flex--justify">
        <div className="wrap--input-half">
          <TextInput
            label={'Email'}
            id="email"
            fsExclude
            value={form.email || ''}
            onChange={(value) =>
              updateModalForm({email: value, hasChanged_email: true})
            }
            required={form.required.includes('email')}
            errorMessage={form.hasChanged_email && errors.email}
          />
        </div>
        <div className="wrap--input-half">
          <TextInput
            label={'Phone'}
            id="phone"
            fsExclude
            value={form.phone || ''}
            onChange={(value) =>
              updateModalForm({phone: value, hasChanged_phone: true})
            }
            required={form.required.includes('phone')}
            errorMessage={form.hasChanged_phone && errors.phone}
          />
        </div>
      </li>
      <li className="list__item--form">
        <TextInput
          label={'Street 1'}
          id="street1"
          fsExclude
          value={form.street1 || ''}
          onChange={(value) =>
            updateModalForm({street1: value, hasChanged_street1: true})
          }
          required={form.required.includes('street1')}
          errorMessage={form.hasChanged_street1 && errors.street1}
        />
      </li>
      <li className="list__item--form">
        <TextInput
          label={'Street 2'}
          id="street2"
          fsExclude
          value={form.street2 || ''}
          onChange={(value) =>
            updateModalForm({street2: value, hasChanged_street2: true})
          }
          required={form.required.includes('street2')}
          errorMessage={form.hasChanged_street2 && errors.street2}
        />
      </li>
      <li className="list__item--form flex--justify">
        <div className="wrap--input-half">
          <TextInput
            label={'City'}
            id="city"
            fsExclude
            value={form.city || ''}
            onChange={(value) =>
              updateModalForm({city: value, hasChanged_city: true})
            }
            required={form.required.includes('city')}
            errorMessage={form.hasChanged_city && errors.city}
          />
        </div>
        <div className="wrap--input-eighth">
          <TextInput
            label={'State'}
            id="state"
            fsExclude
            value={form.state || ''}
            onChange={(value) =>
              updateModalForm({state: value, hasChanged_state: true})
            }
            required={form.required.includes('state')}
            errorMessage={form.hasChanged_state && errors.state}
          />
        </div>
        <div className="wrap--input-third">
          <TextInput
            label={'Zip'}
            id="zip"
            fsExclude
            value={form.zip || ''}
            onChange={(value) =>
              updateModalForm({zip: value, hasChanged_zip: true})
            }
            required={form.required.includes('zip')}
            errorMessage={form.hasChanged_zip && errors.zip}
          />
        </div>
      </li>
      <li className="list__item--form flex">
        <div className="wrap--input-half">
          <TextInput
            label={'Country'}
            id="country"
            fsExclude
            value={form.country || ''}
            onChange={(value) =>
              updateModalForm({country: value, hasChanged_country: true})
            }
            required={form.required.includes('country')}
            errorMessage={form.hasChanged_country && errors.country}
          />
        </div>
      </li>
    </ul>
  )
}
