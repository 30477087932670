import PropTypes from 'prop-types'
import {useMemo} from 'react'
import classNames from 'classnames'

import {useSelector} from '../../store.js'
import {Count, Plural, PluralBlock} from '../../common/components/Plural.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import TimelinePopover from '../../common/components/List/TimelinePopover/index.js'
import {
  createBatchSelector,
  createBatchCommentGroupsSelector,
  batchInternalNotesSelector,
  batchOrderNumbersSelector,
  batchCreatedDateSelector,
  batchUpdatedDateSelector,
  batchLPPPDFDocumentsSelector,
  startBatchPrintTask,
  startBatchMarkAsPrintedTask,
  batchHasActionErrorSelector,
} from '../../data/batches.js'
import {
  showBatchInternalNotesModal,
  showBatchReferenceIDModal,
} from './Modals/EditBatchParamModal.js'
import {
  batchListSelectReferenceID,
  batchListToggleExpanded,
} from './batchListActions.js'
import {
  createIsSelectedSelector,
  createIsExpandedSelector,
  createDetailURLSelector,
} from './batchListSelectors.js'
import {ORDER_STATUS_BY_SLUG} from '../../common/constants/Orders.js'
import {batchSummarySelector} from '../../data/batchSummaries.js'
import {buildPath} from '../../common/querystring.js'
import {formatAbodeURL} from '../../common/abode.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  labelPrinterIDSelector,
  packingListPrinterIDSelector,
} from '../../data/printerSettings.js'
import Link from '../../common/components/Link/Link.js'
import {showAddBatchCommentModal} from './Modals/AddBatchCommentModal.js'
import {showLatestBatchActionsModal} from './Modals/LatestBatchActionsModal.js'

export default function Row({referenceID}) {
  const batchSelector = useMemo(
    () => createBatchSelector(referenceID),
    [referenceID],
  )
  const [
    commentGroupsSelector,
    isSelectedSelector,
    isExpandedSelector,
    detailURLSelector,
  ] = useMemo(
    () => [
      createBatchCommentGroupsSelector(batchSelector),
      createIsSelectedSelector(referenceID),
      createIsExpandedSelector(referenceID),
      createDetailURLSelector(referenceID),
    ],
    [referenceID, batchSelector],
  )

  const isSelected = useSelector(isSelectedSelector)
  const isExpanded = useSelector(isExpandedSelector)
  const detailURL = useSelector(detailURLSelector)
  const createdDate = useSelector((state) =>
    batchCreatedDateSelector(state, {referenceID}),
  )
  const updatedDate = useSelector((state) =>
    batchUpdatedDateSelector(state, {referenceID}),
  )
  const internalNotes = useSelector((state) =>
    batchInternalNotesSelector(state, {referenceID}),
  )
  const orderCount = useSelector(
    (state) => batchOrderNumbersSelector(state, {referenceID}).length,
  )
  const batch = useSelector(batchSelector)
  const orderCounts = Object.entries(
    useSelector((state) => batchSummarySelector(state, {referenceID})) || {},
  ).filter(([_, count]) => count > 0)
  const singleOrderStatusName =
    orderCounts.length === 1 ? ORDER_STATUS_BY_SLUG[orderCounts[0][0]].name : ''
  const llpPDFDocuments = useSelector((state) =>
    batchLPPPDFDocumentsSelector(state, {referenceID}),
  )
  const labelPrinterID = useSelector(labelPrinterIDSelector)
  const packingListPrinterID = useSelector(packingListPrinterIDSelector)
  const batchHasActionError = useSelector((state) =>
    batchHasActionErrorSelector(state, {referenceID}),
  )

  return (
    <tr
      className={classNames('table__tr table__tr--batch-row', {
        'table__tr--expanded': isExpanded,
      })}
    >
      <td className="table__td table__td--order-checkbox relative">
        <TimelinePopover
          title="Batch Timeline"
          type="batch"
          commentGroupsSelector={commentGroupsSelector}
        />
        <Checkbox
          checked={isSelected}
          onClick={(checked, event) =>
            batchListSelectReferenceID(referenceID, checked, event.shiftKey)
          }
        />
      </td>

      <td className="table__td">
        <div className="wrap--product-name inline-block">
          <a
            className="btn btn--link darkest fs-n0 lh-sm-md  margin-right-5"
            href={detailURL}
          >
            <strong>{referenceID}</strong>
          </a>
          <ButtonLink
            className="btn--edit-product-name no-underline"
            onClick={() => showBatchReferenceIDModal(referenceID)}
          >
            <span
              className="i-pencil fs-00 lh-sm v-align-middle"
              aria-hidden="true"
            />
          </ButtonLink>
        </div>

        <dl className="wrap--po-addl-info list list--order-data with-top-divider">
          <dt className="list__title--order-data">Internal Notes</dt>
          {internalNotes ? (
            <dd className="list__item--order-data">
              <div>{internalNotes}</div>
              <ButtonLink
                className="meta-batchlist-button-edit-internal-note"
                onClick={() => showBatchInternalNotesModal(referenceID)}
              >
                Edit
              </ButtonLink>
            </dd>
          ) : (
            <dd className="list__item--order-data">
              <ButtonLink
                className="meta-batchlist-button-add-internal-note"
                onClick={() => showBatchInternalNotesModal(referenceID)}
              >
                Add note
              </ButtonLink>
            </dd>
          )}
        </dl>

        <dl className="wrap--po-addl-info list list--order-data with-top-divider">
          <dd className="list__item--order-data">
            <ButtonLink
              className="meta-batchlist-button-add-comment"
              onClick={() => showAddBatchCommentModal(referenceID)}
            >
              Add a comment
            </ButtonLink>
          </dd>
        </dl>
      </td>

      <td className="table__td">
        <div className="fs-n0 lh-sm-md">
          <strong>
            <PluralBlock count={orderCount}>
              {orderCount} {singleOrderStatusName.toLowerCase()}{' '}
              <Plural word="order" />
            </PluralBlock>
          </strong>
        </div>
        {batch.errors.length ? (
          <PluralBlock array={batch.errors}>
            <div className="fs-n0 margin-top-3">
              <span
                className="i-exclamation-triangle text--error fs-n0 lh-sm v-align-middle margin-right-3"
                aria-hidden="true"
              />
              <Link
                className="fs-n1 error lh-sm-md border-underline v-align-base"
                href={`${detailURL}&carrier_error=true`}
              >
                <Count /> <Plural p="orders have" s="order has" />{' '}
                <Plural p="errors" s="an error" />
              </Link>{' '}
              <span className="fs-n1 op-50">→</span>
            </div>
          </PluralBlock>
        ) : null}

        {isExpanded && orderCounts.length > 1 && (
          <div>
            <ul className="list list--square">
              {orderCounts.map(([status, count]) => (
                <li className="fs-n0 lh-sm margin-top-7" key={status}>
                  {ORDER_STATUS_BY_SLUG[status].name}: {count}
                </li>
              ))}
            </ul>
          </div>
        )}
      </td>

      <td className="table__td">
        <div className="fs-n0  lh-sm-md margin-bottom-1">
          <FormattedDate value={createdDate} format="MMM D, YYYY" />
          {' at '}
          <FormattedDate value={createdDate} format="h:mma" />
        </div>
      </td>

      <td className="table__td">
        {batch.archived_date ? (
          <div className="label__callout label__callout--grey inline-block margin-right-5">
            <strong>ARCHIVED</strong>
          </div>
        ) : (
          <div
            className={classNames('wrap--batch-status', {
              error: batchHasActionError,
            })}
          >
            {!batch.running_task && (
              <div>
                <em className="fs-n0 lh-sm-md">
                  <FormattedDate
                    value={updatedDate}
                    format="MMM D, YYYY [at] h:mma"
                  />
                </em>
              </div>
            )}
            {batch.running_task ? (
              <div className="loading">
                <span className="spinner--sm v-align-middle margin-right-3" />
                <strong className="v-align-middle lh-sm">
                  <em className="fs-n0 text--md-grey">
                    {batch.running_task === 'fetch_rates'
                      ? 'Refreshing shipping rates...'
                      : batch.running_task === 'endpoint'
                        ? 'Updating orders...'
                        : batch.running_task === 'batch_sort'
                          ? 'Sorting orders...'
                          : batch.running_task === 'apply_presets'
                            ? 'Applying presets...'
                            : batch.running_task === 'generate_label'
                              ? 'Generating shipping labels...'
                              : batch.running_task === 'render_label'
                                ? 'Rendering labels...'
                                : batch.running_task === 'render_packing_list'
                                  ? 'Rendering packing list...'
                                  : batch.running_task === 'render_pick_list'
                                    ? 'Rendering pick list...'
                                    : batch.running_task === 'render_lpp'
                                      ? 'Finalizing document...'
                                      : batch.running_task === 'send_to_printer'
                                        ? 'Printing document...'
                                        : 'Running task...'}
                  </em>
                </strong>
              </div>
            ) : null}
            {batch.latest_actions.length > 0 && (
              <div className="fs-n0 margin-top-3">
                {batchHasActionError && (
                  <span
                    className="i--error fs-n0 lh-sm v-align-middle margin-right-3"
                    aria-hidden="true"
                  />
                )}
                <ButtonLink
                  className={classNames(
                    'fs-n1 lh-sm-md border-underline v-align-base',
                    {
                      error: batchHasActionError,
                    },
                  )}
                  onClick={() => showLatestBatchActionsModal({referenceID})}
                >
                  Latest action{batchHasActionError ? ' failed' : ' completed'}
                </ButtonLink>{' '}
                <span className="text--md-dk-grey fs-n1 op-50">→</span>
              </div>
            )}
          </div>
        )}
      </td>

      <td className="table__td">
        {llpPDFDocuments.map((document, index) => (
          <div key={index} className="wrap--batch-doc flex">
            <div className="flex--justify-col margin-right-7">
              <strong className="fs-n0 lh-sm-md">
                {document.document_name}
              </strong>
            </div>
            <a
              className="btn btn--primary btn--x-sm margin-right-7"
              href={`${formatAbodeURL(
                buildPath([...document.cache_path, document.document_name]),
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={
                document.hasLabel
                  ? () => startBatchMarkAsPrintedTask(referenceID)
                  : null
              }
            >
              View/Print
            </a>
            {(document.hasLabel ? labelPrinterID : packingListPrinterID) && (
              <ButtonPrimary
                alt
                size="x-sm"
                onClick={() =>
                  startBatchPrintTask(
                    referenceID,
                    document,
                    document.hasLabel ? labelPrinterID : packingListPrinterID,
                    document.hasLabel,
                  )
                }
              >
                InstaPrint
              </ButtonPrimary>
            )}
          </div>
        ))}
      </td>

      <td className="table__td align-right">
        <ButtonLink
          className="no-underline btn--expand-product-row lh-0"
          onClick={() => batchListToggleExpanded(referenceID)}
        >
          <span
            className="i--expand fs-01 lh-0 v-align-middle"
            aria-hidden="true"
          />
        </ButtonLink>
      </td>
    </tr>
  )
}

Row.propTypes = {
  referenceID: PropTypes.string.isRequired,
}
