import PropTypes from 'prop-types'
import {useEffect, useMemo} from 'react'

import {useSelector} from '../../../store.js'
import {
  batchCreatedDateSelector,
  batchHasActionErrorSelector,
  batchInternalNotesSelector,
  batchLPPPDFDocumentsSelector,
  batchOrderNumbersSelector,
  batchUpdatedDateSelector,
  createBatchCommentGroupsSelector,
  createBatchSelector,
  ensureBatch,
  startBatchMarkAsPrintedTask,
  startBatchPrintTask,
} from '../../../data/batches.js'
import CommentGroup from '../../../common/components/Details/Timeline/CommentGroup.js'
import {showAddBatchCommentModal} from '../../BatchListPage/Modals/AddBatchCommentModal.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {
  showBatchInternalNotesModal,
  showBatchReferenceIDModal,
} from '../../BatchListPage/Modals/EditBatchParamModal.js'
import className from '../../../common/className.js'
import {
  batchSummarySelector,
  ensureBatchSummary,
} from '../../../data/batchSummaries.js'
import {ORDER_STATUS_BY_SLUG} from '../../../common/constants/Orders.js'
import {
  labelPrinterIDSelector,
  packingListPrinterIDSelector,
} from '../../../data/printerSettings.js'
import FormattedDate from '../../../common/components/FormattedDate.js'
import {showLatestBatchActionsModal} from '../../BatchListPage/Modals/LatestBatchActionsModal.js'
import {formatAbodeURL} from '../../../common/abode.js'
import {buildPath} from '../../../common/querystring.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {Count, Plural, PluralBlock} from '../../../common/components/Plural.js'
import Link from '../../../common/components/Link/Link.js'
import {createDetailURLSelector} from '../../BatchListPage/batchListSelectors.js'
import {isLoadingSelector} from '../orderListSelectors.js'

export default function BatchDetailsTab({referenceID}) {
  const batchSelector = useMemo(
    () => createBatchSelector(referenceID),
    [referenceID],
  )
  const [commentGroupsSelector, detailURLSelector] = useMemo(
    () => [
      createBatchCommentGroupsSelector(batchSelector),
      createDetailURLSelector(referenceID),
    ],
    [referenceID, batchSelector],
  )
  const batch = useSelector(batchSelector)
  const batchCommentGroups = useSelector(commentGroupsSelector)
  const detailURL = useSelector(detailURLSelector)
  const createdDate = useSelector((state) =>
    batchCreatedDateSelector(state, {referenceID}),
  )
  const updatedDate = useSelector((state) =>
    batchUpdatedDateSelector(state, {referenceID}),
  )
  const internalNotes = useSelector((state) =>
    batchInternalNotesSelector(state, {referenceID}),
  )
  const orderCount = useSelector(
    (state) => (batchOrderNumbersSelector(state, {referenceID}) || []).length,
  )
  const orderCounts = Object.entries(
    useSelector((state) => batchSummarySelector(state, {referenceID})) || {},
  ).filter(([_, count]) => count > 0)
  const singleOrderStatusName =
    orderCounts.length === 1 ? ORDER_STATUS_BY_SLUG[orderCounts[0][0]].name : ''
  const llpPDFDocuments = useSelector((state) =>
    batchLPPPDFDocumentsSelector(state, {referenceID}),
  )
  const labelPrinterID = useSelector(labelPrinterIDSelector)
  const packingListPrinterID = useSelector(packingListPrinterIDSelector)
  const batchHasActionError = useSelector((state) =>
    batchHasActionErrorSelector(state, {referenceID}),
  )
  const isLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    if (batch) {
      return
    }

    ensureBatch(referenceID)

    ensureBatchSummary(referenceID)
  }, [referenceID])

  if (isLoading) {
    return null
  }

  return (
    <>
      {batch && (
        <div className="wrap--batch-info-panel">
          <div className="inner-wrap__panel--actions">
            <dl className="list divider--bottom">
              <dt className="list__title--order-data">Batch ID</dt>
              <dd className="list__item--product-data">
                <strong className="fs-02">{referenceID}</strong>
                <ButtonLink
                  className="margin-left-10 no-underline"
                  onClick={() => showBatchReferenceIDModal(referenceID)}
                >
                  <span
                    className="i-pencil fs-00 lh-sm v-align-middle"
                    aria-hidden="true"
                  />
                </ButtonLink>
              </dd>
            </dl>
            <dl className="list divider--bottom">
              <dt className="list__title--order-data">Date Created</dt>
              <dd className="list__item--product-data">
                <FormattedDate value={createdDate} format="MMM D, YYYY" />
                {' at '}
                <FormattedDate value={createdDate} format="h:mma" />
              </dd>
            </dl>
            <dl className="list divider--bottom">
              <dt className="list__title--order-data">Contents</dt>
              <dd className="list__item--product-data">
                <strong className="fs-n0 lh-sm-md">
                  <PluralBlock count={orderCount}>
                    {orderCount} {singleOrderStatusName.toLowerCase()}{' '}
                    <Plural word="order" />
                  </PluralBlock>
                </strong>
              </dd>
              {batch.errors.length ? (
                <PluralBlock array={batch.errors}>
                  <dd className="list__item--product-data">
                    <span
                      className="i-exclamation-triangle text--error fs-n0 lh-sm v-align-middle margin-right-3"
                      aria-hidden="true"
                    />
                    <Link
                      className="fs-n1 error lh-sm-md border-underline v-align-base"
                      href={`${detailURL}&carrier_error=true`}
                    >
                      <Count /> <Plural p="orders have" s="order has" />{' '}
                      <Plural p="errors" s="an error" />
                    </Link>{' '}
                    <span className="fs-n1 op-50">→</span>
                  </dd>
                </PluralBlock>
              ) : null}
              {orderCounts.length > 1 && (
                <dd className="list__item--product-data">
                  <ul className="list list--square">
                    {orderCounts.map(([status, count]) => (
                      <li className="fs-n0 lh-sm margin-top-7" key={status}>
                        {ORDER_STATUS_BY_SLUG[status].name}: {count}
                      </li>
                    ))}
                  </ul>
                </dd>
              )}
            </dl>
            <dl className="list divider--bottom">
              <dt className="list__title--order-data">Internal Notes</dt>
              {internalNotes ? (
                <dd className="list__item--order-data ws-pre-wrap fs-n0">
                  <div>{internalNotes}</div>
                  <ButtonLink
                    className="fs-n0 meta-batchlist-button-edit-internal-note"
                    onClick={() => showBatchInternalNotesModal(referenceID)}
                  >
                    Edit
                  </ButtonLink>
                </dd>
              ) : (
                <dd className="list__item--order-data">
                  <ButtonLink
                    className="fs-n0 meta-batchlist-button-add-internal-note"
                    onClick={() => showBatchInternalNotesModal(referenceID)}
                  >
                    Add note
                  </ButtonLink>
                </dd>
              )}
            </dl>

            <dl className="list divider--bottom">
              <dt className="list__title--order-data">Last Updated/Status</dt>
              {batch.archived_date ? (
                <dd className="list__item--order-data">
                  <div className="label__callout label__callout--grey inline-block margin-right-5">
                    <strong>ARCHIVED</strong>
                  </div>
                </dd>
              ) : (
                <dd
                  className={className`list__item--order-data wrap--batch-status ${{
                    error: batchHasActionError,
                  }}`}
                >
                  {!batch.running_task && (
                    <div>
                      <em className="fs-n0 lh-sm-md">
                        <FormattedDate
                          value={updatedDate}
                          format="MMM D, YYYY [at] h:mma"
                        />
                      </em>
                    </div>
                  )}

                  {batch.running_task ? (
                    <div className="loading">
                      <span className="spinner--sm v-align-middle margin-right-3" />
                      <strong className="v-align-middle lh-sm">
                        <em className="fs-n0 text--md-grey">
                          {batch.running_task === 'fetch_rates'
                            ? 'Refreshing shipping rates...'
                            : batch.running_task === 'endpoint'
                              ? 'Updating orders...'
                              : batch.running_task === 'batch_sort'
                                ? 'Sorting orders...'
                                : batch.running_task === 'apply_presets'
                                  ? 'Applying presets...'
                                  : batch.running_task === 'generate_label'
                                    ? 'Generating shipping labels...'
                                    : batch.running_task === 'render_label'
                                      ? 'Rendering labels...'
                                      : batch.running_task ===
                                          'render_packing_list'
                                        ? 'Rendering packing list...'
                                        : batch.running_task ===
                                            'render_pick_list'
                                          ? 'Rendering pick list...'
                                          : batch.running_task === 'render_lpp'
                                            ? 'Finalizing document...'
                                            : batch.running_task ===
                                                'send_to_printer'
                                              ? 'Printing document...'
                                              : 'Running task...'}
                        </em>
                      </strong>
                    </div>
                  ) : null}

                  {batch.latest_actions.length > 0 && (
                    <div className="fs-n0 margin-top-3">
                      {batchHasActionError && (
                        <span
                          className="i--error fs-n0 lh-sm v-align-middle margin-right-3"
                          aria-hidden="true"
                        />
                      )}
                      <ButtonLink
                        className={className`fs-n1 lh-sm-md border-underline v-align-base ${{
                          error: batchHasActionError,
                        }}`}
                        onClick={() =>
                          showLatestBatchActionsModal({referenceID})
                        }
                      >
                        Latest action
                        {batchHasActionError ? ' failed' : ' completed'}
                      </ButtonLink>{' '}
                      <span className="text--md-dk-grey fs-n1 op-50">→</span>
                    </div>
                  )}
                </dd>
              )}
            </dl>
            {llpPDFDocuments.length > 0 && (
              <dl className="list divider--bottom">
                <dt className="list__title--order-data">Documents</dt>
                {llpPDFDocuments.map((document, index) => (
                  <dd
                    key={index}
                    className="list__item--order-data list__item--batch-tab-docs flex"
                  >
                    <div className="flex--justify-col margin-right-10">
                      <strong className="fs-n0 lh-sm-md">
                        {document.document_name}
                      </strong>
                    </div>
                    <a
                      className="btn btn--primary btn--xx-sm margin-right-10"
                      href={`${formatAbodeURL(
                        buildPath([
                          ...document.cache_path,
                          document.document_name,
                        ]),
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={
                        document.hasLabel
                          ? () => startBatchMarkAsPrintedTask(referenceID)
                          : null
                      }
                    >
                      View/Print
                    </a>
                    {(document.hasLabel
                      ? labelPrinterID
                      : packingListPrinterID) && (
                      <ButtonPrimary
                        alt
                        size="xx-sm"
                        onClick={() =>
                          startBatchPrintTask(
                            referenceID,
                            document,
                            document.hasLabel
                              ? labelPrinterID
                              : packingListPrinterID,
                            document.hasLabel,
                          )
                        }
                      >
                        InstaPrint
                      </ButtonPrimary>
                    )}
                  </dd>
                ))}
              </dl>
            )}
            <dl className="list">
              <dt className="list__title--order-data">Batch History</dt>
              <dd className="list__item--order-data">
                <ButtonLink
                  className="fs-n0 meta-batchlist-button-add-comment"
                  onClick={() => showAddBatchCommentModal(referenceID)}
                >
                  Add a comment
                </ButtonLink>
              </dd>
              <dd>
                {batchCommentGroups.map(({day, comments}) => (
                  <CommentGroup key={day} day={day} comments={comments} />
                ))}
              </dd>
            </dl>
          </div>
        </div>
      )}
    </>
  )
}

BatchDetailsTab.propTypes = {
  referenceID: PropTypes.string.isRequired,
}
