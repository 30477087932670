import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import isEmpty from 'lodash/isEmpty.js'
import pickBy from 'lodash/pickBy.js'
import sortBy from 'lodash/sortBy.js'

import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {setForm, updateForm} from '../store.js'
import {getNounsFromValue, tokenizeOptions} from '../common/tokenizeOptions.js'
import {
  getIsFBAWarehouse,
  warehousesSortedByNameSelector,
} from './warehouses.js'
import {
  COMMUNICATION_EMAIL,
  COMMUNICATION_PO,
} from '../common/constants/Suppliers.js'
import {useDropshippingSelector, usePurchaseOrdersSelector} from './company.js'

export const SUPPLIERS = 'SUPPLIERS'

export function setSuppliers(suppliers) {
  setForm(SUPPLIERS, keyBy(suppliers, 'id'))
}

export function setSupplier(supplier) {
  updateForm(SUPPLIERS, {[supplier.id]: supplier})
}

export async function getSuppliers() {
  try {
    const suppliers = await fetchAllAPI('/supplier', 'supplier')

    setSuppliers(suppliers)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting suppliers.',
        details: err.message || err.error_message,
      },
      err,
    )

    setSuppliers([])
  }
}

export function suppliersSelector(state) {
  return state.ui.forms[SUPPLIERS] || {}
}

export function suppliersHaveLoadedSelector(state) {
  return !!state.ui.forms[SUPPLIERS]
}

export function supplierSelector(state, {supplierID}) {
  return suppliersSelector(state)[supplierID]
}

export function useSuppliersSelector(state) {
  const useDropshipping = useDropshippingSelector(state)
  const usePurchaseOrders = usePurchaseOrdersSelector(state)

  return useDropshipping || usePurchaseOrders
}

export const activeSuppliersSelector = createSelector(
  suppliersSelector,
  (suppliers) => pickBy(suppliers, (supplier) => !supplier.archive_date),
)

// Do not use, only used by DataImport. Will hide suppliers with same name.
export const suppliersByNameSelector = createSelector(
  suppliersSelector,
  (suppliers) => keyBy(suppliers, (supplier) => getSupplierName(supplier)),
)

// Do not use, only used by DataImport. Will hide suppliers with same name.
export const activeSuppliersByNameSelector = createSelector(
  activeSuppliersSelector,
  (suppliers) => keyBy(suppliers, (supplier) => getSupplierName(supplier)),
)

export const allSuppliersSortedByNameSelector = createSelector(
  suppliersSelector,
  (suppliers) => sortBy(suppliers, (supplier) => getSupplierName(supplier)),
)

export const suppliersSortedByNameSelector = createSelector(
  activeSuppliersSelector,
  (suppliers) => sortBy(suppliers, (supplier) => getSupplierName(supplier)),
)

export const supplierOptionsSelector = createSelector(
  suppliersSortedByNameSelector,
  (suppliers) =>
    suppliers.map((supplier) => ({
      value: supplier.id,
      display: getSupplierName(supplier),
      entity: supplier,
      type: SUPPLIERS,
      nouns: getNounsFromValue(
        `${getSupplierName(supplier)} ${supplier.address.city || ''} ${
          supplier.address.state || ''
        }`,
      ),
    })),
)

export const allSupplierOptionsSelector = createSelector(
  allSuppliersSortedByNameSelector,
  (suppliers) =>
    suppliers.map((supplier) => ({
      value: supplier.id,
      display: getSupplierName(supplier),
      entity: supplier,
      type: SUPPLIERS,
      nouns: getNounsFromValue(
        `${getSupplierName(supplier)} ${supplier.address.city || ''} ${
          supplier.address.state || ''
        }`,
      ),
    })),
)

export const activeSupplierOptionsSelector = createSelector(
  allSuppliersSortedByNameSelector,
  (suppliers) =>
    suppliers
      .filter((supplier) => !supplier.archive_date)
      .map((supplier) => ({
        value: supplier.id,
        display: getSupplierName(supplier),
        entity: supplier,
        type: SUPPLIERS,
        nouns: getNounsFromValue(
          `${getSupplierName(supplier)} ${supplier.address.city || ''} ${
            supplier.address.state || ''
          }`,
        ),
      })),
)

export const supplierOptionTokensSelector = createSelector(
  allSupplierOptionsSelector,
  (supplierOptions) => tokenizeOptions(supplierOptions),
)

export const activeSupplierOptionTokensSelector = createSelector(
  activeSupplierOptionsSelector,
  (supplierOptions) => tokenizeOptions(supplierOptions),
)

export function getSupplierName(supplier) {
  return supplier ? supplier.address.company : ''
}

export function supplierNameSelector(state, {supplierID}) {
  return getSupplierName(supplierSelector(state, {supplierID}))
}

export function getSupplierAddress(supplier, type = COMMUNICATION_PO) {
  let address = supplier && supplier.address

  if (!address) {
    return
  }

  return {
    ...address,
    email:
      supplier[`${type}_routing_channel`] === COMMUNICATION_EMAIL
        ? supplier[`${type}_routing_address`]
        : null,
  }
}

export function supplierAddressSelector(state, {supplierID, type}) {
  return getSupplierAddress(supplierSelector(state, {supplierID}), type)
}

export function createSupplierSelector(supplierID) {
  return createSelector(suppliersSelector, (suppliers) => suppliers[supplierID])
}

export function getIsFBA(supplier) {
  return !!supplier && !isEmpty(supplier.vendor_config)
}

export function getDropshipmentRoutingChannel(supplier) {
  return supplier ? supplier.dropshipment_routing_channel : COMMUNICATION_EMAIL
}

const activeNonFBASuppliersSelector = createSelector(
  suppliersSortedByNameSelector,
  (suppliers) =>
    suppliers.filter((supplier) => isEmpty(supplier.vendor_config)),
)

export const activeNonFBASupplierOptionsSelector = createSelector(
  activeNonFBASuppliersSelector,
  (suppliers) =>
    suppliers.map((supplier) => ({
      value: supplier.id,
      display: getSupplierName(supplier),
    })),
)

export const needsSupplierSelector = createSelector(
  suppliersHaveLoadedSelector,
  activeSuppliersSelector,
  (hasLoadedSuppliers, activeSuppliers) =>
    hasLoadedSuppliers && Object.keys(activeSuppliers).length === 0,
)

export function isFBASupplierSelector(state, {supplierID}) {
  return getIsFBA(supplierSelector(state, {supplierID}))
}

export function supplierWarehouseSelector(state, {supplierID}) {
  const supplier = supplierSelector(state, {supplierID})

  if (!supplier || !supplier.vendor_config.id) {
    return null
  }

  const warehouses = warehousesSortedByNameSelector(state)

  return warehouses.find(
    (warehouse) =>
      getIsFBAWarehouse(warehouse) &&
      warehouse.cart.id === supplier.vendor_config.id,
  )
}
