import {formSelector} from '../../../store.js'

export function setupExportKitStructureForm() {
  return {}
}

export function exportKitStructureErrorsSelector() {
  return {}
}

export function exportKitStructurePayloadSelector(state, {formName}) {
  const {reportType} = formSelector(state, {formName})

  const payload = {
    type: reportType,
    params: {},
  }

  return payload
}

export default function ExportKitStructureForm() {
  return null
}
