import * as Sentry from '@sentry/browser'

import {IS_PROD_ENV} from '../constants/index.js'
import {getFullStoryLinks, setupFullStory} from './fullStory.js'
import googleTagManager from './googleTagManager.js'
import verde from '../verde.js'
import {getState} from '../../store.js'
import {companySelector} from '../../data/company.js'
import datadogRum from './datadogRum.js'

export const PRIVACY_MASK = 'fs-exclude dd-privacy-mask'

window.paintPrivacyMask = function () {
  const head = document.querySelector('head')

  const css = document.createElement('style')
  css.type = 'text/css'

  css.appendChild(
    document.createTextNode(`
      ${PRIVACY_MASK.split(' ')
        .map((cn) => '.' + cn)
        .join(', ')} {
        background-color: #00ff0077 !important;
      }
    `),
  )

  head.appendChild(css)
}

export default {
  fullStory: null,

  init() {
    if (IS_PROD_ENV) {
      this.fullStory = setupFullStory()
    }

    this.identify()
  },

  identify() {
    const user = window.ORDORO_BOOTSTRAP.me
    const company = window.ORDORO_BOOTSTRAP.company

    if (!user) {
      return
    }

    Sentry.setUser({
      id: user.id,
      email: user.email,
      company_id: user.company_id,
      name: user.name,
    })

    if (this.fullStory) {
      this.fullStory.identify(`${user.id}`, {
        email: user.email,
        displayName: user.name || user.email,
        createdAt_date: user.date_joined,
        companyId_int: user.company_id,
        userId_int: user.id,
        isActive_bool: !!user.is_active,
        isSuperuser_bool: !!user.is_superuser,
        isStaff_bool: !!user.is_staff,
      })
    }

    googleTagManager.identify({
      userID: user.id,
      email: user.email,
      name: user.name,
      createdAt: user.date_joined,
      companyID: user.company_id,
      isActive: !!user.is_active,
      isSuperuser: !!user.is_superuser,
      isStaff: !!user.is_staff,
    })

    datadogRum.setUser({
      id: user.id,
      name: user.name,
      email: user.email,
      is_active: !!user.is_active,
      is_superuser: !!user.is_superuser,
      is_staff: !!user.is_staff,
      plan: company.plan,
      company_id: company.id,
      features: company.features,
      ...getFullStoryLinks(),
    })
  },

  async trackEvent(eventName) {
    const company = companySelector(getState())
    const hubSpotContactID = company.source.hubspot_contact_id

    if (!hubSpotContactID) {
      return
    }

    try {
      await verde.post('/event/track', {
        event_name: eventName,
        hubspot_contact_id: hubSpotContactID,
      })
    } catch (err) {
      // long hair don't care
    }
  },

  logout() {
    if (this.fullStory) {
      this.fullStory.clearUserCookie()
    }

    Sentry.setUser()

    datadogRum.clearUser()
  },
}
