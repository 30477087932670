import {updateModalForm} from './EditAddressFunctions.js'
import {isPresent} from '../../../common/utils.js'
import {PRIVACY_MASK} from '../../../common/analytics/index.js'

export default function Suggestion({
  suggestion: {street1, street2, city, state, zip, country_code},
}) {
  return (
    <dd className="list__item--addr-callout">
      <ul
        className={`list--no-style fs-n0 lh-md margin-bottom-0 ${PRIVACY_MASK}`}
      >
        <li className="margin-bottom-0">{street1}</li>
        {street2 && <li className="margin-bottom-0">{street2}</li>}
        <li className="margin-bottom-0">
          <span>{city}</span>, <span>{state}</span> <span>{zip}</span>
        </li>
        <li className="margin-bottom-0">{country_code}</li>
        <li className="margin-bottom-5">
          <button
            className="btn btn--link"
            type="button"
            onClick={() => {
              const updates = {tookSuggestion: true}

              if (isPresent(street1)) {
                updates.street1 = street1
                updates.street2 = street2
                updates.hasChanged_street1 = true
                updates.hasChanged_street2 = true
              }

              if (isPresent(state)) {
                updates.state = state
                updates.hasChanged_state = true
              }

              if (isPresent(city)) {
                updates.city = city
                updates.hasChanged_city = true
              }

              if (isPresent(zip)) {
                updates.zip = zip
                updates.hasChanged_zip = true
              }

              if (isPresent(country_code)) {
                updates.country = country_code
                updates.hasChanged_country = true
              }

              updateModalForm(updates)
            }}
          >
            Use this address
          </button>
        </li>
      </ul>
    </dd>
  )
}
