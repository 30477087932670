import PropTypes from 'prop-types'
import {useCallback} from 'react'
import _ from 'lodash'

import {
  ErrorsShape,
  DataImportColumnsShape,
  DataImportColumnsToDataMapShape,
} from '../../../../common/PropTypes.js'
import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default function ReconcileForm(props) {
  const onChange = useCallback((value, name) =>
    props.updateColumnMapping(value, name),
  )

  if (_.isEmpty(props.columnsToDataMap)) {
    return null
  }

  return (
    <BaseReconcileForm className="medium-11" {...props}>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.sku}
          errorMessage={props.columnErrors.sku}
          name="sku"
          label="SKU"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.name}
          errorMessage={props.columnErrors.name}
          name="name"
          label="Description"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.price}
          errorMessage={props.columnErrors.price}
          name="price"
          label="Price"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.cost}
          errorMessage={props.columnErrors.cost}
          name="cost"
          label="Weighted Average Unit Cost"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.weight}
          errorMessage={props.columnErrors.weight}
          name="weight"
          label="Weight (lbs)"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.category}
          errorMessage={props.columnErrors.category}
          name="category"
          label="Category"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.length}
          errorMessage={props.columnErrors.length}
          name="length"
          label="Length"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.width}
          errorMessage={props.columnErrors.width}
          name="width"
          label="Width"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.height}
          errorMessage={props.columnErrors.height}
          name="height"
          label="Height"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.upc}
          errorMessage={props.columnErrors.upc}
          name="upc"
          label="UPC"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.asin}
          errorMessage={props.columnErrors.asin}
          name="asin"
          label="ASIN"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.hs_code}
          errorMessage={props.columnErrors.hs_code}
          name="hs_code"
          label="Harmonized Code"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.country_of_origin}
          errorMessage={props.columnErrors.country_of_origin}
          name="country_of_origin"
          label="Country Of Origin"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.declared_value}
          errorMessage={props.columnErrors.declared_value}
          name="declared_value"
          label="Declared Value"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.customs_description}
          errorMessage={props.columnErrors.customs_description}
          name="customs_description"
          label="Customs Description"
          className="medium-5"
          onChange={onChange}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.internal_notes}
          errorMessage={props.columnErrors.internal_notes}
          name="internal_notes"
          label="Internal Notes"
          className="medium-7 end"
          onChange={onChange}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.has_serial_numbers}
          errorMessage={props.columnErrors.has_serial_numbers}
          name="has_serial_numbers"
          label="Has Serial Numbers"
          className="medium-5"
          onChange={onChange}
        />
      </Row>
    </BaseReconcileForm>
  )
}

ReconcileForm.propTypes = {
  columns: DataImportColumnsShape.isRequired,
  columnsToDataMap: DataImportColumnsToDataMapShape.isRequired,
  columnErrors: ErrorsShape.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
