export const REPORT_EXPORT_ORDERS = 'export_orders'
export const REPORT_EXPORT_PRODUCTS = 'export_products'
export const REPORT_EXPORT_PRODUCTS_SUPPLIERS = 'export_products_suppliers'
export const REPORT_EXPORT_PRODUCTS_SALES_CHANNELS =
  'export_products_sales_channels'
export const REPORT_EXPORT_SHIPPED_ORDERS = 'export_shipped_orders'
export const REPORT_EXPORT_SKU_SALES = 'export_sku_sales'
export const REPORT_EXPORT_INVENTORY_LOG = 'export_inventory_log'
export const REPORT_EXPORT_BILLING_INSURANCE = 'export_billing_insurance'
export const REPORT_EXPORT_POSTAGE_ACCOUNT = 'export_postage_account'
export const REPORT_EXPORT_GOODS_RECEIPT = 'export_goods_receipt'
export const REPORT_EXPORT_PURCHASE_ORDER_DETAIL =
  'export_purchase_order_detail'
export const REPORT_EXPORT_PURCHASE_ORDER_SUMMARY =
  'export_purchase_order_summary'
export const REPORT_EXPORT_RMA_SUMMARY = 'export_rma_summary'
export const REPORT_EXPORT_RMA_DETAIL = 'export_rma_detail'
export const REPORT_EXPORT_MO_DETAIL = 'export_mo_detail'
export const REPORT_EXPORT_MO_SUMMARY = 'export_mo_summary'
export const REPORT_AOH_REPORT = 'aoh_report'
export const REPORT_CART_INVENTORY_REPORT = 'cart_inventory_report'
export const REPORT_COMPONENT_INVENTORY_VALUE_REPORT =
  'component_inventory_value_report'
export const REPORT_FAILED_TRACKING_REPORT = 'failed_tracking_report'
export const REPORT_FULFILLMENT_SPEED_REPORT = 'fulfillment_speed_report'
export const REPORT_EXPORT_FULFILLMENT_SPEED = 'export_fulfillment_speed'
export const REPORT_INVENTORY_REPORT = 'inventory_report'
export const REPORT_INVENTORY_SUPPLIER_INFO_REPORT =
  'inventory_supplier_info_report'
export const REPORT_KIT_INVENTORY_REPORT = 'kit_inventory_report'
export const REPORT_KIT_STRUCTURE_REPORT = 'kit_structure_report'
export const REPORT_LOW_INVENTORY_REPORT = 'low_inventory_report'
export const REPORT_EXPORT_LOW_INVENTORY = 'export_low_inventory'
export const REPORT_NON_SUPPLIED_PRODUCTS_REPORT =
  'non_supplied_products_report'
export const REPORT_PARAMETERIZED_REPORT = 'parameterized_report'
export const REPORT_SALES_FORECASTING_REPORT = 'sales_forecasting_report'
export const REPORT_SALES_PER_SKU_REPORT = 'sales_per_sku_report'
export const REPORT_HIGH_ORDER_VALUE_REPORT = 'high_order_value_report'
export const REPORT_EXPORT_DELIVERY_STATUS_SUMMARY =
  'export_delivery_status_summary'
export const REPORT_EXPORT_DELIVERY_STATUS_DETAIL =
  'export_delivery_status_detail'
export const REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED =
  'export_products_fulfillment_speed'
export const REPORT_EXPORT_KIT_INVENTORY = 'export_kit_inventory'
export const REPORT_EXPORT_KIT_STRUCTURE = 'export_kit_structure'
export const REPORT_EXPORT_BOM_INVENTORY = 'export_bom_inventory'
export const REPORT_EXPORT_BOM_STRUCTURE = 'export_bom_structure'

export const REPORTS_BY_TYPE = {
  [REPORT_EXPORT_ORDERS]: {
    value: REPORT_EXPORT_ORDERS,
    display: 'Export Order Summary',
  },
  [REPORT_EXPORT_PRODUCTS]: {
    value: REPORT_EXPORT_PRODUCTS,
    display: 'Export Products',
  },
  [REPORT_EXPORT_PRODUCTS_SUPPLIERS]: {
    value: REPORT_EXPORT_PRODUCTS_SUPPLIERS,
    display: 'Export Products Suppliers',
  },
  [REPORT_EXPORT_PRODUCTS_SALES_CHANNELS]: {
    value: REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
    display: 'Export Product/Sales Channel Bridge',
  },
  [REPORT_EXPORT_SHIPPED_ORDERS]: {
    value: REPORT_EXPORT_SHIPPED_ORDERS,
    display: 'Export Order Detail',
  },
  [REPORT_EXPORT_SKU_SALES]: {
    value: REPORT_EXPORT_SKU_SALES,
    display: 'Export Sales by SKU Info',
  },
  [REPORT_EXPORT_INVENTORY_LOG]: {
    value: REPORT_EXPORT_INVENTORY_LOG,
    display: 'Export Inventory Log',
  },
  [REPORT_EXPORT_BILLING_INSURANCE]: {
    value: REPORT_EXPORT_BILLING_INSURANCE,
    display: 'Export Billing Insurance',
  },
  [REPORT_EXPORT_POSTAGE_ACCOUNT]: {
    value: REPORT_EXPORT_POSTAGE_ACCOUNT,
    display: 'Export Postage Account',
  },
  [REPORT_EXPORT_GOODS_RECEIPT]: {
    value: REPORT_EXPORT_GOODS_RECEIPT,
    display: 'Export Goods Receipt',
  },
  [REPORT_EXPORT_PURCHASE_ORDER_DETAIL]: {
    value: REPORT_EXPORT_PURCHASE_ORDER_DETAIL,
    display: 'Export Purchase Order Detail',
  },
  [REPORT_EXPORT_PURCHASE_ORDER_SUMMARY]: {
    value: REPORT_EXPORT_PURCHASE_ORDER_SUMMARY,
    display: 'Export Purchase Order Summary',
  },
  [REPORT_EXPORT_RMA_SUMMARY]: {
    value: REPORT_EXPORT_RMA_SUMMARY,
    display: 'Export RMA Summary',
  },
  [REPORT_EXPORT_RMA_DETAIL]: {
    value: REPORT_EXPORT_RMA_DETAIL,
    display: 'Export RMA Detail',
  },
  [REPORT_EXPORT_MO_DETAIL]: {
    value: REPORT_EXPORT_MO_DETAIL,
    display: 'Export Mfg Detail',
  },
  [REPORT_EXPORT_MO_SUMMARY]: {
    value: REPORT_EXPORT_MO_SUMMARY,
    display: 'Export Mfg Summary',
  },
  [REPORT_AOH_REPORT]: {value: REPORT_AOH_REPORT, display: 'AOH Report'},
  [REPORT_CART_INVENTORY_REPORT]: {
    value: REPORT_CART_INVENTORY_REPORT,
    display: 'Sales Channel Inventory Report',
  },
  [REPORT_COMPONENT_INVENTORY_VALUE_REPORT]: {
    value: REPORT_COMPONENT_INVENTORY_VALUE_REPORT,
    display: 'Component Inventory Value Report',
  },
  [REPORT_FAILED_TRACKING_REPORT]: {
    value: REPORT_FAILED_TRACKING_REPORT,
    display: 'Failed Tracking Report',
  },
  [REPORT_FULFILLMENT_SPEED_REPORT]: {
    value: REPORT_FULFILLMENT_SPEED_REPORT,
    display: 'Order Fulfillment Speed',
  },
  [REPORT_EXPORT_FULFILLMENT_SPEED]: {
    value: REPORT_EXPORT_FULFILLMENT_SPEED,
    display: 'Order Fulfillment Speed',
  },
  [REPORT_INVENTORY_REPORT]: {
    value: REPORT_INVENTORY_REPORT,
    display: 'Inventory Report',
  },
  [REPORT_INVENTORY_SUPPLIER_INFO_REPORT]: {
    value: REPORT_INVENTORY_SUPPLIER_INFO_REPORT,
    display: 'Inventory Supplier Info Report',
  },
  [REPORT_KIT_INVENTORY_REPORT]: {
    value: REPORT_KIT_INVENTORY_REPORT,
    display: 'Kit Inventory Report',
  },
  [REPORT_KIT_STRUCTURE_REPORT]: {
    value: REPORT_KIT_STRUCTURE_REPORT,
    display: 'Kit Structure Report',
  },
  [REPORT_LOW_INVENTORY_REPORT]: {
    value: REPORT_LOW_INVENTORY_REPORT,
    display: 'Export Low Inventory',
  },
  [REPORT_EXPORT_LOW_INVENTORY]: {
    value: REPORT_EXPORT_LOW_INVENTORY,
    display: 'Export Low Inventory',
  },
  [REPORT_NON_SUPPLIED_PRODUCTS_REPORT]: {
    value: REPORT_NON_SUPPLIED_PRODUCTS_REPORT,
    display: 'Non Supplied Products Report',
  },
  [REPORT_PARAMETERIZED_REPORT]: {
    value: REPORT_PARAMETERIZED_REPORT,
    display: 'Parameterized Report',
  },
  [REPORT_SALES_FORECASTING_REPORT]: {
    value: REPORT_SALES_FORECASTING_REPORT,
    display: 'Sales Forecasting Report',
  },
  [REPORT_SALES_PER_SKU_REPORT]: {
    value: REPORT_SALES_PER_SKU_REPORT,
    display: 'Sales Per SKU Report',
  },
  [REPORT_HIGH_ORDER_VALUE_REPORT]: {
    value: REPORT_HIGH_ORDER_VALUE_REPORT,
    display: 'High Order Value Report',
  },
  [REPORT_EXPORT_DELIVERY_STATUS_SUMMARY]: {
    value: REPORT_EXPORT_DELIVERY_STATUS_SUMMARY,
    display: 'Export Delivery Status Summary',
  },
  [REPORT_EXPORT_DELIVERY_STATUS_DETAIL]: {
    value: REPORT_EXPORT_DELIVERY_STATUS_DETAIL,
    display: 'Export Delivery Status Detail',
  },
  [REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED]: {
    value: REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED,
    display: 'Export Product Fulfillment Speed',
  },
  [REPORT_EXPORT_KIT_INVENTORY]: {
    value: REPORT_EXPORT_KIT_INVENTORY,
    display: 'Export Kit Inventory',
  },
  [REPORT_EXPORT_KIT_STRUCTURE]: {
    value: REPORT_EXPORT_KIT_STRUCTURE,
    display: 'Export Kit Structure',
  },
  [REPORT_EXPORT_BOM_INVENTORY]: {
    value: REPORT_EXPORT_BOM_INVENTORY,
    display: 'Export BOM Inventory',
  },
  [REPORT_EXPORT_BOM_STRUCTURE]: {
    value: REPORT_EXPORT_BOM_STRUCTURE,
    display: 'Export BOM Structure',
  },
}

export const REPORTS_LIST = Object.keys(REPORTS_BY_TYPE)
export const SCHEDULABLE_REPORTS = [
  REPORT_EXPORT_ORDERS,
  REPORT_EXPORT_SHIPPED_ORDERS,
  REPORT_EXPORT_FULFILLMENT_SPEED,
  REPORT_EXPORT_PURCHASE_ORDER_DETAIL,
  REPORT_EXPORT_PURCHASE_ORDER_SUMMARY,
  REPORT_EXPORT_GOODS_RECEIPT,
  REPORT_EXPORT_PRODUCTS,
  REPORT_EXPORT_PRODUCTS_SUPPLIERS,
  REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
  REPORT_EXPORT_RMA_SUMMARY,
  REPORT_EXPORT_RMA_DETAIL,
  REPORT_EXPORT_SKU_SALES,
  REPORT_EXPORT_DELIVERY_STATUS_SUMMARY,
  REPORT_EXPORT_DELIVERY_STATUS_DETAIL,
  REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED,
  REPORT_EXPORT_LOW_INVENTORY,
  // REPORT_EXPORT_KIT_INVENTORY,
  // REPORT_EXPORT_KIT_STRUCTURE,
  REPORT_EXPORT_BOM_INVENTORY,
  REPORT_EXPORT_BOM_STRUCTURE,
]
export const SINGLE_RUN_REPORTS = [...SCHEDULABLE_REPORTS]

export const REPORT_SCHEDULE_HOURLY = 'hourly'
export const REPORT_SCHEDULE_DAILY = 'daily'
export const REPORT_SCHEDULE_WEEKLY = 'weekly'
export const REPORT_SCHEDULE_MONTHLY = 'monthly'

export const REPORT_SCHEDULE_FREQUENCY_OPTIONS = [
  {value: REPORT_SCHEDULE_HOURLY, display: 'Hourly'},
  {value: REPORT_SCHEDULE_DAILY, display: 'Daily'},
  {value: REPORT_SCHEDULE_WEEKLY, display: 'Weekly'},
  {value: REPORT_SCHEDULE_MONTHLY, display: 'Monthly'},
]

export const REPORT_SCHEDULE_FREQUENCIES =
  REPORT_SCHEDULE_FREQUENCY_OPTIONS.map(({value}) => value)

export const REPORT_SCHEDULE_FREQUENCY_DISPLAY =
  REPORT_SCHEDULE_FREQUENCY_OPTIONS.reduce((prev, option) => {
    prev[option.value] = option.display

    return prev
  }, {})

export const REPORT_INTERVAL_HOUR = 'hour'
export const REPORT_INTERVAL_DAY = 'day'
export const REPORT_INTERVAL_WEEK = 'week'
export const REPORT_INTERVAL_MONTH = 'month'

export const REPORT_INTERVAL_OPTIONS = [
  {value: REPORT_INTERVAL_HOUR, display: 'Hour'},
  {value: REPORT_INTERVAL_DAY, display: 'Day'},
  {value: REPORT_INTERVAL_WEEK, display: 'Week'},
  {value: REPORT_INTERVAL_MONTH, display: 'Month'},
]

export const REPORT_INTERVAL_ABBV_LOOKUP = {
  h: REPORT_INTERVAL_HOUR,
  hr: REPORT_INTERVAL_HOUR,
  hour: REPORT_INTERVAL_HOUR,
  hours: REPORT_INTERVAL_HOUR,
  d: REPORT_INTERVAL_DAY,
  day: REPORT_INTERVAL_DAY,
  days: REPORT_INTERVAL_DAY,
  w: REPORT_INTERVAL_WEEK,
  wk: REPORT_INTERVAL_WEEK,
  week: REPORT_INTERVAL_WEEK,
  weeks: REPORT_INTERVAL_WEEK,
  m: REPORT_INTERVAL_MONTH,
  mth: REPORT_INTERVAL_MONTH,
  month: REPORT_INTERVAL_MONTH,
  months: REPORT_INTERVAL_MONTH,
}

export const REPORT_INTERVALS = REPORT_INTERVAL_OPTIONS.map(({value}) => value)
